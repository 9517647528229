<template>
    <div>
        <!--banner-->
        <van-swipe :autoplay="3000" indicator-color="white" height="180">
            <van-swipe-item v-for="item,key in banner" :key="key">
                <img class="swipe-img" :src="item" @click="clickBanner(key)">
            </van-swipe-item>
        </van-swipe>
        <!--商户基本信息-->
        <div class="business-info-container">
            <div class="business-name-container">
                <div class="logo">
                    <img :src="'https://seetop-1257860468.file.myqcloud.com/panjiang/' + trademark">
                </div>
                <div class="business-name">
                    <div>{{name}}</div>
                    <div class="label-container">
                        <div v-for="item,index in label" :key="index">{{item}}</div>
                    </div>
                </div>
            </div>
            <div class="business-introduce">
                {{describe}}
            </div>
            <div class="business-address-container">
                <div class="business-address-left">
                    <div>{{address}}</div>
                    <!--                    <div>距离您10m</div>-->
                </div>
                <div class="business-address-right">
                    <a @click="customerService">
                        <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/business-%E5%AE%A2%E6%9C%8D.png">
                        <div>客服</div>
                    </a>
                    <a @click="viewMap">
                        <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/business-%E5%9C%B0%E5%9D%80.png">
                        <div>导航</div>
                    </a>
                    <a :href="'tel:' + phone">
                        <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/busienss-%E7%94%B5%E8%AF%9D.png">
                        <div>电话</div>
                    </a>
                </div>
            </div>
        </div>
        <!--        最新活动-->
        <div class="active-container" v-if="activitiesName" @click="jumpAct">
            <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E6%9C%80%E6%96%B0%E6%B4%BB%E5%8A%A8.png">
            <div>{{activitiesName}}</div>
        </div>
        <!--推荐产品-->
        <div class="title" v-if="showProduct">
            <div>推荐产品</div>
<!--            <div class="more">更多</div>-->
        </div>
        <div class="product-list" v-if="showProduct">
            <product-item-large2 v-for="item,key in showProduct" :key="key" :name="item.name" :img="item.thumbnail1"
                                 :label="item.label" :text="item.text" :price="item.mallprice" :productId="key" :cellId="item.cellId"></product-item-large2>
        </div>
        <div style="width: 100%;height: 5rem;"></div>
        <!--固定底部栏-->
        <float-nav></float-nav>
    </div>
</template>

<script>
  import ProductItemLarge2 from '../components/ProductItemLarge2'
  import FloatNav from '../components/FloatNav'
  import { globalConfig } from '../utils/mixin'
  import { ImagePreview } from 'vant'

  export default {
    name: 'Business',
    components: {
      FloatNav,
      ProductItemLarge2
    },
    mixins: [globalConfig],
    data () {
      return {
        businessId: '',
        trademark: '',
        name: '',
        label: [],
        describe: '',
        address: '',
        phone: '',
        activitiesName: '',
        activitiesUrl: '',
        lng: '',
        lat: '',
        banner: [],
        isBind: 0,
        isSubscribe: 0,
        showProduct: []
      }
    },
    methods: {
      getBusinessInfo: function () {
        this.axios.post(this.apiUrl + 'index/business/index', {
          businessId: this.businessId
        }).then((response) => {
          const result = response.data
          console.log(result)
          this.name = result.data.business_name
          this.label = result.data.label
          this.describe = result.data.describe
          this.address = result.data.address
          this.banner = result.data.images.map((item) => {
            return 'https://seetop-1257860468.file.myqcloud.com/panjiang/' + item
          })
          this.trademark = result.data.trademark
          this.phone = result.data.phone
          this.activitiesName = result.data.activities_name
          this.activitiesUrl = result.data.activities_url
          this.isBind = result.data.isBindUser
          this.isSubscribe = result.data.isSubscribe
          this.lng = result.data.lng
          this.lat = result.data.lat
          this.showProduct = result.data.product
        })
      },
      jumpAct: function () {
        window.location.href = this.activitiesUrl
      },
      // 客服聊天
      customerService: function () {
        if (this.isBind === 1) {
          if (this.isSubscribe === 1) {
            this.$router.push('/launchChat/' + this.businessId)
          } else {
            this.$refs.subscribe.showSubscribe()
          }
        } else {
          this.$toast({
            message: '请先前往绑定',
            icon: 'manager-o',
            onClose: () => {
              this.$router.push('/bind?url=' + window.location.href)
            }
          })
        }
      },
      viewMap: function () {
        this.$router.push('/map/' + this.lng + '/' + this.lat)
      },
      clickBanner: function (index) {
        ImagePreview({
          images: this.banner,
          startPosition: index
        })
      }
    },
    mounted () {
      this.businessId = this.$route.params.id
      this.getBusinessInfo()
    }
  }
</script>

<style scoped>
    .swipe-img {
        width: 100%;
    }

    .business-name-container {
        display: flex;
        flex-flow: row;
        align-items: flex-end;
        margin-bottom: 1rem;
        margin-top: -3rem;
    }

    .logo {
        background-color: #ffffff;
        z-index: 1;
        border-radius: 10rem;
        overflow: hidden;
    }

    .logo img {
        width: 6rem;
        height: 6rem;
        border-radius: 10rem;
    }

    .business-info-container {
        background-color: #ffffff;
        padding: 1rem 1rem 1.25rem 1rem;
        width: 100%;
        box-sizing: border-box;
        z-index: 9999;
    }

    .business-name {
        margin-left: 1.5rem;
    }

    .business-name > div:first-child {
        width: 16rem;
        font-weight: 600;
        font-size: 1.1rem;
        font-family: "Microsoft YaHei";
        margin-top: 3rem;
        word-wrap: break-word;
    }

    .business-name > div:nth-child(2) {
        margin-top: 0.5rem;
    }

    .business-address-container {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        /*align-items: flex-end;*/
        margin-top: 1rem;
    }

    .business-address-left {
    }

    .business-address-left > div:first-child {
        font-weight: 600;
        font-size: 1rem;
        color: #333333;
    }

    .business-address-left > div:nth-child(2) {
        font-size: 0.9rem;
        color: #888888;
    }

    .business-address-right {
        display: flex;
        flex-flow: row;
    }

    .business-address-right img {
        width: 1.4rem;
    }

    .business-address-right > a {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        color: #666666;
        margin-left: 1.2rem;
    }

    .business-address-right > a > div {
        margin-top: 0.2rem;
    }

    .business-introduce {
        color: #333333;
        letter-spacing: 1.5px;
    }

    .label-container {
        display: flex;
        flex-flow: row;
    }

    .label-container > div {
        border-radius: 10rem;
        padding: 0.1rem 0.3rem;
        margin-right: 0.5rem;
        font-size: 0.8rem;
        text-align: center;
        color: #333333;
    }

    .label-container > div:first-child {
        background-color: #FFE5C7;
    }

    .label-container > div:nth-child(2) {
        background-color: #C7F0FF;
    }

    .label-container > div:nth-child(3) {
        background-color: #FFD1C7;
    }

    .active-container {
        display: flex;
        flex-flow: row;
        align-items: center;
        background-color: #FFFFff;
        padding: 0 1.5rem;
        width: 100%;
        box-sizing: border-box;
        margin-top: 1rem;
    }

    .active-container > img {
        width: 2.5rem;
    }

    .active-container > div:nth-child(2) {
        font-size: 1rem;
        color: #333333;
        margin-left: 1.5rem;
    }

    .product-list {
        /*margin-top: 1rem;*/
    }

    .bottom-bar {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #F64C4C;
        color: #FFFFff;
        text-align: center;
        padding: 1rem 0;
        letter-spacing: 5px;
    }

    .title {
        margin-top: 1rem;
        background-color: #ffffff;
        display: flex;
        flex-flow: row;
        padding: 0.5rem 1rem;
        justify-content: space-between;
    }

    .title > div:first-child {
        font-weight: 600;
        font-size: 1.1rem;
    }

    .more {
        font-size: 0.9rem;
        color: #666666;
        display: flex;
        flex-flow: row;
        align-items: center;
    }

    .more:after {
        width: 1rem;
        height: 1rem;
        content: "";
        background: url("https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E5%90%91%E5%8F%B3-%E9%BB%91.png") no-repeat;
        background-size: 100%;
        display: inline-block;
    }
</style>
