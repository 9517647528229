<!--右下角的浮标导航-->
<template>
    <div class="nav-container">
        <div class="nav-wrapper">
            <router-link class="nav-icon-wrapper" to="/business/index">
                <img src="https://seetop-1257860468.file.myqcloud.com/vue/img/float-home.png" class="nav-img">
            </router-link>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'FloatNav',
    data () {
      return {
        showReturn: true
      }
    },
    methods: {
      backPage: function () {
        this.$router.go(-1)
      }
    },
    mounted () {
      const beforeUrl = this.$store.state.beforeUrl
      const name = beforeUrl.name
      // 如果是刚授权完进来，或者没有上一页信息，那么就不显示返回按钮了
      if (name === 'auth' || name === 'authNotify' || name === null) {
        this.showReturn = false
      }
    }
  }
</script>

<style scoped>
    .nav-container {
        position: fixed;
        right: 1rem;
        bottom: 5rem;
        display: flex;
        flex-flow: column;
    }
    .nav-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 10rem;
        background: #ffffff;
        box-shadow: 0 0 0.6rem #d2d2d2;
    }
    .nav-img {
        width: 2rem;
        height: 2rem;
    }
</style>
