<template>
    <div class="page">
        <!--顶部个人信息-->
        <div class="top-wrapper">
            <div class="top-info-wrapper">
                <div class="head-wrapper">
                    <img class="head-img" :src="headUrl">
                </div>
                <div class="user-info-wrapper">
                    <div>{{name}}</div>
                    <div>{{mobile}}</div>
                </div>
            </div>
        </div>
        <!--收藏-->
        <div class="collect-wrapper">
            <div class="collect-title">
                我的收藏
            </div>
            <div class="collect-content-wrapper">
                <router-link class="collect-item" to="/collect/0">
                    <img class="collect-img" src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83-%E6%96%87%E7%AB%A0%E6%94%B6%E8%97%8F.png">
                </router-link>
                <router-link class="collect-item" to="/collect/1">
                    <img class="collect-img" src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83-%E5%95%86%E5%93%81%E6%94%B6%E8%97%8F.png">
                </router-link>
            </div>
        </div>
        <!--服务入口-->
        <div class="serve-wrapper">
            <div class="collect-title">
                我的服务
            </div>
            <div class="serve-row">
                <router-link class="serve-item" to="/orderList">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83icon-v2-%E6%88%91%E7%9A%84%E8%AE%A2%E5%8D%95.png">
                    <div>我的订单</div>
                </router-link>
                <router-link class="serve-item" to="/addressList">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83icon-v2-%E6%88%91%E7%9A%84%E5%9C%B0%E5%9D%80.png">
                    <div>我的地址</div>
                </router-link>
                <router-link class="serve-item" to="/couponList">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83icon-v2-%E4%BC%98%E6%83%A0%E5%88%B8.png">
                    <div>优惠券</div>
                </router-link>
                <router-link class="serve-item" to="/cart">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83-%E8%B4%AD%E7%89%A9%E8%BD%A6.png">
                    <div>购物车</div>
                </router-link>
            </div>
            <div class="serve-row">
                <router-link class="serve-item" to="/beihun">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83icon-v2-%E5%A4%87%E5%A9%9A%E5%BD%95.png">
                    <div>备婚录</div>
                </router-link>
                <router-link class="serve-item" to="/huangli">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83icon-v2-%E9%BB%84%E5%8E%86.png">
                    <div>黄历</div>
                </router-link>
                <router-link class="serve-item" to="/collect">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83icon-v2-%E6%94%B6%E8%97%8F.png">
                    <div>收藏</div>
                </router-link>
                <div class="serve-item" @click="viewService">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83-%E6%96%B0%E5%A8%98%E7%BE%A4.png">
                    <div>新娘群</div>
                </div>
            </div>
            <div class="serve-row">
                <router-link class="serve-item" to="/question">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83icon-v2-%E5%B8%B8%E8%A7%81%E9%97%AE%E9%A2%98.png">
                    <div>常见问题</div>
                </router-link>
                <div class="serve-item" @click="viewService">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83icon-v2-%E5%AE%A2%E6%9C%8D%E5%BE%AE%E4%BF%A1.png">
                    <div>客服微信</div>
                </div>
<!--                <router-link class="serve-item" to="/chat">-->
<!--                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83icon-v2-%E5%92%A8%E8%AF%A2.png">-->
<!--                    <div>咨询</div>-->
<!--                </router-link>-->
                <router-link class="serve-item" to="/about">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%AA%E4%BA%BA%E4%B8%AD%E5%BF%83icon-v2-%E5%85%B3%E4%BA%8E%E6%88%91%E4%BB%AC.png">
                    <div>关于我们</div>
                </router-link>
            </div>
        </div>
        <customer-service ref="service"></customer-service>
        <page-footer></page-footer>
    </div>
</template>

<script>
  import { globalConfig } from '../utils/mixin'
  import PageFooter from '../components/PageFooter'
  import CustomerService from '../components/CustomerService'
  export default {
    name: 'User',
    components: { CustomerService, PageFooter },
    mixins: [globalConfig],
    data () {
      return {
        headUrl: '',
        name: '',
        mobile: ''
      }
    },
    methods: {
      // 初始化
      init: function () {
        this.axios.post(this.apiUrl + 'index/user/isBind').then((response) => {
          const data = response.data
          const isBind = data.isBind
          console.log(isBind)
          if (isBind === false) {
            this.$router.replace('/bind')
          }
          this.headUrl = data.weixinInfo.headimgurl
          this.name = data.weixinInfo.weixinNick
          this.mobile = data.mobile
        })
      },
      viewService: function () {
        this.$refs.service.showPop()
      }
    },
    mounted () {
      this.init()
    }
  }
</script>

<style scoped>
    .page {
        background-color: #ffffff;
    }
    .top-wrapper {
        width: 100%;
        height: 10rem;
        background: url('https://seetop-1257860468.file.myqcloud.com/panjiang/img/个人中心-背景页.png') no-repeat;
        background-size: 100% 100%;
        position: relative;
    }
    .top-info-wrapper {
        width: 15rem;
        display: flex;
        flex-flow: row;
        align-items: center;
        position: absolute;
        bottom: 3rem;
        left: 1.5rem;
    }
    .head-wrapper {
        padding: 0.2rem;
        background: #ffffff;
        border-radius: 10rem;
    }
    .user-info-wrapper {
        margin-left: 1rem;
    }
    .user-info-wrapper > div:first-child {
        font-family: PingFangSC-Thin, sans-serif;
        font-size: 1.2rem;
        font-weight: 600;
        color: #333333;
    }
    .user-info-wrapper > div:nth-child(2) {
        font-size: 0.8rem;
        margin-top: 0.5rem;
        color: #333333;
    }
    .head-img {
        width: 4.3rem;
        height: 4.3rem;
        border-radius: 10rem;
        display: block;
    }
    .collect-wrapper {
        width: 90%;
        margin: 1rem auto 0;
    }
    .collect-title {
        color: #333333;
        font-weight: 600;
        font-size: 1.2rem;
        font-family: PingFangSC-Regular, sans-serif;
    }
    .collect-title > div:nth-child(2) {
        font-size: 0.9rem;
        color: #999999;
    }
    .collect-content-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-top: 0.75rem;
    }
    .collect-img {
        width: 100%;
        border-radius: 0.5rem;
    }
    .collect-item {
        width: 48.5%;
    }
    .serve-row {
        display: flex;
        flex-flow: row;
    }
    .serve-wrapper {
        width: 90%;
        margin: 1rem auto 3rem;
    }
    .serve-item {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin: 1rem 0 0 0;
        width: 25%;
    }
    .serve-item > img {
        width: 2rem;
    }
    .serve-item > div:nth-child(2) {
        font-size: 0.9rem;
        color: #333333;
        margin-top: 0.5rem;
    }
</style>
