import wx from 'weixin-js-sdk'
import { getCookie } from './cookie'
import { urlSetValue } from './tools'
import { getLocalStorage } from './localStorage'
import { Toast } from 'vant'

//  常规参数配置
export const globalConfig = {
  data: function () {
    return {
      apiUrl: process.env.VUE_APP_API_URL + '/club/public/index.php/', // api url 解决跨域
      webUrl: process.env.VUE_APP_WEB_URL // 前端url
    }
  },
  methods: {
  }
}

/**
 * 混入列表
 * @type {{data(): *, methods: {onLoad: listLoading.methods.onLoad, listLoadingInit: listLoading.methods.listLoadingInit, listLoadingGetData: listLoading.methods.listLoadingGetData}}}
 * 1. 混入listLoading
 * 2. 初始化this.listLoadingInit(this.apiUrl + 'mall/distributor/getDistributionLog', 'list', 1, {})
 * 3. <van-list></van-list>包裹
 * 4. item => v-for="(item, index) in listLoadingList" :key="index"
 * 5. 如果有tab需要引入tab组件，定义tab()函数
 */
export const listLoading = {
  data () {
    return {
      listLoadingList: [], // 渲染列表
      listLoading: false, // 是否加载
      listLoading2: false, // 是否加载，listLoading是和van-list绑定，受van-list控制，不太适合用来判断
      listLoadingFinished: false, // 是否结束
      listLoadingApiListName: 'list', // api返回的列表名
      listLoadingApiUrl: '',
      listLoadingPage: 1,
      parameter: {}
    }
  },
  methods: {
    onLoad: function () {
      this.listLoadingGetData()
    },
    //  加载列表
    listLoadingGetData: function () {
      if (this.listLoading2) {
        return
      }
      this.listLoading = true
      this.listLoading2 = true
      this.axios.post(this.listLoadingApiUrl, this.getCurrentParameter()).then((response) => {
        const data = response.data
        if (data.status) {
          this.listLoadingList = this.listLoadingList.concat(data[this.listLoadingApiListName])
          this.listLoading = false
          this.listLoading2 = false
          if (data[this.listLoadingApiListName].length <= 7) {
            this.listLoadingFinished = true
          } else {
            this.listLoadingPage++
          }
        } else {
          this.listLoadingFinished = true
          this.listLoading = false
          this.listLoading2 = false
          this.$toast(data.msg)
        }
      })
    },
    /**
     * 初始化列表 ， 主要针对页面初始化 or tab切换等
     * @param apiUrl  请求的接口url
     * @param apiListName 接口返回以后列表的字段名
     * @param page  初始化page起始，有些page为0，有些为1
     * @param parameter 初始化除page以外的搜索参数
     */
    listLoadingInit: function (apiUrl, apiListName, page, parameter = {}) {
      this.parameter = parameter
      this.listLoadingList = []
      this.listLoading = false
      this.listLoadingFinished = false
      this.listLoadingApiListName = apiListName
      this.listLoadingApiUrl = apiUrl
      this.listLoadingPage = page
      this.listLoadingGetData() // 最后触发，初始化的时候主动触发一次，避免height不够没触发onload
    },
    /**
     * 清除参数
     */
    clearLoading: function () {
      this.listLoadingList = []
      this.listLoading = false
      this.listLoadingFinished = false
      this.page = 0
    },
    /**
     * 多元参数设置
     * @returns {{page: *}}
     */
    getCurrentParameter: function () {
      const parameter = Object.assign(this.parameter, {
        page: this.listLoadingPage
      })
      return parameter
    }
  }
}

/**
 * 微信jsdk混入
 * @type {{methods: {WxJsdkConfig: WXJsdk.methods.WxJsdkConfig}}}
 */
export const WXJsdk = {
  methods: {
    WxJsdkConfig: function (appId, timestamp, nonceStr, signature) {
      wx.config({
        appId: appId,
        timestamp: timestamp,
        nonceStr: nonceStr,
        signature: signature,
        jsApiList: [
          // 所有要调用的 API 都要加到这个列表中
          'checkJsApi',
          'onMenuShareTimeline',
          'onMenuShareAppMessage',
          'onMenuShareQQ',
          'onMenuShareWeibo',
          'hideMenuItems',
          'showMenuItems',
          'hideAllNonBaseMenuItem',
          'showAllNonBaseMenuItem',
          'translateVoice',
          'startRecord',
          'stopRecord',
          'onRecordEnd',
          'playVoice',
          'pauseVoice',
          'stopVoice',
          'uploadVoice',
          'downloadVoice',
          'chooseImage',
          'previewImage',
          'uploadImage',
          'downloadImage',
          'getNetworkType',
          'openLocation',
          'getLocation',
          'hideOptionMenu',
          'showOptionMenu',
          'closeWindow',
          'scanQRCode',
          'chooseWXPay',
          'openProductSpecificView',
          'addCard',
          'chooseCard',
          'openCard'
        ]
      })
    },
    WxShareConfig: function (title, desc, link, imgUrl) {
      wx.ready(function () {
        // 1 判断当前版本是否支持指定 JS 接口，支持批量判断
        wx.checkJsApi({
          jsApiList: [
            'getNetworkType',
            'previewImage',
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'onMenuShareQQ',
            'onMenuShareWeibo',
            'hideMenuItems'
          ]
        })
        // wx.hideAllNonBaseMenuItem();
        wx.hideMenuItems({
          menuList: ['menuItem:copyUrl'] // 要隐藏的菜单项，只能隐藏“传播类”和“保护类”按钮，所有menu项见附录3
        })
        var shareData = {
          title: title,
          desc: desc,
          link: link,
          imgUrl: imgUrl
        }
        wx.onMenuShareAppMessage(shareData)
        wx.onMenuShareTimeline(shareData)
        wx.onMenuShareQQ(shareData)
        wx.onMenuShareWeibo(shareData)
      })
      wx.error(function (res) {
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      })
    },
    // 创建分销链接，分销信息统一从cookie拿
    createDistributionShareUrl: function (url) {
      const is = getCookie('isDistributionMember')
      if (is === '1') {
        const channelUrl = urlSetValue(url, 'channel', getLocalStorage('currentUserChannelId'))
        // 把channelUrl发送到小程序
        wx.miniProgram.postMessage({
          data: {
            shareUrl: channelUrl
          }
        })
        return channelUrl
      } else {
        return url
      }
    },
    callPay: function (appId, timeStamp, nonceStr, pack, signType, paySign, url) {
      const that = this
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', that.jsApiCall, false)
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', that.jsApiCall)
          document.attachEvent('onWeixinJSBridgeReady', that.jsApiCall)
        }
      } else {
        that.jsApiCall(appId, timeStamp, nonceStr, pack, signType, paySign, url)
      }
    },
    jsApiCall: function (appId, timeStamp, nonceStr, pack, signType, paySign, url) {
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          appId: appId,
          timeStamp: timeStamp,
          nonceStr: nonceStr,
          package: pack,
          signType: signType,
          paySign: paySign
        },
        function (res) {
          window.WeixinJSBridge.log(res.err_msg)
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            Toast({
              message: '支付成功',
              onClose: function () {
                window.location.href = globalConfig.data().webUrl + '#/user'
              }
            })
          }
        }
      )
    },
    // 授权的时候调用一次这个参数，设置config
    localStorageWxConfig: function () {
      let signPackage = getLocalStorage('signPackage')
      if (signPackage) {
        signPackage = JSON.parse(signPackage)
        this.WxJsdkConfig(signPackage.appId, signPackage.timestamp, signPackage.nonceStr, signPackage.signature)
      }
    }
  }
}
