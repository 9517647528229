<template>
    <div>
        <div v-if="position == 'left'" class="chat-wrapper left">
            <img class="chat-head"
                 :src="'https://seetop-1257860468.file.myqcloud.com/panjiang/' + leftHead">
            <!--客服问题框-->
            <div class="chat-content" v-if="type === 'problem'">
                <div class="question-top">猜你想问（输入关键词提问：如支持人、费用）：</div>
                <div class="question-item" v-for="item,key in content" :key="key"
                     @click="clickProblem(item.problem_id, item.problem)">· {{item.problem}}
                </div>
            </div>
            <!--默认引导回复-->
            <div class="chat-content" v-if="type === 'default'">
                未找到相关内容，<span class="customerService" @click="clickCustomerService">点击联系客服</span>
            </div>
            <!--客服文字-->
            <div class="chat-content" v-else>
                {{content}}
            </div>
        </div>
        <div v-else class="chat-wrapper right">
            <div class="chat-content">
                {{content}}
            </div>
            <img class="chat-head" :src="rightHead">
        </div>
        <customer-service ref="service"></customer-service>
    </div>
</template>

<script>
  import CustomerService from './CustomerService'

  export default {
    name: 'ChatContent',
    components: { CustomerService },
    props: ['position', 'type', 'content', 'leftHead', 'rightHead'],
    methods: {
      clickProblem: function (id, problem) {
        this.$emit('clickProblem', [id, problem])
      },
      clickCustomerService: function () {
        this.$refs.service.showPop()
      }
    },
    mounted () {
    }
  }
</script>

<style scoped>
    .chat-wrapper {
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        padding: 1rem 1rem 0 1rem;
        box-sizing: border-box;
    }

    .left {
        justify-content: flex-start;
    }

    .right {
        justify-content: flex-end;
    }

    .chat-head {
        width: 3rem;
        height: 3rem;
        border-radius: 10rem;
    }

    .chat-content {
        max-width: 60%;
        border-radius: 1rem;
        background-color: #ffffff;
        margin: 0 1rem;
        padding: 1rem;
        word-wrap: break-word;
    }

    .question-top {
    }

    .question-wrapper > div {
        margin-top: 1rem;
    }

    .question-item {
        color: #3f8df6;
        font-weight: 600;
    }

    .customerService {
        color: #3f8df6;
    }
</style>
