<template>
    <div>
        <div class="article-item" :style="'width:'+ width + 'px' ">
            <img class="article-img" :src="'https://seetop-1257860468.file.myqcloud.com/panjiang/' + img" @click="toArticle">
            <div class="article-name">{{name}}</div>
            <div class="article-footer">
                <support-icon :like="like" :hasLike="isLike" @click.native="clickLike"></support-icon>
            </div>
        </div>
    </div>
</template>

<script>
  import SupportIcon from './SupportIcon'
  import { globalConfig } from '../utils/mixin'
  export default {
    name: 'ArticlePreview',
    components: { SupportIcon },
    mixins: [globalConfig],
    props: ['id', 'name', 'img', 'support', 'hasLike'],
    data () {
      return {
        isLike: false,
        like: 0,
        width: 0
      }
    },
    methods: {
      toArticle: function () {
        this.$router.push('/article/' + this.id)
      },
      // 点赞
      clickLike: function () {
        //  避免重复点赞
        if (this.isLike) {
          return
        }
        this.axios.post(this.apiUrl + 'index/article/like', {
          id: this.id,
          type: '文章'
        }).then((response) => {
          const data = response.data
          this.$toast(data.msg)
          // 点击的同时直接变颜色
          this.isLike = true
          this.like++
        })
      }
    },
    mounted () {
      this.isLike = this.hasLike
      this.like = this.support
      // 计算宽
      const tt = document.body.clientWidth
      this.width = (tt / 2) - 10
    }
  }
</script>

<style scoped>
    .article-item {
        display: flex;
        flex-flow: column;
        background-color: #ffffff;
        /*break-inside: avoid;*/
        margin: 0 0.25rem 0.5rem 0.25rem;
        /*width: 50%;*/
        border-radius: 8px;
        overflow: hidden;
    }
    .article-img {
        width: 100%;
        /*border-top-left-radius: 8px;*/
        /*border-top-right-radius: 8px;*/
    }
    .article-name {
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        font-size: 0.9rem;
        box-sizing: border-box;
        padding: 0 0.4rem;
        margin: 0.4rem 0 0;
        font-weight: 600;
    }
    .article-footer {
        display: flex;
        flex-flow: row;
        justify-content: flex-end;
        padding: 0.5rem;
    }
    .icon-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
    }
    .article-icon {
        width: 1.2rem;
        height: 1.2rem;
    }
    .article-support-number {
        margin-left: 0.3rem;
        font-size: 0.9rem;
        color: #999999;
    }
</style>
