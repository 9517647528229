<template>
    <div>
        <img class="banner" src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E5%A4%87%E5%A9%9A%E5%BD%95banner.png">
        <van-collapse v-model="activeNames">
            <van-collapse-item :title="  item[1].group" :value=" item[1].selectCount +  '/' + item[1].list.size" :icon="iconArr[item[0]]" v-for="item,key in list" :key="key">
                <div class="plan-row" v-for="item2,key2 in item[1].list" :key="key2">
                    <div class="tick" @click="clickTick(item[0],item2[0], item2[1].id, item2[1].select)" v-if="item2[1].select">
                        <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%B4%AD%E7%89%A9%E8%BD%A6%E6%89%93%E5%8B%BE.png">
                    </div>
                    <div class="tick-default" @click="clickTick(item[0],item2[0], item2[1].id, item2[1].select)" v-else></div>
                    <div :class="item2[1].select ? 'plan-default':'plan'">{{item2[1].title}}</div>
                </div>
            </van-collapse-item>
        </van-collapse>
        <a :href="'https://pj.seetop.net.cn/club/public/index.php/index/api/download?v=' + v" class="download-button">下载备婚录</a>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import { globalConfig } from '../utils/mixin'
  import FloatNav from '../components/FloatNav'

  export default {
    name: 'BeiHun',
    components: { FloatNav },
    mixins: [globalConfig],
    data () {
      return {
        v: 0,
        activeNames: ['1'],
        list: [],
        iconArr: {
          1: 'https://seetop-1257860468.file.myqcloud.com/vue/img/%E5%A4%87%E5%A9%9A%E5%BD%95-%E5%89%8D%E6%9C%9F%E5%87%86%E5%A4%87.png',
          2: 'https://seetop-1257860468.file.myqcloud.com/vue/img/%E5%A4%87%E5%A9%9A%E5%BD%95-%E8%AE%A2%E5%9C%BA%E5%9C%B0.png',
          3: 'https://seetop-1257860468.file.myqcloud.com/vue/img/%E5%A4%87%E5%A9%9A%E5%BD%95-%E6%8B%8D%E5%A9%9A%E7%BA%B1%E7%85%A7.png',
          4: 'https://seetop-1257860468.file.myqcloud.com/vue/img/%E5%A4%87%E5%A9%9A%E5%BD%95-%E5%A9%9A%E5%BA%86%E4%BA%8B%E9%A1%B9.png',
          5: 'https://seetop-1257860468.file.myqcloud.com/vue/img/%E5%A4%87%E5%A9%9A%E5%BD%95-%E6%8C%91%E5%A9%9A%E7%BA%B1.png',
          6: 'https://seetop-1257860468.file.myqcloud.com/vue/img/%E5%A4%87%E5%A9%9A%E5%BD%95-%E4%B9%B0%E5%A9%9A%E5%93%81.png    ',
          7: 'https://seetop-1257860468.file.myqcloud.com/vue/img/%E5%A4%87%E5%A9%9A%E5%BD%95-%E5%A9%9A%E5%89%8D%E5%86%B2%E5%88%BA.png'
        }
      }
    },
    methods: {
      // 初始化问题
      init: function () {
        this.axios.post(this.apiUrl + 'index/memorandum/index', {}).then((response) => {
          const result = response.data
          // 对象和数组的处理，转Map方便后续操作
          this.list = new Map(Object.entries(result.list))
          this.list.forEach((value, key) => {
            const a = value
            const b = new Map()
            value.list.forEach((val, index, arr) => {
              // val.select = true
              b.set(index, val)
            })
            a.list = b
            this.list.set(key, a)
          })
          console.log(this.list)
        })
      },
      // 点击图标，注意Vue的视图驱动对Map的修改没反应
      // 需要解决，为啥前面的也更新了；怎么更新视图驱动
      clickTick: function (key1, key2, mid, status) {
        var test = this.list
        var group = test.get(key1)
        var title = group.list.get(key2)
        // var selectCount = group.selectCount // eslint-disable-line no-unused-vars
        this.axios.post(this.apiUrl + 'index/memorandum/tick', {
          id: mid
        }).then((response) => {
          // 对视图进行更新
          if (status == true) {
            title.select = false
            group.selectCount = group.selectCount - 1
          } else {
            title.select = true
            group.selectCount = group.selectCount + 1
          }
          this.$forceUpdate()
        })
      }
    },
    mounted () {
      this.init()
      this.v = Math.floor(Math.random() * 99999)
      console.log(this.v)
    }
  }
</script>

<style scoped>
    .plan-row {
        display: flex;
        flex-flow: row;
        align-items: center;
        border-bottom: 0.01rem solid #f3f3f3;
        padding-bottom: 0.9rem;
        margin-top: 0.5rem;
    }
    .tick {
        background-color: #46D88C;
        border-radius: 10rem;
        width: 1.3rem;
        height: 1.3rem;
        line-height: 1.3rem;
        text-align: center;
        margin-right: 0.7rem;
    }
    .tick-default {
        border: 0.01rem solid #888888;
        border-radius: 10rem;
        width: 1.3rem;
        height: 1.3rem;
        margin-right: 0.7rem;
        box-sizing: border-box;
    }
    .tick img {
        width: 1.1rem;
    }
    .plan {
        color: #333333;
        font-size: 1rem;
    }
    .plan-default {
        color: #999999;
        text-decoration: line-through;
        font-size: 1rem;
    }
    .banner {
        width: 100%;
    }
    .download-button {
        color: #FFFFff;
        background-color: #F64C4C;
        text-align: center;
        padding: 0.7rem 2rem;
        margin: 1rem auto;
        width: 80%;
        display: block;
    }
</style>
