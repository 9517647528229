<!--发起聊天-->
<template>
    <div>
        <div class="tip">* 客服将在第一时间回复您，请留意平台信息</div>
        <van-field v-model="mobile" type="tel" label="手机号" placeholder="请输入手机号"/>
        <van-field
                v-model="content"
                rows="4"
                autosize
                label=""
                type="textarea"
                maxlength="128"
                placeholder="请填写您想要咨询的内容"
                show-word-limit
        />
        <div class="button-container">
            <div @click="returnPage">上一页</div>
            <div @click="linkChat">确定</div>
        </div>
    </div>
</template>

<script>
  import { globalConfig } from '../utils/mixin'

  export default {
    name: 'LaunchChat',
    data () {
      return {
        content: '',
        mobile: '',
        businessId: ''
      }
    },
    mixins: [globalConfig],
    methods: {
      launch: function () {
        this.axios.post(this.apiUrl + 'index/chat/launchChat', {
          mobile: this.mobile,
          business: this.businessId,
          message: this.content
        }).then((response) => {
          const result = response.data
          console.log(result)
          if (result.status === 1) {
            this.$toast({
              message: '提交成功，客服会在第一时间回复您',
              onClose: () => {
                this.$router.push('/message')
              }
            })
          } else if (result.status === 2) {
            this.$toast({
              message: result.msg,
              onClose: () => {
                this.$router.push('/message')
              }
            })
          } else {
            this.$toast({
              message: result.msg
            })
          }
        })
      },
      linkChat: function () {
        if (this.mobile && this.content && this.businessId) {
          this.launch()
        } else {
          this.$toast('请填写完整信息')
        }
      },
      returnPage: function () {
        this.$router.go(-1)
      },
      checkHasChat: function () {
        this.axios.post(this.apiUrl + 'index/chat/checkHasChat', {
          business: this.businessId
        }).then((response) => {
          const result = response.data
          console.log(result)
          if (result.status === 2) {
            this.$router.replace('/chat/' + result.data)
          }
        })
      }
    },
    mounted () {
      this.businessId = this.$route.params.id
      // 这边是否
      this.checkHasChat()
    }
  }
</script>

<style scoped>
    .button-container {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        border-top: 0.01rem solid #F64C4C;
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
    }

    .button-container > div {
        width: 50%;
        box-sizing: border-box;
        text-align: center;
        padding: 1rem 0;
        font-size: 1.1rem;
    }

    .button-container > div:first-child {
        color: #F64C4C;
        background-color: #ffffff;
    }

    .button-container > div:nth-child(2) {
        color: #FFFFff;
        background-color: #F64C4C;
    }

    .tip {
        color: #888888;
        font-size: 0.9rem;
        margin: 0.5rem 0 0.5rem 1rem;
    }
</style>
