<!--发布动态-->
<template>
    <div class="page">
        <div class="input-container">
            <!--            <textarea class="content" rows="5" placeholder="说点什么~~" maxlength="128"></textarea>-->
            <van-field
                    v-model="content"
                    rows="4"
                    autosize
                    label=""
                    type="textarea"
                    maxlength="128"
                    placeholder="发布信息内容"
                    show-word-limit
            />
            <!--            :max-size="500 * 1024"-->
            <van-uploader v-model="fileList" multiple :max-count="6" :max-size="500 * 1024" @oversize="onOversize"
                          :before-read="asyncBeforeRead"/>
        </div>
        <div class="button-container">
            <div @click="returnPage">上一页</div>
            <div @click="submitFriend">发布</div>
        </div>
    </div>
</template>

<script>
  import { globalConfig } from '../utils/mixin'
  import * as imageConversion from 'image-conversion'

  export default {
    name: 'FriendSubmit',
    data () {
      return {
        fileList: [],
        content: ''
      }
    },
    mixins: [globalConfig],
    methods: {
      onOversize (file) {
        console.log(file)
        this.$toast('文件大小不能超过 500kb')
      },
      returnPage: function () {
        this.$router.push('friend')
      },
      submitFriend: function () {
        if (this.content) {
        } else {
          this.$toast('发布内容不能为空')
          return
        }
        this.axios.post(this.apiUrl + 'index/friend/friendSubmit', {
          content: this.content,
          img: this.fileList
        }).then((response) => {
          if (response.data.status === 1) {
            this.$toast({
              message: '审核通过后将正式发布',
              onClose: () => {
                this.$router.push('friend')
              }
            })
          } else {
            this.$toast(response.data.msg)
          }
        })
      },
      // 上传前置处理
      asyncBeforeRead (file) {
        this.$toast.loading({
          message: '加载中...'
        })
        // return true
        console.log(file, file.size)
        return new Promise((resolve) => {
          // compressAccurately有多个参数时传入对象
          imageConversion.compressAccurately(file, {
            size: 300 // 图片大小压缩到1024kb
            // width: 1280 // 宽度压缩到1280
          }).then(res => {
            console.log('压缩结束', res)
            this.$toast.clear()
            resolve(res)
          })
        })
      }
    }
  }
</script>

<style scoped>
    body, html {
        width: 100%;
        height: 100%;
    }

    .page {
        width: 100%;
        height: 100%;
        background-color: #FFFFff;
    }

    .content {
        width: 100%;
        border: none;
        margin-bottom: 1rem;
        border-bottom: 0.01rem solid #e7e7e7;
    }

    .input-container {
        padding: 1rem;
    }

    .button-container {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        border-top: 0.01rem solid #F64C4C;
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
    }

    .button-container > div {
        width: 50%;
        box-sizing: border-box;
        text-align: center;
        padding: 1rem 0;
        font-size: 1.1rem;
    }

    .button-container > div:first-child {
        color: #F64C4C;
        background-color: #ffffff;
    }

    .button-container > div:nth-child(2) {
        color: #FFFFff;
        background-color: #F64C4C;
    }
</style>
