import axios from 'axios'
// 这边单独引入一个Toast vue.use以后此处引用不到
// import { Toast } from 'vant'
import { setCookieDefault } from './cookie'
// import { setCookieDefault } from './cookie'
import store from '../store'
//  request 拦截器
axios.interceptors.request.use(
  config => {
    if (store.state.isShowLoading) {
      store.commit('beginLoad')
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
)
//  response拦截器
axios.interceptors.response.use(
  response => {
    // Toast.clear()
    store.commit('endLoad')
    store.commit('setIsShowLoading', true)
    const status = response.data.status
    if (status === -99) {
      // 没有授权，获取当前的路由链接，确保回调二次授权以后回调到当前页面
      console.log('后台授权已经失效 1')
      setCookieDefault('userAuth', 0)
      window.location.reload()
    } else if (status === -98) {
      // 服务繁忙
    }
    return response
  },
  error => {
    return error
  }
)

export default axios
