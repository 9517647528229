<!--大块商品item 类型2-->
<template>
    <div class="product-container" @click="viewProduct">
        <img class="product-img" :src="'https://seetop-1257860468.file.myqcloud.com/panjiang/' + img">
        <div class="product-info">
            <div class="product-name">{{name}}</div>
            <div class="label-container">
                <div v-for="item,key in label" :key="key">{{item}}</div>
            </div>
            <div>{{text}}</div>
            <div class="product-price">
                <span>￥</span>
                <div>{{moneyFen2Yuan(price)}}</div>
                <span>元</span>
            </div>
        </div>
    </div>
</template>

<script>
  import { moneyFen2Yuan } from '../utils/tools'

  export default {
    name: 'ProductItemLarge2',
    props: ['name', 'img', 'label', 'text', 'price', 'cellId'],
    methods: {
      moneyFen2Yuan,
      viewProduct: function () {
        this.$router.push('/product/' + this.cellId)
      }
    }
  }
</script>

<style scoped>
    .product-container {
        background-color: #ffffff;
        width: 100%;
        box-sizing: border-box;
        padding: 0.5rem 1rem;
        display: flex;
        flex-flow: row;
        /*justify-content: space-between;*/
    }

    .product-img {
        width: 7rem;
        height: 8.5rem;
        border-radius: 0.7rem;
    }

    .product-info {
        width: inherit;
        margin-left: 1rem;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    .product-info > div:nth-child(3) {
        width: 15rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .product-name {
        font-size: 1.2rem;
        color: #333333;
        font-weight: 600;
    }

    .product-price {
        align-self: flex-end;
        display: flex;
        flex-flow: row;
        align-items: flex-end;
        color: #F80202;
    }

    .product-price > span {
        font-size: 0.8rem;
    }

    .product-price > div {
        font-size: 1rem;
    }

    .label-container {
        display: flex;
        flex-flow: row;
    }

    .label-container > div {
        border-radius: 10rem;
        padding: 0.1rem 0.3rem;
        margin-right: 0.5rem;
        font-size: 0.8rem;
        text-align: center;
        color: #333333;
    }

    .label-container > div:first-child {
        background-color: #FFE5C7;
    }

    .label-container > div:nth-child(2) {
        background-color: #C7F0FF;
    }

    .label-container > div:nth-child(3) {
        background-color: #FFD1C7;
    }
</style>
