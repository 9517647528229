<!--订单列表，待支付订单样式-->
<template>
    <div>
        <div class="order-wrapper">
            <div class="order-head-wrapper">
                <p class="order-pass-time">过期时间：{{payInfo.expire_time}}</p>
                <p class="order-status">待支付</p>
            </div>
            <div class="order-body-wrapper" v-for="(item1,index1) in order" v-bind:key="index1">
                <div class="order-business-title-wrapper">
                    <p></p>
                    <p>{{item1.name}}</p>
                </div>
                <div class="order-product-wrapper" v-for="(item2,index2) in item1.goods" v-bind:key="index2">
                    <div class="order-product-item">
                        <img :src="'https://seetop-1257860468.file.myqcloud.com/panjiang/' + item1.trademark"  class="order-product-img">
                        <div class="order-product-name">
                            {{item2.name}}
                        </div>
                        <p class="order-product-number">*{{item2.number}}</p>
                    </div>
                </div>
            </div>
            <div class="order-footer-wrapper">
                <p class="order-price">总价：￥{{computedTotalFee}}</p>
                <div class="order-button-wrapper">
                    <div class="order-button order-button-cancel" @click="cancelOrder">取消</div>
                    <a class="order-button order-button-pay" @click="paymeny">支付</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { moneyFen2Yuan } from '../../utils/tools'
  import { globalConfig } from '../../utils/mixin'

  export default {
    name: 'WaitPayOrderItem',
    props: ['order', 'payInfo'],
    mixins: [globalConfig],
    data () {
      return {
      }
    },
    watch: {
    },
    computed: {
      computedTotalFee: function () {
        return moneyFen2Yuan(this.payInfo.total_fee)
      }
    },
    methods: {
      cancelOrder: function () {
        const that = this
        this.$dialog.confirm({
          title: '取消订单',
          message: '确认取消该订单？'
        }).then(() => {
          console.log('点击了确定')
          that.axios.post(that.apiUrl + 'index/order/cancelOrderWaitPay', {
            orderID: that.payInfo.orderID
          }).then((response) => {
            that.$toast({
              message: '取消成功',
              onClose: function () {
                that.$router.push('/user')
              }
            })
          })
        }).catch(() => {})
      },
      paymeny: function () {
        // window.location.href = 'https://club.seetop.net.cn/web/payment.html?v=10001&orderID=' + this.payInfo.orderID
        this.$router.push('/payment/' + this.payInfo.orderID)
      }
    },
    mounted () {
      // console.log('加载待支付Item')
      // console.log(this.order)
      console.log(this.payInfo)
    }
  }
</script>

<style scoped>
    .order-wrapper{
        background-color: #FFFFff;
        margin: 3vw 0;
        border-radius: 2vw;
    }
    .order-head-wrapper{
        width: 86vw;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        border-bottom: 0.01rem solid #eeeeee;
        margin: 0 4vw;
        padding: 2vw 1vw;
    }
    .order-pass-time{
        font-size: 3.2vw;
        color: #666;
        letter-spacing: 0.2vw;
    }
    .order-status{
        color: #FF795A;
        font-size: 0.9rem;
    }
    .order-body-wrapper {
        padding: 2vw 3vw 1vw 3vw;
    }
    .order-business-title-wrapper{
        height: 8vw;
        display: flex;
        align-content: center;
    }
    .order-business-title-wrapper p:nth-child(1){
        width: 0.8vw;
        height: 3vw;
        background-color: #ff3b30;
        margin: 1.2vw 1.5vw 0 2.5vw;
    }
    .order-business-title-wrapper p:nth-child(2){
        font-size: 3.6vw;
        font-weight: 700;;
    }
    .order-product-wrapper{
        width: 100%;
        font-size: 3.2vw;
    }
    .order-product-item{
        width: 94%;
        display: flex;
        margin: 1vw 0;
    }
    .order-product-name{
        width: 90%;
        display: flex;
        align-items: center;
    }
    .order-product-number{
        font-size: 0.9rem;
        color: #999;
        display: flex;
        align-items: center;
    }
    .order-product-img{
        width: 10vw;
        height: 10vw;
        border-radius: 1.5vw;
        margin: 0 3vw;
        box-shadow: 0 0 4px #999;
    }
    .order-footer-wrapper{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 0.2rem;
        padding: 2vw 4vw
    }
    .order-footer-wrapper > p:nth-child(1) {
        font-size: 3.2vw;
        color: #898989;
    }
    .order-price {
        /*font-weight: 600;*/
    }
    .order-button-wrapper{
        display: flex;
        flex-flow: row;
    }
    .order-button{
        border-radius: 0.4rem;
        font-size: 0.9rem;
        padding: 0.25rem 1.3rem;
    }
    .order-button-pay {
        margin-left: 1rem;
        border: 1px solid #ff9f1d;
        color: #ff9f1d;
    }
    .order-button-cancel {
        color: #7c7c7c;
        border: 1px solid #7c7c7c;
    }
</style>
