import { render, staticRenderFns } from "./FloatNavBusiness.vue?vue&type=template&id=066c0213&scoped=true&"
import script from "./FloatNavBusiness.vue?vue&type=script&lang=js&"
export * from "./FloatNavBusiness.vue?vue&type=script&lang=js&"
import style0 from "./FloatNavBusiness.vue?vue&type=style&index=0&id=066c0213&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "066c0213",
  null
  
)

export default component.exports