<!--订单列表，普通订单样式-->
<template>
    <div>
        <div class="order-wrapper">
            <div class="order-head-wrapper">
                <div class="order-head-left-wrapper">
                    <div class="order-type" v-if="orderType=='0'">{{computedOrderType}}</div>
                    <div class="order-type" v-if="orderType=='1'" style="background-color: #6B82BD">{{computedOrderType}}</div>
                    <div class="order-type" v-if="orderType=='2'" style="background-color: #CDE19D">{{computedOrderType}}</div>
                    <div class="order-type" v-if="orderType=='6'" style="background-color: #e3a1a1">{{computedOrderType}}</div>
                    <div class="order-type" v-if="orderType=='5'" style="background-color: rgb(64, 199, 210)">{{computedOrderType}}</div>
                    <p class="order-type-text">{{type}}</p>
                </div>
                <p class="order-status">{{computedOrderStatus}}</p>
            </div>
            <div class="order-body-wrapper">
                <img :src="'https://seetop-1257860468.file.myqcloud.com/panjiang/' + trademark" class="order-img">
                <div class="order-base-info">
                    <div class="order-base-info-text">
                        <p class="order-product-name">{{productName}}</p>
                        <p class="order-product-number">*{{number}}</p>
                    </div>
                    <p class="order-total-price">总价：￥{{computedTotalFee}}</p>
                </div>
            </div>
            <div class="order-footer-wrapper">
                <p>{{createTime}}</p>
                <div class="order-btn" v-if="orderStatus==0 && orderType==1" @click="viewOrder">待收货</div>
                <div class="order-btn" v-else-if="orderStatus==0 && orderType==6" @click="viewOrder">查看</div>
                <div class="order-btn" v-else-if="orderStatus==0" @click="viewOrder">去使用</div>
                <div class="order-btn" v-else-if="orderStatus==1" @click="viewOrder">去使用</div>
                <div class="order-btn" v-else-if="orderStatus==2 && orderType==5" @click="viewPft">查看预约</div>
<!--                <div class="order-btn" v-else-if="orderStatus==2" @click="toComment">去评论</div>-->
                <div class="order-btn" v-else-if="orderStatus==2" @click="viewOrder">查看</div>
                <div class="order-btn" v-else-if="orderStatus > 0" @click="viewOrder">查看</div>
                <div class="order-btn" v-else-if="orderStatus==-1" @click="viewOrder">查看</div>
            </div>
        </div>
    </div>
</template>

<script>
  import { moneyFen2Yuan, orderStatusStr } from '../../utils/tools'

  export default {
    name: 'CommonOrderItem',
    props: ['createTime', 'productName', 'trademark', 'type', 'totalFee', 'number', 'id', 'orderType', 'orderStatus'],
    data () {
      return {
        btnText: ''
      }
    },
    computed: {
      computedTotalFee: function () {
        return moneyFen2Yuan(this.totalFee)
      },
      // 订单类型
      computedOrderType: function () {
        let labelText = ''
        switch (parseInt(this.orderType)) {
          case 0:
            labelText = '套餐'
            break
          case 1:
            labelText = '快递'
            break
          case 2:
            labelText = '兑换码'
            break
          case 5:
            labelText = '票类'
            break
          case 6:
            labelText = '套票'
            break
          default:
            break
        }
        return labelText
      },
      // 订单状态
      computedOrderStatus: function () {
        console.log(this.orderType, this.orderStatus)
        return orderStatusStr(this.orderType, this.orderStatus)
      }
    },
    methods: {
      viewOrder: function () {
        this.$router.push('/order/' + this.id)
      },
      viewPft: function () {
        this.$router.push('/bookingList')
      },
      toComment: function () {
        this.$router.push('/comment/' + this.id)
      }
    },
    mounted () {}
  }
</script>

<style scoped>
    .order-wrapper{
        background-color: #FFFFff;
        margin: 3vw 0;
        border-radius: 2vw;
    }
    .order-head-wrapper{
        width: 86vw;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        border-bottom: 0.01rem solid #eeeeee;
        margin: 0 4vw;
        padding: 2vw 1vw;
    }
    .order-head-left-wrapper {
        display: flex;
        align-items: center;
    }
    .order-type{
        font-size: 2.6vw;
        padding: 0.2vw 1vw;
        color: #fff;
        background-color: #FFC68E;
        margin-right: 1.5vw;
        border-radius: 1vw;
    }
    .order-type-text{
        font-size: 3.2vw;
        color: #666;
        letter-spacing: 0.2vw;
    }
    .order-status{
        color: #FF795A;
        font-size: 0.9rem;
    }
    .order-body-wrapper{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        border-bottom: 0.01rem solid #eeeeee;
        background-color: #ffffff;
        padding:1rem 1rem 0.5rem 1rem;
        border-radius: 2vw;
    }
    .order-img{
        width: 4rem;
        height: 4rem;
        border-radius: 0.3rem;
        border: 1px solid #efefef;
    }
    .order-base-info{
        width: 80%;
        display: flex;
        flex-flow: column;
    }
    .order-base-info-text{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }
    .order-product-name{
        font-size: 3.6vw;
        width: 60vw;
    }
    .order-product-number {
        margin: 0 2vw;
        color: #999;
        font-size: 3.6vw;
    }
    .order-total-price {
        font-size: 3.2vw;
        color: #898989;
        margin-top: 0.2rem;
    }
    .order-footer-wrapper > p:nth-child(1) {
        font-size: 3.2vw;
        color: #898989;
    }
    .order-footer-wrapper{
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 0.2rem;
        padding: 1vw 4vw 3vw 4vw
    }
    .order-btn{
        border: 1px solid #ff9f1d;
        color: #ff9f1d;
        font-size: 0.9rem;
        padding: 0.25rem 1.3rem;
        border-radius: 0.4rem;
    }
</style>
