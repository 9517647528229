<template>
    <div>
        <div class="bg" :style="'width:' + availWidth+ 'px ;height:' + availHeight + 'px;'">
            <div style="width: 100%;height: 3rem;"></div>
            <img class="logo" src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/绑定页面-LOGO.png">
            <div class="input-wrapper">
                <div class="input-left">手机号</div>
                <div class="input-right">
                    <input class="my-input" v-model="mobile" placeholder="请输入手机号" readonly ref="mobile"
                           @click="clickMobile">
                    <div class="code-button" @click="sendCode">{{codeText}}</div>
                </div>
            </div>
            <div class="input-wrapper">
                <div class="input-left">验证码</div>
                <div class="input-right">
                    <input class="my-input" v-model="code" placeholder="请输入验证码" readonly @click="clickCode">
                </div>
            </div>
            <div class="input-wrapper">
                <div class="input-left">性别</div>
                <van-radio-group v-model="sexRadio" direction="horizontal" class="sex-wrapper">
                    <img class="sex-icon"
                         src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/sex-nan.png">
                    <div class="sex-label">先生</div>
                    <van-radio name="男" shape="square"></van-radio>
                    <img class="sex-icon"
                         src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/sex-nv.png"
                         style="margin-left: 1rem;">
                    <div class="sex-label">小姐</div>
                    <van-radio name="女" shape="square"></van-radio>
                </van-radio-group>
            </div>
            <div class="input-wrapper">
                <div class="input-left">婚姻状况</div>
                <van-radio-group v-model="statusRadio" direction="horizontal" class="sex-wrapper">
                    <div class="sex-label">未婚</div>
                    <van-radio name="未婚" shape="square"></van-radio>
                    <div class="sex-label">备婚</div>
                    <van-radio name="备婚" shape="square"></van-radio>
                    <div class="sex-label">已婚</div>
                    <van-radio name="已婚" shape="square"></van-radio>
                </van-radio-group>
            </div>
            <div class="input-wrapper">
                <div class="input-left">婚期</div>
                <div class="input-right">
                    <input class="my-input" v-model="marryDate" placeholder="选择婚期（选填）" readonly @click="clickMarryDay">
                </div>
            </div>
            <div class="register-btn" @click="bind">注册</div>
        </div>
        <!--婚期面板-->
        <van-action-sheet v-model="showMarryDay">
            <div class="content">
                <van-datetime-picker
                        v-model="currentDate"
                        type="year-month"
                        title="选择年月"
                        :min-date="minDate"
                        :max-date="maxDate"
                        :formatter="formatter"
                        @cancel="cancelMarryDay"
                        @confirm="confirmMarryDay"
                />
            </div>
        </van-action-sheet>
        <!--数组键盘-->
        <van-number-keyboard
                :show="showKeyBoard"
                @blur="showKeyBoard = false"
                @input="onInput"
                @delete="onDelete"
        />
    </div>
</template>

<script>
  import { globalConfig } from '../utils/mixin'
  import { formatGMT2StandardDate } from '../utils/tools'

  export default {
    name: 'Bind',
    data () {
      return {
        sexRadio: '男',
        statusRadio: '未婚',
        showMarryDay: false,
        minDate: new Date(2022, 0, 1),
        maxDate: new Date(2025, 10, 1),
        currentDate: new Date(),
        marryDate: '',
        availWidth: 0,
        availHeight: 0,
        showKeyBoard: false,
        mobile: '',
        code: '',
        codeText: '获取验证码',
        currentInput: 'mobile',
        send: false,
        beforeUrl: '',
        channel: 'seetop'
      }
    },
    props: [],
    mixins: [globalConfig],
    methods: {
      onInput (value) {
        if (this.currentInput === 'mobile') {
          if (this.mobile.length >= 11) {
            return
          }
          this.mobile = this.mobile + value
        } else if (this.currentInput === 'code') {
          if (this.code.length >= 6) {
            return
          }
          this.code = this.code + value
        }
      },
      onDelete () {
        if (this.currentInput === 'mobile') {
          this.mobile = this.mobile.substring(0, this.mobile.length - 1)
        } else if (this.currentInput === 'code') {
          this.code = this.code.substring(0, this.code.length - 1)
        }
      },
      clickMobile () {
        this.showKeyBoard = true
        this.currentInput = 'mobile'
        // this.$refs.mobile.$el.style.bor
        // console.log(this.$refs.mobile.focus())
      },
      clickCode () {
        this.showKeyBoard = true
        this.currentInput = 'code'
      },
      // 发送验证码
      sendCode () {
        if (this.mobile) {
        } else {
          this.$toast('请输入手机号码')
          return
        }
        if (!this.send) {
        } else {
          this.$toast('验证码已发送，请稍候再试')
          return
        }
        this.axios.post(this.apiUrl + 'index/sms/sendBindCode', {
          mobile: this.mobile
        }).then((response) => {
          const data = response.data
          if (data.status) {
            this.$toast('发送成功')
            this.countDown()
          } else {
            this.$toast(data.msg)
          }
        })
      },
      // 倒计时
      countDown () {
        if (this.send === true) {
          return
        }
        this.codeText = '60s'
        this.send = true
        const that = this
        var seconds = 60
        const timer = setInterval(function () {
          seconds--
          that.codeText = seconds + 's'
          if (seconds === 1) {
            clearInterval(timer)
            that.codeText = '重新获取'
            that.send = false
          }
        }, 1000)
      },
      // 绑定
      bind () {
        const that = this
        if (this.mobile && this.mobile.length === 11) {
        } else {
          this.$toast('请输入正确手机号')
          return
        }
        if (this.code && this.code.length === 6) {
        } else {
          this.$toast('请输入6位验证码')
          return
        }
        this.axios.post(this.apiUrl + 'index/sms/checkBindCode', {
          mobile: this.mobile,
          captcha: this.code,
          sex: this.sexRadio,
          marryDate: this.marryDate,
          loveState: this.statusRadio,
          channel: this.channel
        }).then((response) => {
          const data = response.data
          if (data.status) {
            this.$toast({
              message: '登录成功',
              onClose () {
                window.location.href = that.beforeUrl
              }
            })
          } else {
            this.$toast(data.msg)
          }
        })
      },
      // 是否绑定
      isBind () {
        this.axios.get(this.apiUrl + 'index/user/isBind').then((response) => {
          const data = response.data
          if (data.isBind) {
            window.location.href = this.beforeUrl
          }
        })
      },
      getBeforeUrl () {
        const url = document.location.toString()
        const arr = url.split('url')
        if (arr.length >= 2) {
          return arr[1].substr(1)
        } else {
          return this.webUrl + '#/user'
        }
      },
      clickMarryDay: function () {
        this.showMarryDay = true
      },
      formatter (type, val) {
        if (type === 'year') {
          return `${val}年`
        } else if (type === 'month') {
          return `${val}月`
        }
        return val
      },
      // 关闭婚期面板
      cancelMarryDay: function () {
        this.showMarryDay = false
      },
      // 确定选择婚期
      confirmMarryDay: function () {
        const d = formatGMT2StandardDate(this.currentDate)
        this.marryDate = d.slice(0, 6)
        this.showMarryDay = false
      }
    },
    mounted () {
      // 确定来源页面
      this.beforeUrl = this.getBeforeUrl()
      // 已绑定则跳转
      this.isBind()
      this.beforeUrl = decodeURIComponent(this.beforeUrl)
      console.log('跳转链接' + this.beforeUrl)
      // 填充背景页参数
      this.availWidth = screen.availWidth
      this.availHeight = screen.availHeight
    }
  }
</script>

<style scoped>
    .bg {
        width: 100%;
        height: 100%;
        background: url("https://seetop-1257860468.file.myqcloud.com/panjiang/img/bind-bg.png") no-repeat;
        background-size: 100% 100%;
        overflow: scroll;
    }

    .logo {
        width: 11rem;
        display: block;
        margin: 2rem auto;
    }

    .input-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        padding: 1rem 0;
    }

    .input-left {
        width: 30%;
        color: #ffffff;
        text-align: center;
    }

    .input-right {
        width: 65%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        border-bottom: 1px solid #FFFFff;
        padding: 1rem 0;
    }

    .my-input {
        /*opacity: 0.5;*/
        border: none;
        color: #FFFFff;
        background-color: transparent;
        width: 50%;
        padding-left: 1rem;
    }

    ::-webkit-input-placeholder {
        color: #ffffff;
    }

    .code-button {
        color: #FFFFff;
        padding: 0.35rem 0.5rem;
        background-color: #3F3F37;
        border-radius: 0.5rem;
        font-size: 0.8rem;
    }

    .sex-wrapper {
        color: #FFFFff;
        display: flex;
        flex-flow: row;
        align-items: center;
        padding: 0.5rem 0;
    }

    .sex-icon {
        width: 2.5rem;
        height: 1.5rem;
        margin-right: 0.7rem;
    }

    .sex-label {
        margin-right: 0.7rem;
    }

    .register-btn {
        width: 20rem;
        color: #FFFFff;
        background-color: #F64C4C;
        border-radius: 0.5rem;
        padding:  0.6rem 0;
        text-align: center;
        margin:  1rem auto 1rem ;
    }
</style>
