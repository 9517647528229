<template>
    <div>
        <van-overlay :show="show" @click="show = false">
            <div class="subscribe-wrapper">
<!--                <img :src="'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=' + subscribeTicket">-->
                <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%B8%80%E7%A2%97%E6%9C%89%E5%9B%8D%E4%BA%8C%E7%BB%B4%E7%A0%81.jpg">
                <p>为了及时查收消息，请长按扫描关注公众号</p>
                <p style="color: #F64C4C;">关注后请刷新页面</p>
            </div>
        </van-overlay>
    </div>
</template>

<script>
  export default {
    name: 'Subscribe',
    data () {
      return {
        show: false
      }
    },
    methods: {
      showSubscribe: function () {
        this.show = true
      }
    }
  }
</script>

<style scoped>
    .subscribe-wrapper {
        width: 80%;
        background-color: #ffffff;
        margin: 5rem auto;
        text-align: center;
        padding: 1rem 0;
    }
    .subscribe-wrapper img {
        width: 90%;
        margin: 0 auto;
    }
    .subscribe-wrapper p {
        font-size: 1rem;
    }
</style>
