import Vue from 'vue'
import store from '../store'
import VueRouter from 'vue-router'
import { businessRouter } from './business'
import Home from '../views/Home.vue'
import Shop from '../views/Shop'
import Cart from '../views/Cart'
import Message from '../views/Message'
import User from '../views/User'
import Article from '../views/Article'
import Chat from '../views/Chat'
import Product from '../views/Product'
import AddressList from '../views/AddressList'
import AddressEdit from '../views/AddressEdit'
import OrderList from '../views/OrderList'
import PayOrder from '../views/PayOrder'
import { getCookie } from '../utils/cookie'
import { setLocalStorage } from '../utils/localStorage'
import Bind from '../views/Bind'
import Payment from '../views/Payment'
import Question from '../views/Question'
import About from '../views/About'
import Huangli from '../views/Huangli'
import BeiHun from '../views/BeiHun'
import Collect from '../views/Collect'
import SelectCoupon from '../views/SelectCoupon'
import CouponList from '../views/CouponList'
import Coupon from '../views/Coupon'
import Class from '../views/Class'
import Test from '../views/Test'
import ArticleSearch from '../views/ArticleSearch'
import Order from '../views/Order'
import Friend from '../views/Friend'
import FriendSubmit from '../views/FriendSubmit'
import FriendUser from '../views/FriendUser'
import FriendEdit from '../views/FriendEdit'
import FriendRealName from '../views/FriendRealName'
import LaunchChat from '../views/LaunchChat'
import Business from '../views/Business'
import Map from '../views/Map'
// import { setShopTitle } from '../utils/tools'

Vue.use(VueRouter)

const baseRouter = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      showTab: true
    }
  },
  {
    path: '/auth',
    name: 'auth',
    component: () => import('../views/auth/Auth.vue')
  },
  {
    path: '/authNotify/:phpsessid?',
    name: 'authNotify',
    component: () => import('../views/auth/AuthNotify.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      showTab: true
    }
  },
  {
    path: '/bind',
    name: 'bind',
    component: Bind
  },
  {
    path: '/shop',
    name: 'shop',
    component: Shop,
    meta: {
      showTab: true
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: Cart
  },
  {
    path: '/message',
    name: 'message',
    component: Message,
    meta: {
      showTab: true
    }
  },
  {
    path: '/user',
    name: 'user',
    component: User,
    meta: {
      showTab: true
    }
  },
  {
    path: '/article/:id',
    name: 'article',
    component: Article
  },
  {
    path: '/chat/:id',
    name: 'chat',
    component: Chat
  },
  {
    path: '/launchChat/:id',
    name: 'launchChat',
    component: LaunchChat
  },
  {
    path: '/product/:id',
    name: 'product',
    component: Product
  },
  {
    path: '/addressList/:key?',
    name: 'addressList',
    component: AddressList
  },
  {
    path: '/addressEdit/:id?',
    name: 'addressEdit',
    component: AddressEdit
  },
  {
    path: '/orderList/:status?',
    name: 'orderList',
    component: OrderList
  },
  {
    path: '/order/:id',
    name: 'order',
    component: Order
  },
  {
    path: '/payOrder',
    name: 'payOrder',
    component: PayOrder
  },
  {
    path: '/payment/:orderId',
    name: 'payment',
    component: Payment
  },
  {
    path: '/question',
    name: 'question',
    component: Question
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/huangli',
    name: 'huangli',
    component: Huangli
  },
  {
    path: '/beihun',
    name: 'beihun',
    component: BeiHun
  },
  {
    path: '/collect/:tab?',
    name: 'collect',
    component: Collect
  },
  {
    path: '/selectCoupon',
    component: SelectCoupon
  },
  {
    path: '/couponList',
    component: CouponList
  },
  {
    path: '/coupon/:id',
    component: Coupon
  },
  {
    path: '/class/:id',
    component: Class
  },
  {
    path: '/test',
    component: Test
  },
  {
    path: '/articleSearch/:keyword?',
    component: ArticleSearch
  },
  {
    path: '/friend',
    component: Friend,
    meta: {
      showTab: true
    }
  },
  {
    path: '/friendEdit',
    component: FriendEdit
  },
  {
    path: '/friendUser/:id',
    component: FriendUser
  },
  {
    path: '/friendSubmit',
    component: FriendSubmit
  },
  {
    path: '/friendRealName',
    component: FriendRealName
  },
  {
    path: '/businessIndex/:id',
    component: Business
  },
  {
    path: '/map/:lng/:lat',
    component: Map
  }
]

const routes = baseRouter.concat(businessRouter)

const router = new VueRouter({
  routes
})

// 注册一个导航守卫，进行授权判断
// 1.已经登录则执行next()
// 2.没有登录跳转中央授权组件
router.beforeEach((to, from, next) => {
  store.commit('setBeforeUrl', from)
  const userAuth = getCookie('userAuth')
  // console.log(userAuth)
  // console.log(to)
  // console.log(from)
  // 前两个条件代表进入商城业务页面，后一个条件代表没有授权
  if (to.name !== 'auth' && to.name !== 'authNotify' && (userAuth == 0)) {
    setLocalStorage('beforeLoginUrl', to.fullPath)
    next({
      name: 'auth'
    })
  } else if ((to.name === 'auth' || to.name === 'authNotify') && (userAuth == 1)) {
    // 如果进入授权或者回调页面，并且当前已经登录 这个情况包含axios请求status -99的情况
    next({
      name: 'home'
    })
  } else {
    // 这边主要要处理【已经授权再次进入页面的情况】不会进入回调，所以很多操作需要在这边设置，不能在回调一次性设置
    // 当前已经授权了，不会去回调组件，这边设置一下Title
    // setShopTitle(getLocalStorage('shopName'))
    // 每进入一个页面，都默认设置分享配置
    // const currentBrowser = getLocalStorage('currentBrowser')
    // if (currentBrowser === 'weixin') {
    //   WXJsdk.methods.localStorageWxConfig()
    //   WXJsdk.methods.WxShareConfig('一碗商城', '好吃好玩，品质生活一键get！', globalConfig.data().webUrl + '#' + to.path, 'https://seetop-1257860468.file.myqcloud.com/seetop/uploads/20201010/8221bbf6f9ccc163d0d8fdee03c80440.jpg')
    // } else if (currentBrowser === 'unionpay') {
    //   UnionPayJsdk.methods.localStorageUnionPayConfig()
    // }
    next()
  }
})

export default router
