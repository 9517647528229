<template>
    <div>
        <van-tabs v-model="active" @click="clickTab" sticky>
            <van-tab title="文章">
                <div style="width: 100%;height: 1rem;"></div>
                <van-list
                        v-model="listLoading"
                        :finished="listLoadingFinished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <waterfall ref="waterfall" :show-data="listLoadingList"></waterfall>
                </van-list>
            </van-tab>
            <van-tab title="商品">
                <van-list
                        v-model="listLoading"
                        :finished="listLoadingFinished"
                        finished-text="没有更多了"
                        loading-text=""
                        @load="onLoad"
                >
                    <div class="product-list">
                        <product-item-middle
                                v-for="(item, index) in listLoadingList" :key="index"
                                :title="item.name"
                                :price="item.mallprice"
                                :img="item.img"
                                :cellId="item.product_cell_id"
                        ></product-item-middle>
                    </div>
                </van-list>
            </van-tab>
        </van-tabs>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import FloatNav from '../components/FloatNav'
  import Waterfall from '../components/Waterfall'
  import { globalConfig, listLoading } from '../utils/mixin'
  import ProductItemMiddle from '../components/ProductItemMiddle'
  export default {
    name: 'Collect',
    components: { ProductItemMiddle, Waterfall, FloatNav },
    mixins: [globalConfig, listLoading],
    data () {
      return {
        active: 0,
        currentTab: 'article'
      }
    },
    methods: {
      //  加载数据
      loadData: function (type) {
        this.listLoadingInit(this.apiUrl + 'index/collect/getCollectList', 'list', 1, {
          page: 1,
          type: type
        })
      },
      clickTab: function (name, title) {
        console.log(name, title)
        let currentClick = ''
        if (title === '文章') {
          currentClick = 'article'
        } else if (title === '商品') {
          currentClick = 'product'
        }
        console.log(currentClick)
        if (currentClick === this.currentTab) {} else {
          this.currentTab = currentClick
          if (currentClick == 'article') {
            if (Object.keys(this.$refs).indexOf('waterfall') === 0) {
              this.$refs.waterfall.forceResetData()
            }
            this.loadData(currentClick)
          } else {
            this.loadData(currentClick)
          }
        }
        // console.log(currentClick)
      }
    },
    mounted () {
      if (this.$route.params.tab) {
        this.active = parseInt(this.$route.params.tab)
        if (this.$route.params.tab == 0) {
          this.currentTab = 'article'
        } else {
          this.currentTab = 'product'
        }
      }
      this.loadData(this.currentTab)
    }
  }
</script>

<style scoped>
    .product-list {
        padding: 0.5rem;
        width: 100%;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        box-sizing: border-box;
    }
</style>
