<template>
    <div>
        <div class="space"></div>
        <div class="footer-nav screen-save">
            <router-link to="/home" :class="[currentPath == '/home' || currentPath == '/' ? 'footer-active' : 'footer-default','footer-nav-item']">
                <img class="footer-img" :src="currentPath == '/home' || currentPath == '/' ? 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城tab2.0-首页点击.gif' : 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城tab2.0-首页未点击.png'">
                <div>首页</div>
            </router-link >
            <router-link to="/shop" :class="[currentPath == '/shop' ? 'footer-active' : 'footer-default','footer-nav-item']">
                <img class="footer-img" :src="currentPath == '/shop' ? 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城tab2.0-商城点击.gif' : 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城tab2.0-商城未点击.png'">
                <div>商城</div>
            </router-link>
            <router-link to="/friend" :class="[currentPath == '/friend' ? 'footer-active' : 'footer-default','footer-nav-item']">
                <img class="footer-img" :src="currentPath == '/friend' ? 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城tab2.0-交友点击.gif' : 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城tab2.0-交友未点击.png'">
                <div>交友</div>
            </router-link>
            <router-link to="/message" :class="[currentPath == '/message' ? 'footer-active' : 'footer-default','footer-nav-item']">
                <img class="footer-img" :src="currentPath == '/message' ? 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城tab2.0-咨询点击.gif' : 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城tab2.0-咨询未点击.png'">
                <div>咨询</div>
            </router-link>
            <router-link to="/user" :class="[currentPath == '/user' ? 'footer-active' : 'footer-default','footer-nav-item']">
                <img class="footer-img" :src="currentPath == '/user' ? 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城tab2.0-我的点击.gif' : 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城tab2.0-我的未点击.png'">
                <div>我的</div>
            </router-link>
        </div>
    </div>
</template>
<script>
  export default {
    name: 'NavBar',
    data () {
      return {
        currentPath: ''
      }
    },
    mounted () {
      this.currentPath = this.$route.path
      // console.log(screen.width, screen.height)
      // alert(screen.width + ' - ' + screen.height)
    },
    watch: {
      $route: function () {
        this.currentPath = this.$route.path
      }
    }
  }
</script>

<style scoped>
    .footer-nav{
        display: flex;
        flex-flow: row;
        z-index: 99;
        background-color: #ffffff;
        position: fixed;
        left: 0;
        bottom:0;
        width: 100%;
        align-items: center;
        border-top: 0.03rem solid #d1d1d1;
        color: #535353;
        padding: 0.5rem 0;
    }
    .footer-nav-item {
        width: 25%;
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
    }
    .footer-nav-item div {
        /*font-size: 0.9rem;*/
        margin-top: 0.2rem;
    }
    .footer-default{
        color: #535353;
    }
    .footer-nav > a > div{
        font-size: 0.8rem;
    }
    .footer-active {
        color: #F64C4C;
    }
    .space {
        width: 100%;
        height: 5rem;
    }
    .footer-img {
        width: 1.8rem;
        min-height: 1.5rem;
    }
    /**   iphone X  */
    @media only screen and (device-width: 375px) and (device-height: 724px) and (-webkit-device-pixel-ratio: 3) {
        .screen-save {
            padding-bottom: 23px;
        }
    }
    /**   iphone XR  */
    @media only screen and (device-width: 414px) and (device-height: 808px) and (-webkit-device-pixel-ratio: 2) {
        .screen-save {
            padding-bottom: 23px;
        }
    }
    /**   iphone 13  */
    @media only screen and (device-width: 390px) and (device-height: 844px) and (-webkit-device-pixel-ratio: 3) {
        .screen-save {
            padding-bottom: 23px;
        }
    }
</style>
