<!--交友-->
<template>
    <div>
        <!--顶部tab-->
        <div class="top-tab">
            <tabs :tabs="tabs" style="width: 70%;" active-color="#F64C4C" @click-tab="clickTab"></tabs>
            <router-link to="friendEdit" class="friend-wrapper">
                <img :src="userHead">
                <span>个人信息</span>
            </router-link>
        </div>
        <!-- 动态列表 -->
        <div class="moment-list">
            <van-list
                    v-model="listLoading"
                    :finished="listLoadingFinished"
                    finished-text="没有更多了"
                    @load="onLoad"
            >
                <friend-moment v-for="(item, index) in listLoadingList" :key="index"
                               :headimgurl="item.weixin.headimgurl"
                               :weixin-nick="item.weixin.weixinNick"
                               :sex="item.user.sex"
                               :age="item.user.age"
                               :stature="item.user.stature"
                               :weight="item.user.weight"
                               :content="item.content"
                               :img="item.img"
                               :create-time="item.create_time"
                               :id="item.id"
                               :support="item.like"
                               :has-like="item.hasLike"
                               :last-comment="item.lastComment"
                               :has-more-comment="item.hasMoreComment"
                               :user-head="userHead"
                               :user-name="userName"
                               :user-id="item.user.id"
                ></friend-moment>
            </van-list>
        </div>
        <div @click="toSubmitMoment">
            <img class="publish"
                 src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E5%8F%91%E8%A1%A8%E6%96%87%E7%AB%A0%E6%8C%89%E9%92%AE.png"
            >
        </div>
    </div>
</template>

<script>
  import Tabs from '../components/Tabs'
  import { globalConfig, listLoading } from '../utils/mixin'
  import FriendMoment from '../components/FriendMoment'

  export default {
    name: 'Friend',
    components: {
      FriendMoment,
      Tabs
    },
    mixins: [globalConfig, listLoading],
    data () {
      return {
        tabs: ['全部', '海峡两岸', '本地交友'],
        userStatus: 0,
        userHead: '',
        userName: ''
      }
    },
    methods: {
      // 只判断1和-2，因为可以浏览的都是这两种状态的用户
      toSubmitMoment: function () {
        if (this.userStatus === 1) {
          this.$router.push('/friendSubmit')
        } else if (this.userStatus === -2) {
          this.$toast({
            message: '请先完善个人信息',
            onClose: () => {
              this.$router.push('friendEdit')
            }
          })
        }
      },
      clickTab: function (e) {
        console.log(e)
        let type = 1
        if (e === '全部') {
          type = ''
        } else if (e === '海峡两岸') {
          type = 1
        } else if (e === '本地交友') {
          type = 2
        }
        this.listLoadingInit(this.apiUrl + 'index/friend/getFriendMomentList', 'list', 1, {
          type: type
        })
      }
    },
    mounted () {
      // 绑定和实名在这边统一判断，如果恶意进入后续页面直接阻止运行不考虑用户体验
      this.axios.post(this.apiUrl + 'index/friend/friendListInit', {}).then((response) => {
        console.log(response.data)
        this.userStatus = response.data.status
        if (response.data.status === 1) {
          this.userHead = response.data.info.head
          this.userName = response.data.info.weixinNick
        } else if (response.data.status === -4) {
          this.$router.push('friendRealName')
        } else if (response.data.status === -1) {
          this.$router.replace('/bind?url=' + window.location.href)
        } else if (response.data.status === -2) {
          // 没有完善个人信息，但是做了实名认证，允许浏览
          this.userHead = response.data.info.head
          this.userName = response.data.info.weixinNick
        } else {
          this.$toast({
            message: response.data.msg,
            onClose: () => {
              this.$router.push('user')
            }
          })
        }
      })
      this.listLoadingInit(this.apiUrl + 'index/friend/getFriendMomentList', 'list', 1, {})
    }
  }
</script>

<style scoped>
    .top-tab {
        width: 100%;
        background-color: #FFFFff;
        position: fixed;
        left: 0;
        top: 0;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-around;
        z-index: 1;
    }

    .friend-wrapper {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin: 0.15rem 0;
    }

    .friend-wrapper span {
        font-size: 0.7rem;
        margin: 0.3rem 0 0 0;
        color: #666666;
    }

    .friend-wrapper img {
        width: 2rem;
        height: 2rem;
        border-radius: 10rem;
        box-shadow: 0 0 0.1rem #888888;
    }

    .moment-list {
        display: flex;
        flex-flow: column;
        margin-top: 4rem;
    }

    .publish {
        width: 3.2rem;
        height: 3.2rem;
        position: fixed;
        right: 1rem;
        bottom: 5rem;
    }
</style>
