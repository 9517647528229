<template>
    <div>
        <div class="msg-container">
            <img class="logo"
                 src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E5%AE%9E%E5%90%8D%E8%AE%A4%E8%AF%81.png">
            <div class="msg">进入交友专区请先完成实名认证</div>
            <div class="button-container">
                <div class="button" @click="jumpRealName">前往认证</div>
                <router-link to="/home" class="button">回到首页</router-link>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'FriendRealName',
    methods: {
      jumpRealName: function () {
        this.$toast('敬请期待')
      }
    }
  }
</script>

<style scoped>
    .msg-container {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin-top: 7rem;
    }

    .logo {
        width: 70%;
    }

    .msg {
        font-size: 1rem;
        margin: 2rem 0;
    }

    .button-container {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }

    .button {
        background-color: #F64C4C;
        border-radius: 10rem;
        padding: 0.7rem 3rem;
        color: #FFFFff;
        margin: 0 0.5rem;
    }
</style>
