<template>
    <div>
        <!--banner-->
        <van-swipe indicator-color="white" :loop="false">
            <van-swipe-item v-for="item,key in banner" :key="key">
                <img class="swipe-img" :src="item">
            </van-swipe-item>
        </van-swipe>
        <!--商品内容-->
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text=""
                loading-text=""
                @load="onLoad"
        >
            <div class="product-list">
                <product-item-middle
                        v-for="(item, index) in listLoadingList" :key="index"
                        :title="item.name"
                        :price="item.mallprice"
                        :img="item.img"
                        :cellId="item.product_cell_id"
                ></product-item-middle>
            </div>
        </van-list>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import ProductItemMiddle from '../components/ProductItemMiddle'
  import { globalConfig, listLoading } from '../utils/mixin'
  import FloatNav from '../components/FloatNav'
  export default {
    name: 'Class',
    components: { FloatNav, ProductItemMiddle },
    mixins: [globalConfig, listLoading],
    data () {
      return {
        group: '',
        banner: [
          'https://seetop-1257860468.file.myqcloud.com/panjiang/img/banner1.png',
          'https://seetop-1257860468.file.myqcloud.com/panjiang/img/banner2.png',
          'https://seetop-1257860468.file.myqcloud.com/panjiang/img/banner3.png'
        ]
      }
    },
    methods: {
      loadData: function () {
        this.listLoadingInit(this.apiUrl + 'index/product/getGroupProductList', 'list', 1, {
          group: this.group,
          keyword: ''
        })
      }
    },
    mounted () {
      this.group = this.$route.params.id
      console.log(this.group)
      this.loadData(1)
    }
  }
</script>

<style scoped>
    .swipe-img {
        width: 100%;
        height: 200px;
    }
    .product-list {
        padding: 0.5rem;
        width: 100%;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        box-sizing: border-box;
    }
</style>
