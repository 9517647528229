<!--商品块（中）-->
<template>
    <div class="product-wrapper" @click="clickProduct">
        <img class="product-img" :src="'https://seetop-1257860468.file.myqcloud.com/panjiang/' + img">
        <div class="product-name">
            {{title}}
        </div>
        <div class="product-footer">
            <div>
<!--                默认标签-->
            </div>
            <div>￥{{moneyFen2Yuan(price)}}</div>
        </div>
    </div>
</template>

<script>
    import { moneyFen2Yuan } from '../utils/tools'
    export default {
    name: 'ProductItemMiddle',
    props: ['cellId', 'title', 'price', 'tip', 'img'],
    methods: {
      moneyFen2Yuan,
      clickProduct: function () {
        this.$router.push('/product/' + this.cellId)
      }
    }
  }
</script>

<style scoped>
    .product-wrapper {
        display: flex;
        flex-flow: column;
        border-radius: 8px;
        overflow: hidden;
        break-inside: avoid;
        background-color: #ffffff;
        margin-bottom: 1rem;
        width: 49%;
        box-sizing: border-box;
    }
    .product-img {
        width: 100%;
    }
    .product-name {
        overflow:hidden;
        text-overflow:ellipsis;
        display:-webkit-box;
        -webkit-box-orient:vertical;
        -webkit-line-clamp:2;
        padding: 0.2rem 0.5rem 0;
        font-weight: 600;
    }
    .product-footer {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        padding: 0.2rem 0.8rem 0.5rem;
    }
    .product-footer div:first-child {
        font-size: 0.8rem;
        color: #999999;
    }
    .product-footer div:nth-child(2) {
        font-size: 0.9rem;
        color: #F64C4C;
        font-weight: 600;
    }
</style>
