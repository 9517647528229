<!--小标签-->
<template>
    <div class="icon-wrapper">
        <transition name="fade">
            <img v-show="isShow" class="icon-img" :src="img">
        </transition>
        <div>{{name}}</div>
    </div>
</template>

<script>
  export default {
    name: 'IconTag',
    props: ['img', 'name'],
    data () {
      return {
        isShow: true
      }
    }
  }
</script>

<style scoped>
    .icon-wrapper {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
    }
    .icon-img {
        width: 1.6rem;
        height: 1.6rem;
    }
    .icon-wrapper > div:nth-child(2) {
        font-size: 0.9rem;
        color: rgb(151,151,151);
        margin-top: 0.2rem;
    }
    .text-enter, .text-leave-to {
        transition: opacity .5s;
    }
    .text-enter-avtive, .text-leave-active {
        opacity: 0;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
