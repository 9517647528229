<!--商品详情-->
<template>
    <div>
        <!--banner-->
        <van-swipe indicator-color="white" :loop="false" v-if="banner.length > 0">
            <van-swipe-item v-for="item,key in banner" :key="key">
                <img class="swipe-img"
                     :src="item"
                     @click="clickBanner(key)"
                >
            </van-swipe-item>
        </van-swipe>
        <!--商品基础信息-->
        <div class="product-title-wrapper">
            <div class="product-title">{{title}}</div>
            <div class="label-container" v-if="label.length > 0">
                <div v-for="item,key in label" :key="key">{{item}}</div>
            </div>
            <div class="product-title-footer">
                <div class="product-price-wrapper">
                    <div>￥{{salePrice}}</div>
                    <div>原价:￥{{orgPrice}}</div>
                    <!--                    <div>券后￥39.9</div>-->
                </div>
                <div class="product-icon-wrapper">
                    <icon-tag :img="collectIcon" name="收藏" @click.native="clickCollect"></icon-tag>
                </div>
            </div>
        </div>
        <!--商户入口-->
        <div class="business-container">
            <img :src="'https://seetop-1257860468.file.myqcloud.com/panjiang/' + trademark">
            <div>
                <div>{{businessName}}</div>
                <div>{{address}}</div>
            </div>
            <div>
                <div class="view-store" @click="viewStore">进店</div>
                <div class="business-right">
                    <a @click="viewMap">
                        <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/business-%E5%9C%B0%E5%9D%80.png">
                        <div>导航</div>
                    </a>
                    <a :href="'tel:' + phone">
                        <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/busienss-%E7%94%B5%E8%AF%9D.png">
                        <div>电话</div>
                    </a>
                </div>
            </div>
        </div>
        <!--商品详情-->
        <div class="product-detail-wrapper">
            <div class="detail-title-wrapper">
                <img class="icon-small"
                     src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E7%9F%A9%E5%BD%A2.png">
                <div class="detail-title-text">商品详情</div>
                <img class="icon-small"
                     src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E7%9F%A9%E5%BD%A2.png">
            </div>
            <div class="detail-content" v-html="details"></div>
        </div>
        <buy-product-bar-v2 v-if="propsSubProductList"
                            :subProduct="propsSubProductList"
                            :apiTimestamp="apiTimestamp"
                            :productName="title"
                            :cellId="currentCellId"
                            :isBind="isBindUser"
                            :businessId="businessId"
                            :productImg="banner[0]"
                            :productSku="this.productSku"
                            :express="express"
                            :isSubscribe="isSubscribe"
        ></buy-product-bar-v2>
    </div>
</template>

<script>
  import IconTag from '../components/IconTag'
  import { globalConfig } from '../utils/mixin'
  import { ImagePreview } from 'vant'
  import { moneyFen2Yuan, getBetweenNumber } from '../utils/tools'
  import BuyProductBarV2 from '../components/BuyProductBarV2'

  export default {
    name: 'Product',
    components: {
      BuyProductBarV2,
      IconTag
    },
    mixins: [globalConfig],
    data () {
      return {
        currentCellId: '',
        express: 0,
        banner: [],
        title: '',
        salePrice: '',
        orgPrice: '',
        details: '',
        apiTimestamp: '',
        propsSubProductList: '',
        productSku: [],
        isBindUser: false,
        isSubscribe: 0,
        businessId: '',
        hasCollectProduct: false,
        collectIcon: '',
        // 2022.01.07新增一部分商户属性展示
        businessName: '',
        address: '',
        lng: '',
        lat: '',
        phone: '',
        trademark: '',
        label: []
      }
    },
    methods: {
      moneyFen2Yuan,
      getData: function () {
        this.axios.post(this.apiUrl + 'index/product/getProductDetail', {
          id: this.currentCellId
        }).then((response) => {
          const result = response.data
          const data = result.data
          console.log(data)
          this.title = data.cellProductInfo.name
          this.details = data.cellProductInfo.details
          this.apiTimestamp = data.timestamp
          this.propsSubProductList = data.subProductList
          this.productSku = data.cellProductInfo.specs_list
          this.express = data.cellProductInfo.express
          this.isBindUser = data.isBindUser
          this.isSubscribe = data.isSubscribe
          this.hasCollectProduct = data.isCollect
          this.updateCollectIcon(true)
          this.businessId = data.cellProductInfo.businessid
          this.banner = data.cellProductInfo.images
          this.label = data.cellProductInfo.label
          this.banner = this.banner.map((item) => {
            return 'https://seetop-1257860468.file.myqcloud.com/panjiang/' + item
          })
          // 单独处理价格，让数组变成区间
          let salePriceArr = []
          let prgPriceArr = []
          for (const i in data.subProductList) {
            salePriceArr.push(data.subProductList[i].mallprice)
            prgPriceArr.push(data.subProductList[i].price)
          }
          salePriceArr = getBetweenNumber(salePriceArr)
          prgPriceArr = getBetweenNumber(prgPriceArr)
          if (salePriceArr[0] === salePriceArr[1]) {
            this.salePrice = moneyFen2Yuan(salePriceArr[0])
          } else {
            this.salePrice = moneyFen2Yuan(salePriceArr[0]) + '~' + moneyFen2Yuan(salePriceArr[1])
          }
          this.orgPrice = moneyFen2Yuan(prgPriceArr[1])
          this.businessName = data.business.business_name
          this.address = data.business.address
          this.lng = data.business.lng
          this.lat = data.business.lat
          this.phone = data.business.phone
          this.trademark = data.business.trademark
        })
      },
      // 点击banner查看大图
      clickBanner: function (index) {
        ImagePreview({
          images: this.banner,
          startPosition: index
        })
      },
      clickCollect: function () {
        this.$store.commit('setIsShowLoading', false)
        this.axios.post(this.apiUrl + 'index/collect/collect', {
          type: 'product',
          follow: this.currentCellId
        }).then((response) => {
          console.log(response)
          this.hasCollectProduct = !this.hasCollectProduct
          this.updateCollectIcon(false)
        })
      },
      // 处理收藏的动效
      updateCollectIcon: function (isInit) {
        if (isInit) {
          if (this.hasCollectProduct) {
            this.collectIcon = 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E6%96%87%E7%AB%A0%E6%94%B6%E8%97%8F%E7%82%B9%E5%87%BB.png'
          } else {
            this.collectIcon = 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E6%94%B6%E8%97%8F%E6%9C%AA%E7%82%B9%E5%87%BB.png'
          }
        } else {
          if (this.hasCollectProduct) {
            this.collectIcon = 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E6%94%B6%E8%97%8F%E5%8A%A8%E6%95%88.gif'
          } else {
            this.collectIcon = 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E6%94%B6%E8%97%8F%E6%9C%AA%E7%82%B9%E5%87%BB.png'
          }
        }
      },
      // 进店
      viewStore: function () {
        this.$router.push('/businessIndex/' + this.businessId)
      },
      // 查看地址
      viewMap: function () {
        this.$router.push('/map/' + this.lng + '/' + this.lat)
      }
    },
    mounted () {
      this.currentCellId = this.$route.params.id
      this.getData()
      console.log(this.currentCellId)
    }
  }
</script>

<style scoped>
    .swipe-img {
        width: 100%;
    }

    .product-title-wrapper {
        padding: 0.5rem 1rem;
        background-color: #ffffff;
    }

    .product-title {
        font-weight: 600;
        font-size: 1.2rem;
    }

    .product-icon-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
    }

    .product-title-footer {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
    }

    .product-price-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        font-size: 1rem;
    }

    .product-price-wrapper > div:first-child {
        color: #F64C4C;
        font-weight: 600;
    }

    .product-price-wrapper > div:nth-child(2) {
        color: #999999;
        text-decoration: line-through;
        margin-left: 1rem;
    }

    .product-detail-wrapper {
    }

    .detail-title-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        margin: 1rem 0;
    }

    .detail-content {
        width: 100%;
        overflow: hidden;
        background-color: #ffffff;
    }

    .detail-content >>> img {
        width: 100%;
    }

    .icon-small {
        width: 0.8rem;
        height: 0.8rem;
    }

    .detail-title-text {
        font-size: 1.1rem;
        font-weight: 600;
        margin: 0 1rem;
    }

    .product-footer-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        background-color: #ffffff;
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
    }

    .button-wrapper {
        display: flex;
        flex-flow: row;
    }

    .button-wrapper div {
        height: 3.5rem;
        width: 8rem;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
    }

    .button-wrapper > div:first-child {
        color: #ffffff;
        background-color: rgb(246, 189, 76);
    }

    .button-wrapper > div:nth-child(2) {
        color: #ffffff;
        background-color: rgb(246, 76, 76);
    }

    .business-container {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        background-color: #FFFFff;
        margin-top: 1rem;
        padding: 0.5rem 1rem;
    }

    .business-container img {
        width: 5rem;
        height: 5rem;
    }

    .business-container > div:nth-child(2) {
        width: 50%;
    }

    .business-container > div:nth-child(2) > * {
        word-break: break-all;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; /* 这里是超出几行省略 */
        overflow: hidden;
    }

    .business-container > div:nth-child(2) > div:first-child {
        color: #000000;
        font-weight: 600;
        margin-bottom: 1rem;
    }

    .business-container > div:nth-child(2) > div:nth-child(2) {
        color: #999999;
        font-size: 0.85rem;
    }

    .business-container > div:nth-child(3) {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        justify-content: space-between;
    }

    .view-store {
        color: #666666;
        display: flex;
        flex-flow: row;
        align-items: center;
        font-size: 0.9rem;
    }

    .view-store:after {
        width: 1rem;
        height: 1rem;
        content: "";
        background: url("https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E5%90%91%E5%8F%B3-%E9%BB%91.png") no-repeat;
        background-size: 100%;
        display: inline-block;
    }

    .business-right {
        display: flex;
        flex-flow: row;
        margin-top: 2rem;
    }

    .business-right img {
        width: 1.3rem;
        height: 1.3rem;
    }

    .business-right > a {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        font-size: 0.8rem;
        color: #666666;
    }

    .business-right > a:first-child {
        margin-right: 1rem;
    }

    .label-container {
        display: flex;
        flex-flow: row;
        margin-top: 1rem;
    }

    .label-container > div {
        border-radius: 10rem;
        padding: 0.1rem 0.3rem;
        margin-right: 1rem;
        font-size: 0.8rem;
        text-align: center;
        color: #333333;
    }

    .label-container > div:first-child {
        background-color: #FFE5C7;
    }

    .label-container > div:nth-child(2) {
        background-color: #C7F0FF;
    }

    .label-container > div:nth-child(3) {
        background-color: #FFD1C7;
    }
</style>
