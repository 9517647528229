<!--个人中心，对应旧版本的user.html-->
<template>
    <div class="box">
        <div class="tab_bar">
            <div :class="['choose',tab===2?'active':'']" @click="switch_tab('已核销')">核销订单</div>
            <div :class="['choose',tab===1?'active':'']" @click="switch_tab('未核销')">未核销订单</div>
        </div>
        <div style="width: 100%;height: 3rem;"></div>
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="item" v-for="(item,index) in listLoadingList" :key="index">
                <p>id：{{item.id}}</p>
                <p>订单号：{{item.orderID}}</p>
                <p>套餐名：{{item.gooods_name}}</p>
                <p>手机号：{{item.mobile}}</p>
                <p>核销码：{{item.randomcode}}</p>
                <p>数量：{{item.number}}</p>
                <p style="color:#ca1416">金额：￥{{item.total_fee}}</p>
                <p>下单时间：{{item.create_time}}</p>
                <p v-if="item.use_time" style="color:#ca1416">核销时间：{{item.use_time}}</p>
                <p v-if="item.use_time && item.order_type === 'package'" style="color:#ca1416">套票商品核销次数：{{item.check_number}}/{{item.package_number}}（使用中）</p>
                <div  v-if="item.order_type === 'express'">
                    <p>收件人：{{item.name}}</p>
                    <p>收件人手机：{{item.addressMobile}}</p>
                    <p>收件人地址：{{item.address}}</p>
                    <div v-if="item.order_status == '0'" class="item-button" @click="check(item.randomcode)">确认发货</div>
                </div>
            </div>
        </van-list>
        <float-nav-business></float-nav-business>
    </div>
</template>
<script>
  import { globalConfig, listLoading } from '../../utils/mixin'
  import FloatNavBusiness from '../../components/FloatNavBusiness'
  export default {
    name: 'OrderList',
    mixins: [globalConfig, listLoading],
      components: { FloatNavBusiness },
    data () {
      return {
        tab: 2,
        page: 1,
        list: [],
        loading: false
      }
    },
    methods: {
      switch_tab: function (status) {
        switch (status) {
          case '已核销':
            this.tab = 2
            break
          case '未核销':
            this.tab = 1
            break
          default:
            break
        }
        //  初始化
        this.page = 1
        this.list = []
        this.listLoadingInit(this.apiUrl + 'check/order/getOrderList', 'list', 1, {
          status: this.tab
        })
      },
      init: function () {
        this.axios.get(this.apiUrl + 'check/api/init').then((response) => {
          console.log(response)
          const data = response.data
          if (data.status === 0) {
            this.$router.replace('/business/login')
          } else {
            console.log('初始化成功')
          }
        })
      },
        check: function (code) {
            const that = this
            this.$dialog.confirm({
                title: '提示',
                message: '是否确认发货'
            }).then(() => {
                that.axios.post(that.apiUrl + 'check/order/checkCode', {
                    code: code
                }).then((response) => {
                    console.log(response)
                    const data = response.data
                    if (data.status === 1) {
                        this.$toast({
                            message: data.msg,
                            onClose: function () {
                                window.location.reload()
                            }
                        })
                    } else {
                        this.$router.push('/business/warning/' + data.msg)
                    }
                })
            }).catch(() => {})
        }
    },
    created () {
      this.init()
      this.switch_tab('已核销')
    },
    mounted () {
    },
    computed: {
    }
  }
</script>
<style scoped>
    *{
        box-sizing: border-box;
    }
    .box{
        background-color: #FEF9F6;
        padding: 3vw 2vw;
    }
    .tab_bar{
        height: 2.6rem;
        line-height: 2.6rem;
        position: fixed;
        top: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: #ffffff;
        padding: 0 0.5rem;
    }
    .tab_bar .choose{
        font-size: 1rem;
        padding:0.1rem 0.3rem;
        color: #7e7e7e;
    }
    .tab_bar .active{
        border-bottom: 1px solid #ca1416;
        color: #ca1416;
    }
    .item{
        width: 92%;
        margin:0 auto 1rem auto;
        background-color: white;
        box-shadow:0 0 5px #999999;
        border-radius: 1vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding:0.8rem 0.7rem;
    }
    .item p{
        font-size: 1rem;
        color: #808080;
        margin-top: 1.8vw;
    }
    .item-button{
        width: 20vw;
        height: 7vw;
        line-height: 7vw;
        border-radius: 1vw;
        background:#ca1416;
        color: #fff;
        text-align: center;
        margin: 1.8vw 0 0 70vw;
    }
</style>
