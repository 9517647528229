<template>
    <div class="box">
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="item" v-for="(item,index) in listLoadingList" :key="index">
                <div class="item2" v-for="(item2,index2) in item.list" :key="index2">
                    <img :src="'https://seetop-1257860468.file.myqcloud.com/panjiang/' + item2.logo" class="item2-img">
                    <div class="item3">
                        <div>{{item2.name}}</div>
                        <div class="item3-word">
                            <div>￥{{item2.mallprice}}</div>
                            <div>库存：{{item2.redisStock}}</div>
                            <div style="color: #ca1416">{{item2.statusName}}</div>
                        </div>
                        <div class="item3-status">
                            <div @click="toDetails(item2.id)">修改</div>
                            <div v-if="item2.status == '1'" @click='switchStatus(item2.id)'>取消上架</div>
                            <div v-if="item2.status == '0'" @click='switchStatus(item2.id)'>上架</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-list>
        <float-nav-business></float-nav-business>
    </div>
</template>
<script>
    import { globalConfig, listLoading } from '../../utils/mixin'
    import FloatNavBusiness from '../../components/FloatNavBusiness'
    export default {
        name: 'Merchandise',
        mixins: [globalConfig, listLoading],
        components: { FloatNavBusiness },
        data () {
            return {
                page: 1,
                list: [],
                loading: false
            }
        },
        methods: {
            switchStatus: function (e) {
                const that = this
                that.axios.post(that.apiUrl + 'check/business/switchStatus', {
                    id: e
                }).then((response) => {
                    const data = response.data
                    that.$toast(data.msg)
                    window.location.reload()
                })
            },
            toDetails: function (e) {
                this.$router.push('/business/details/' + e)
            },
            init: function () {
                this.axios.get(this.apiUrl + 'check/api/init').then((response) => {
                    console.log(response)
                    const data = response.data
                    if (data.status === 0) {
                        this.$router.replace('/business/login')
                    }
                })
            }
        },
        created () {
            this.init()
            this.listLoadingInit(this.apiUrl + 'check/business/getBusinessList', 'list', 1, {

            })
        },
        mounted () {
        },
        computed: {
        }
    }
</script>

<style scoped>
    *{
        box-sizing: border-box;
        color: #666;
    }
    .box{
        background-color: #FEF9F6;
        padding: 3vw 4vw;
    }
    .item{
        width: 92vw;
        background: #fff;
        padding: 2vw 3vw;
        margin-bottom: 3vw;
    }
    .item2{
        display: flex;
        align-items: center;
        margin: 1vw auto;
    }
    .item2-img{
        width: 20%;
    }
    .item3{
        width: 80%;
        padding: 2vw 2vw 2vw 3vw;
        font-size: 3.6vw;
    }
    .item3 div{
        margin: 1vw 0;
    }
    .item3-word{
        display: flex;
    }
    .item3-word div{
        margin-right: 4vw;
    }
    .item3-status{
        display: flex;
        justify-content: flex-end;
    }
    .item3-status div{
        width: 20vw;
        height: 7vw;
        line-height: 7vw;
        border-radius: 1vw;
        border: 1px solid #ca1416;
        color: #ca1416;
        text-align: center;
        margin-left: 4vw;
    }
    .item3-status div:nth-of-type(1){
        border: 1px solid #666666;
        color: #666666;
    }

</style>
