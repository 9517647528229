<template>
    <div>
        <search-input ref="searchInput" @onArticleSearch="onArticleSearch" @onClickLog="onArticleSearch"
                      class="searchInput"></search-input>
        <div style="width: 100%;height: 4rem;"></div>
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <waterfall ref="waterfall" :show-data="listLoadingList"></waterfall>
        </van-list>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import SearchInput from '../components/SearchInput'
  import { globalConfig, listLoading } from '../utils/mixin'
  import Waterfall from '../components/Waterfall'
  import FloatNav from '../components/FloatNav'

  export default {
    name: 'ArticleSearch',
    components: {
      FloatNav,
      Waterfall,
      SearchInput
    },
    mixins: [globalConfig, listLoading],
    data () {
      return {}
    },
    methods: {
      loadData: function () {
        this.listLoadingInit(this.apiUrl + 'index/article/getIndexList', 'list', 1, {
          type: '',
          keyword: this.$refs.searchInput.getValue()
        })
      },
      onArticleSearch: function () {
        this.$refs.waterfall.forceResetData()
        this.loadData()
      }
    },
    mounted () {
      this.$refs.searchInput.setValue(this.$route.params.keyword)
      this.loadData()
    }
  }
</script>

<style scoped>
    .searchInput {
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
    }
</style>
