<template>
    <div>
        <!--聊天顶部操作栏-->
        <div class="chat-top-wrapper">
            <div></div>
            <div>客服咨询</div>
            <div></div>
        </div>
        <!--聊天内容-->
        <div style="width: 100%;height: 3rem;"></div>
        <div  v-for="(item,index) in messageList" :key="index">
            <div v-if="item.position == 'left'" class="chat-wrapper left">
                <img class="chat-head" :src="item.headImg">
                <!--客服文字-->
                <div class="chat-content">
                    {{item.content}}
                </div>
            </div>
            <div v-else class="chat-wrapper right">
                <div class="chat-content">
                    {{item.content}}
                </div>
                <img class="chat-head" src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E6%94%80%E8%AE%B2%E5%AE%A2%E6%9C%8D%E5%A4%B4%E5%83%8F.png">
            </div>
        </div>
        <div style="width: 100%;height: 5rem;"></div>
        <!--操作栏-->
        <div class="option-footer" v-if="chatStatus == '1'">
            <div class="comment-input" @click="clickPublish">输入您想知道的问题</div>
            <div class="option-right-wrapper">
                <a :href="'tel:' + mobile">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/icon2022012402.png">
                    <div>电话</div>
                </a>
                <a href="#" @click="messageOver()">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/icon2022012401.png">
                    <div>结束</div>
                </a>
                <a href="#" @click="toHome()">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%AF%A6%E6%83%85%E9%A1%B5-%E9%A6%96%E9%A1%B5.png"/>
                    <div>首页</div>
                </a>
            </div>
        </div>
        <div v-else>
            <div class="nav-container" >
                <div class="nav-wrapper" v-if="showReturn" style="margin-bottom: 1rem">
                    <div class="nav-icon-wrapper" @click="backPage">
                        <img src="https://seetop-1257860468.file.myqcloud.com/vue/img/float-nav1.png" class="nav-img">
                    </div>
                </div>
                <div class="nav-wrapper">
                    <a :href="'tel:' + mobile">
                        <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/icon2022012402.png" class="nav-img">
                    </a>
                </div>
            </div>
            <div class="close-text">对话已结束</div>
        </div>

        <!--弹出框评论-->
        <van-action-sheet v-model="showPublish" title="咨询" :round="false">
            <div>
                <textarea class="comment-textarea" maxlength="128" placeholder="输入您想知道的问题（小于128字）"
                          v-model="inputText"></textarea>
                <div class="comment-publish" @click="sendMessage">发送</div>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
  import { globalConfig } from '../../utils/mixin'
  export default {
    name: 'Chat',
    mixins: [globalConfig],
    data () {
      return {
          id: '',
          inputText: '',
          commonProblem: [],
          messageList: [],
          showPublish: false,
          mobile: '',
          chatStatus: 1,
          showReturn: true
      }
    },
    methods: {
        backPage: function () {
            this.$router.go(-1)
        },
        // 点击发布评论，弹出评论输入框
        clickPublish: function () {
            this.showPublish = true
        },
        toHome: function () {
            this.$router.push('/business/index')
        },
        init: function () {
            this.axios.post(this.apiUrl + 'check/chat/getChatMessage', {
                id: this.id
            }).then((response) => {
                console.log(response)
                const data = response.data
                if (data.status === 1) {
                    this.messageList = data.list
                    this.mobile = data.mobile
                    this.chatStatus = data.chatStatus
                    setTimeout(function () {
                        window.scrollTo(0, document.body.scrollHeight)
                    }, 50)
                } else if (data.status === '-1') {
                    this.$router.push('/business/warning/' + data.msg)
                } else {
                    this.$router.replace('/business/login')
                }
            })
        },
        sendMessage: function () {
            if (this.inputText) {
                this.axios.post(this.apiUrl + 'check/chat/sendChatMessage', {
                    id: this.id,
                    message: this.inputText
                }).then((response) => {
                    console.log(response)
                    const data = response.data
                    if (data.status === 1) {
                        this.$toast(data.msg)
                        this.messageList.push({
                            position: 'right',
                            content: this.inputText
                        })
                        this.inputText = ''
                        this.showPublish = false
                        setTimeout(function () {
                            window.scrollTo(0, document.body.scrollHeight)
                        }, 10)
                    } else {
                        this.$toast(data.msg)
                    }
                })
            }
        },
        messageOver: function () {
            var that = this
            this.axios.post(this.apiUrl + 'check/chat/getChatMessageOver', {
                id: this.id
            }).then((response) => {
                console.log(response)
                const data = response.data
                if (data.status === 1) {
                    this.$toast({
                        message: data.msg,
                        onClose: function () {
                            that.$router.push('/business/chat')
                        }
                    })
                } else {
                    this.$toast(data.msg)
                }
            })
        }
    },
    mounted () {
        this.id = this.$route.params.id
        this.init()
        const beforeUrl = this.$store.state.beforeUrl
        const name = beforeUrl.name
        // 如果是刚授权完进来，或者没有上一页信息，那么就不显示返回按钮了
        if (name === 'auth' || name === 'authNotify' || name === null) {
            this.showReturn = false
        }
    }
  }
</script>

<style scoped>
    .chat-wrapper {
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: flex-start;
        padding: 1rem 1rem 0 1rem;
        box-sizing: border-box;
    }
    .left {
        justify-content: flex-start;
    }
    .right {
        justify-content: flex-end;
    }
    .chat-head {
        width: 3rem;
        height: 3rem;
        border-radius: 10rem;
    }
    .chat-content {
        max-width: 60%;
        border-radius: 1rem;
        background-color: #ffffff;
        margin: 0 1rem;
        padding: 1rem;
        word-wrap:break-word
    }
    .chat-top-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        box-sizing: border-box;
        background-color: #ffffff;
        padding: 1rem 1rem;
        font-weight: 600;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        font-size: 1.1rem;
    }
    .option-footer {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        box-sizing: border-box;
        padding: 0.8rem 1rem;
    }
    .comment-input {
        width: 55%;
        color: #999999;
        background-color: #d9d9d9;
        border-radius: 10rem;
        padding: 0.5rem 1rem;
        text-align: center;
    }

    .comment-textarea {
        border: none;
        background-color: #ebebeb;
        padding: 1rem;
        width: 90%;
        height: 7rem;
        box-sizing: border-box;
        margin: 0 auto;
        display: block;
        border-radius: 0.2rem;
    }
    .comment-publish {
        width: 20rem;
        color: #ffffff;
        padding: 1rem 2rem;
        background-color: #F64C4C;
        margin: 0.7rem auto 1.2rem;
        text-align: center;
        border-radius: 0.2rem;
    }
    .option-right-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-around;
    }
    .option-right-wrapper img {
        width: 1.5rem;
        height: 1.5rem;
    }
    .option-right-wrapper > a {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        font-size: 0.7rem;
        color: #666666;
        margin-left: 0.8rem;
    }
    .nav-container {
        position: fixed;
        right: 1rem;
        bottom: 5rem;
        display: flex;
        flex-flow: column;
    }
    .nav-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 10rem;
        background: #ffffff;
        box-shadow: 0 0 0.6rem #d2d2d2;
    }
    .nav-img {
        width: 2rem;
        height: 2rem;
    }
    .close-text {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100vw;
        padding: 1rem 0;
        color: #888888;
        font-size: 1rem;
        text-align: center;
        background-color: #fff;
    }
</style>
