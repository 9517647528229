<!--普通核销商品 express = 0-->
<template>
    <div class="check-wrapper">
        <common-title title="核销二维码"></common-title>
        <div class="check-item-wrapper" style="justify-content: center;" v-if="orderStatus == 0">
            <vue-qr id="qr" :text="checkUrl"></vue-qr>
        </div>
        <div class="check-item-wrapper">
            <p>套餐名</p>
            <p>
                <span style="margin-right: 3vw">{{productName}}</span>
                <span v-for="items in specs" :key="items">
                {{items}}
                </span>
            </p>
        </div>
        <div class="check-item-wrapper">
            <p>订单号</p>
            <p>{{orderId}}</p>
        </div>
        <div class="check-item-wrapper" v-if="orderStatus == 0">
            <p>核销码</p>
            <p>{{randomCode}}</p>
        </div>
        <div class="check-item-wrapper">
            <p>过期时间</p>
            <p>{{validEndTime}}</p>
        </div>
        <div class="check-item-wrapper">
            <p>状态</p>
            <p style="color: #fa1f1f;">{{statusStr}}</p>
        </div>
        <div class="check-item-wrapper" v-if="orderStatus == 0">
            <p>温馨提示</p>
            <p>请手持二维码至线下门店，出示二维码进行核销使用。可提前一天预约，尽量避免影响用餐。</p>
        </div>
        <div class="check-item-wrapper" style="justify-content:space-between;">
            <p></p>
            <p style="width: 30vw;text-align: right;">共计：<span style=";color:#ff3b30"> ￥{{computedMoney}}</span></p>
        </div>
    </div>
</template>

<script>
  import VueQr from 'vue-qr'
  import CommonTitle from './CommonTitle'
  import { moneyFen2Yuan } from '../../utils/tools'
  export default {
    name: 'OrderCheckBodyCommon',
    props: ['checkUrl', 'productName', 'specs', 'orderId', 'randomCode', 'validEndTime', 'money', 'orderStatus', 'statusStr'],
    components: { CommonTitle, VueQr },
    computed: {
      computedMoney: function () {
        return moneyFen2Yuan(this.money)
      }
    },
    mounted () {
      console.log(this.checkUrl)
    }
  }
</script>

<style scoped>
    * {
        box-sizing: border-box;
    }
    .check-wrapper{
        background-color: #fff;
        width: 96vw;
        padding: 3vw;
        margin: 3vw 2vw;
        box-shadow: 0 0 8px #ccc;
        border-radius: 1.5vw;
    }
    .check-item-wrapper{
        display: flex;
        width: 95%;
        border-top: 1px solid #eee;
        font-size: 3.4vw;
        color: #666;
        padding: 1.8vw 2vw;
        margin: 0 auto;
    }
    .check-item-wrapper p:nth-child(1){
        width: 18vw;
        color: #333;
    }
    .check-item-wrapper p:nth-child(2){
        width: 60vw;
        color: #333;
    }
</style>
