<template>
    <div>
        <van-search
                v-model="value"
                input-align="center"
                placeholder="请输入你想搜索的内容"
                @click="clickSearch"
        />
        <van-overlay :show="isSearch" @click.stop="isSearch = false" z-index="999">
            <form action="/">
                <van-search
                        v-model="value"
                        show-action
                        input-align="center"
                        placeholder="请输入你想搜索的内容"
                        @search="onSearch"
                        @cancel="onCancel"
                        @click.stop
                />
                <div class="log-container" @click.stop>
                    <div class="log-title" v-if="searchLog.length > 0">历史记录</div>
                    <div class="log-wrapper" v-if="searchLog.length > 0">
                        <span v-for="item,key in searchLog" :key="key" @click="onClickLog(item)">{{item}}</span>
                    </div>
                    <div class="log-title">热门搜索</div>
                    <div class="log-wrapper">
                        <span @click="onClickLog('钻戒')" class="redLog">钻戒</span>
                    </div>
                </div>
            </form>
        </van-overlay>
    </div>
</template>

<script>
  import { getLocalStorage, setLocalStorage } from '../utils/localStorage'

  export default {
    name: 'SearchInput',
    data () {
      return {
        isSearch: false,
        value: '',
        searchLog: []
      }
    },
    methods: {
      clickSearch: function () {
        this.isSearch = true
      },
      onSearch: function () {
        this.isSearch = false
        // 写入缓存
        const searchLog = getLocalStorage('searchLog')
        if (searchLog) {
          const arr = JSON.parse(searchLog)
          if (arr.indexOf(this.value) >= 0) {
            // 存在这条记录，则不再写入
          } else {
            // 写入，避免越来越多，限制12个
            if (arr.length >= 15) {
              arr.pop()
            }
            arr.unshift(this.value)
            setLocalStorage('searchLog', JSON.stringify(arr))
          }
        } else {
          // 第一次记录
          const arr = []
          arr.unshift(this.value)
          setLocalStorage('searchLog', JSON.stringify(arr))
        }
        this.freshShowLog()
        // 事件传递
        this.$emit('onArticleSearch')
      },
      onCancel: function () {
        this.isSearch = false
      },
      onClickLog: function (keyword) {
        this.isSearch = false
        this.setValue(keyword)
        this.$emit('onClickLog')
      },
      getValue: function () {
        return this.value
      },
      setValue: function (val) {
        this.value = val
      },
      // 刷新vue里面的searchLog参数
      freshShowLog: function () {
        const searchLog = getLocalStorage('searchLog')
        if (searchLog) {
          this.searchLog = JSON.parse(searchLog)
        } else {
          this.searchLog = []
        }
      }
    },
    mounted () {
      this.freshShowLog()
    }
  }
</script>

<style scoped>
    .log-container {
        background-color: #FFFFff;
        padding: 0 0 0.5rem 0;
    }
    .log-title {
        font-weight: 600;
        font-size: 1rem;
        padding: 0.3rem 0 0.3rem 1.25rem;
        color: #333333;
    }
    .log-wrapper {
        display: flex;
        flex-flow: wrap;
        margin-top: 0.5rem;
    }
    .log-wrapper span {
        background-color: #F8F8F8;
        color: #333333;
        border-radius: 10rem;
        padding: 0.2rem 1.3rem;
        margin: 0 0 0.7rem 0.5rem;
    }
    .redLog {
        color: #F64C4C !important;
    }
</style>
