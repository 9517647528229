<template>
  <div id="app">
    <router-view/>
    <div class="load-pop" v-if="this.$store.state.loading">
      <img class="load" src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E5%8A%A0%E8%BD%BD%E5%8A%A8%E7%94%BB3.gif">
    </div>
    <nav-bar v-show="this.$route.meta.showTab"></nav-bar>
  </div>
</template>

<script>
  import NavBar from './components/NavBar'
  export default {
    components: { NavBar }
  }
</script>

<style>
  .load-pop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;
    text-align: center;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
  }
  .load {
    width: 60px;
    height: 60px;
  }
</style>
