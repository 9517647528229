<!--地址编辑-->
<template>
    <div>
        <van-address-edit
                :area-list="areaList"
                :area-columns-placeholder="['请选择', '请选择', '请选择']"
                @save="onSave"
                :address-info="addressInfo"
                areaValue="山西"
        />
    </div>
</template>

<script>
  import { areaList } from '../utils/area.json'
  import { globalConfig } from '../utils/mixin'

  export default {
    name: 'AddressEdit',
    mixins: [globalConfig],
    data () {
      return {
        addressId: '',
        areaList,
        addressInfo: {}
      }
    },
    methods: {
      onSave (val) {
        console.log(val)
        if (val.tel.indexOf(' ') !== -1) {
          this.$toast('手机号不能包含空格')
          return
        }
        if (val.name.indexOf(' ') !== -1) {
          this.$toast('姓名不能包含空格')
          return
        }
        if (this.addressId) {
          this.edit(val)
        } else {
          this.add(val)
        }
      },
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'index/address/info', {
          id: this.addressId
        }).then((response) => {
          const data = response.data
          const code = that.getAreaCode(data.info.province, data.info.city, data.info.district)
          that.addressInfo = {
            name: data.info.name,
            tel: data.info.mobile,
            addressDetail: data.info.detail,
            areaCode: code
          }
        })
      },
      edit: function (val) {
        const that = this
        this.axios.post(this.apiUrl + 'index/address/edit', {
          addressid: this.addressId,
          name: val.name,
          mobile: val.tel,
          province: val.province,
          city: val.city,
          district: val.county,
          detail: val.addressDetail
        }).then((response) => {
          const data = response.data
          if (data.status) {
            that.$toast({
              message: '修改成功',
              onClose: function () {
                // 判断一下是否从下单那边过来
                if (that.$store.state.addressIsOrder === 1) {
                  that.$router.replace('/addressList/order')
                } else {
                  that.$router.replace('/addressList')
                }
              }
            })
          }
        })
      },
      add: function (val) {
        const that = this
        that.axios.post(that.apiUrl + 'index/address/add', {
          name: val.name,
          mobile: val.tel,
          province: val.province,
          city: val.city,
          district: val.county,
          detail: val.addressDetail
        }).then((response) => {
          const data = response.data
          console.log(data)
          if (data.status) {
            that.$toast({
              message: '添加成功',
              onClose: function () {
                // 判断一下是否从下单那边过来
                if (that.$store.state.addressIsOrder === 1) {
                  that.$router.replace('/addressList/order')
                } else {
                  that.$router.replace('/addressList')
                }
              }
            })
          }
        })
      },
      getAreaCode: function (province, city, county) {
        const provinceList = areaList.province_list
        const cityList = areaList.city_list
        const countyList = areaList.county_list
        let provinceNumber = ''
        let cityNumber = ''
        let countyNumber = ''
        for (const i in provinceList) {
          if (provinceList[i] === province) {
            provinceNumber = i.slice(0, 2)
          }
        }
        for (const i in cityList) {
          if (cityList[i] === city) {
            cityNumber = i.slice(2, 4)
          }
        }
        const areaCode4s = provinceNumber.toString() + cityNumber.toString()
        for (const i in countyList) {
          if (i.slice(0, 4) === areaCode4s) {
            if (countyList[i] === county) {
              countyNumber = i.slice(4, 6)
            }
          }
        }
        const areaCode6s = areaCode4s + countyNumber.toString()
        return areaCode6s
      }
    },
    mounted () {
      const id = this.$route.params.id
      if (id) {
        this.addressId = id
        this.init()
      } else {
        //  如果是新增地址，默认选中福建省 福州市
        this.addressInfo = {
          areaCode: '350102'
        }
      }
    }
  }
</script>

<style scoped>

</style>
