import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from './utils/axios'
import VueAxios from 'vue-axios'
import BaiduMap from 'vue-baidu-map'

import './assets/styles/global.css'
import './assets/responsive'
import './utils/vant'

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'fYQxGAGi3DVeTI5RG0AKYUVW2NDt3y87'
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// console.log('main -> 1111111111111')
