<template>
    <div></div>
</template>

<script>
  export default {
    name: 'TestComponents',
    beforeCreate () {
      console.log('子beforeCreate')
    },
    created () {
      console.log('子created')
    },
    beforeMount () {
      console.log('子beforeMount')
    },
    mounted () {
      console.log('子mounted')
    }
  }
</script>

<style scoped>

</style>
