<!--https://dafrok.github.io/vue-baidu-map/#/zh/start/usage-->
<template>
    <div>
        <baidu-map id="aaa" class='bm-view' :center="position" :zoom="15" @ready="onMapReady">
            <!--        <bm-control>-->
            <!--            <button>回商家页</button>-->
            <!--        </bm-control>-->
            <!--缩放控件-->
            <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
            <!--坐标提示-->
            <bm-marker :position="position" animation="BMAP_ANIMATION_BOUNCE" @click="infoWindowOpen"></bm-marker>
            <!--坐标上方显示商户名-->
            <!--        <bm-info-window :position="position" :title="name" :show="windowShow" @close="infoWindowClose">-->
            <!--            <p>{{name}}</p>-->
            <!--        </bm-info-window>-->
<!--            <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>-->
        </baidu-map>
        <float-nav></float-nav>
    </div>
</template>

<script>
  // import BMap from 'vue-baidu-map'
  // import Geolocation from 'vue-baidu-map'
  import FloatNav from '../components/FloatNav'
  export default {
    name: 'Map',
    components: { FloatNav },
    data () {
      return {
        // https://api.map.baidu.com/lbsapi/getpoint/index.html
        position: {
          // lng: 119.301008,
          // lat: 26.091747
        },
        windowShow: true,
        name: '这是一家婚策公司',
        BMap: '',
        map: ''
      }
    },
    methods: {
      // https://lequ7.com/guan-yu-vuejs-shi-yong-vuebaidumap-shi-zhao-bu-dao-bmap-dui-xiang-bao-cuo-bmapisnotdefind.html
      onMapReady ({ BMap, map }) {
        this.BMap = BMap
        this.map = map // new Map.Map()
        // this.getUserlocation()
      },
      // 获取用户当前地址
      getUserlocation () {
        const BMap = this.BMap
        const map = this.map
        const geolocation = new BMap.Geolocation()
        geolocation.getCurrentPosition(function (r) {
          const mark = new BMap.Marker(r.point)
          map.addOverlay(mark)
        })
      },
      infoWindowClose (e) {
        this.windowShow = false
      },
      infoWindowOpen (e) {
        this.windowShow = true
      }
    },
    created () {
        this.position = {
          lng: this.$route.params.lng,
          lat: this.$route.params.lat
        }
        console.log(this.position)
    }
  }
</script>

<style scoped>
    .bm-view {
        width: 100%;
        height: 40rem;
    }
</style>
