<!--填写评论 组件-->
<template>
    <van-action-sheet v-model="showPublish" title="评论" :round="false">
        <div>
                <textarea class="comment-textarea" maxlength="128" placeholder="请填写你的评论（小于128字）"
                          v-model="commentText"></textarea>
            <div class="comment-publish" @click="publishComment">发表评论</div>
        </div>
    </van-action-sheet>
</template>

<script>
  export default {
    name: 'CommentPublish',
    data () {
      return {
        showPublish: false,
        commentText: ''
      }
    },
    methods: {
      showPop: function () {
        this.showPublish = true
      },
      closePop: function () {
        this.showPublish = false
        this.commentText = ''
      },
      publishComment: function () {
        if (this.commentText) {
          this.$emit('publishComment', this.commentText)
        }
      }
    },
    mounted () {
      console.log('111111')
    }
  }
</script>

<style scoped>
    .comment-textarea {
        border: none;
        background-color: #ebebeb;
        padding: 1rem;
        width: 90%;
        height: 7rem;
        box-sizing: border-box;
        margin: 0 auto;
        display: block;
        border-radius: 0.2rem;
    }

    .comment-publish {
        width: 20rem;
        color: #ffffff;
        padding: 1rem 2rem;
        background-color: #F64C4C;
        margin: 0.7rem auto 1.2rem;
        text-align: center;
        border-radius: 0.2rem;
    }
</style>
