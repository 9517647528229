<template>
    <div>
        <!--聊天顶部操作栏-->
        <div class="chat-top-wrapper">
            <div></div>
            <div>{{title}}</div>
            <div></div>
        </div>
        <!--聊天内容-->
        <div style="width: 100%;height: 4.5rem;"></div>
        <div class="view-more" @click="viewMore" v-if="showMore">查看更多</div>
        <div class="chat-content-wrapper" id="chatContent">
            <chat-content v-for="item,key in messageList" :key="key"
                          :position="item.position"
                          :type="item.type"
                          :content="item.content"
                          :right-head="userHead"
                          :left-head="trademark"
            ></chat-content>
        </div>
        <div style="width: 100%;height: 5rem;"></div>
        <!--发送框-->
        <div class="send-wrapper" v-if="status == 1">
            <input class="send-input" type="text" v-model="inputText" placeholder="输入您想知道的问题" maxlength="128">
            <div class="send-button" @click="sendMessage">发送</div>
        </div>
        <div class="send-wrapper" v-else>
            <div class="close-text">对话已结束</div>
        </div>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import ChatContent from '../components/ChatContent'
  import { globalConfig } from '../utils/mixin'
  import FloatNav from '../components/FloatNav'

  export default {
    name: 'Chat',
    components: {
      FloatNav,
      ChatContent
    },
    mixins: [globalConfig],
    data () {
      return {
        title: '', // 商户名
        userHead: '', // 用户头像
        trademark: '', // 商家logo头像
        status: 1, // 对话是否关闭
        chatId: '',
        showMore: true,
        page: 1,
        inputText: '',
        commonProblem: [],
        messageList: []
      }
    },
    methods: {
      // 发送消息
      sendMessage: function () {
        if (this.inputText) {
          this.messageList.push({
            position: 'right',
            content: this.inputText,
            type: 'message'
          })
          this.axios.post(this.apiUrl + 'index/chat/sendMessage', {
            message: this.inputText,
            chatId: this.chatId
          }).then((response) => {
            console.log(response)
            this.scrollChat()
            this.inputText = ''
            const result = response.data
            if (result.status === 1) {
            } else {
              this.$toast(
                result.msg
              )
            }
          })
        }
      },
      // 聊天内容滚动到底部
      scrollChat: function () {
        const t = document.body.clientHeight + 100
        // console.log('滚动', t)
        window.scroll({
          top: t,
          left: 0,
          behavior: 'smooth'
        })
      },
      // 获取聊天信息
      getMessage: function (page) {
        this.axios.post(this.apiUrl + 'index/chat/getChatMessage', {
          chatId: this.chatId,
          page: page
        }).then((response) => {
          console.log(response.data)
          const result = response.data
          if (result.status === 1) {
            const list = result.data
            this.messageList = list.concat(this.messageList)
            this.page++
            // 如果没有更多消息，让查看更多的按钮隐藏
            if (list.length <= 7) {
              this.showMore = false
            }
          } else {
            this.$toast({
              message: result.msg,
              onClose: () => {
                this.$router.push('/user')
              }
            })
          }
        })
      },
      viewMore: function () {
        this.getMessage(this.page)
      },
      getChatInfo: function () {
        this.axios.post(this.apiUrl + 'index/chat/getChatInfo', {
          chatId: this.chatId
        }).then((response) => {
          const result = response.data
          console.log(result)
          this.title = result.data.businessName
          this.status = result.data.status
          this.userHead = result.data.headimgurl
          this.trademark = result.data.trademark
        })
      }
    },
    mounted () {
      this.chatId = this.$route.params.id
      this.getMessage(this.page)
      this.getChatInfo()
    }
  }
</script>

<style scoped>
    .chat-top-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        box-sizing: border-box;
        background-color: #ffffff;
        padding: 1rem 1rem;
        font-weight: 600;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        font-size: 1.1rem;
    }

    .chat-content-wrapper {
        /*margin: 3rem 0 0 0;*/
    }

    .send-wrapper {
        position: fixed;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 0.5rem 1rem;
        background-color: #ffffff;
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        box-sizing: border-box;
    }

    .send-input {
        width: 65%;
        border-radius: 10rem;
        border: none;
        background-color: #e5e5e5;
        font-size: 1rem;
        padding: 0.5rem 0 0.5rem 0.7rem;
    }

    .send-button {
        background-color: #F64C4C;
        color: #ffffff;
        padding: 0.5rem 2rem;
        border-radius: 10rem;
    }

    .view-more {
        text-align: center;
        font-size: 1rem;
        color: #888888;
    }

    .close-text {
        padding: 0.5rem 0;
        color: #888888;
        font-size: 1rem;
    }
</style>
