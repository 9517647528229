import Vue from 'vue'
import { Search, Swipe, SwipeItem, Tab, Tabs, DropdownMenu, DropdownItem, Stepper, ActionSheet, AddressEdit, List, NumberKeyboard, Divider, Overlay, Popup, Collapse, CollapseItem, Button, Calendar, CountDown, Icon, Image as VanImage, Uploader, Cell, CellGroup, Field, DatetimePicker, RadioGroup, Radio } from 'vant'

Vue.use(Search)
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Stepper)
Vue.use(ActionSheet)
Vue.use(AddressEdit)
Vue.use(List)
Vue.use(NumberKeyboard)
Vue.use(Divider)
Vue.use(Overlay)
Vue.use(Popup)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Button)
Vue.use(Calendar)
Vue.use(CountDown)
Vue.use(Icon)
Vue.use(VanImage)
Vue.use(Uploader)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(Field)
Vue.use(DatetimePicker)
Vue.use(RadioGroup)
Vue.use(Radio)
