<template>
    <div>
        <div class="price-wrapper">
            <span>￥</span>
            <span>{{moneyFen2Yuan(money)}}</span>
        </div>
        <div class="pay-wrapper">
            <!--纯正微信支付-->
            <div class="pay-item" :class="[currentItems == 'weixin' ? 'item-active' : '']" @click="select('weixin')" v-if="showWeiXin == 1 && showWeiXinApp == 0">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-微信.png">
                <p>微信支付</p>
            </div>
            <div class="pay-item" :class="[currentItems == 'weixinApp' ? 'item-active' : '']" @click="select('weixinApp')" v-if="showWeiXinApp == 1">
                <img src="https://seetop-1257860468.file.myqcloud.com/html/img/icon-微信.png">
                <p>微信小程序支付</p>
            </div>
        </div>
        <div style="width: 100%;height: 6rem;"></div>
        <div class="footer-wrapper">
            <div class="pay-button" @click="pay">确认支付</div>
        </div>
    </div>
</template>
<script>
  import { globalConfig, WXJsdk } from '../utils/mixin'
  import { moneyFen2Yuan } from '../utils/tools'
  import { getLocalStorage } from '../utils/localStorage'
  import wx from 'weixin-js-sdk'

  export default {
    name: 'Payment',
    mixins: [globalConfig, WXJsdk],
    data () {
      return {
        currentItems: '',
        money: 0,
        orderId: '',
        showWeiXin: 0,
        showWeiXinApp: 0,
        openid: ''
      }
    },
    methods: {
      moneyFen2Yuan,
      // 选择支付方式
      select: function (e) {
        this.currentItems = e
      },
      // 初始化，配置可选支付方式
      init: function () {
        const that = this
        this.axios.post(this.apiUrl + 'index/order/paymentInfo', {
          orderID: this.orderId
        }).then((response) => {
          const data = response.data
          if (data.status === 1) {
            that.showWeiXin = data.tools.weixin
            that.showCcbWX = data.tools.ccbwx
            that.showUnionPay = data.tools.unionpay
            that.showCcbPay = data.tools.ccbpay
            that.showByteDance = data.tools.bytedance
            that.money = data.info.total_fee
            that.openid = data.info.openid // 跳转小程序支付需要用到
            that.defaultItemActive()
          } else {
            this.$toast({
              message: data.msg,
              onClose: function () {
                that.$router.push('/home')
              }
            })
          }
        })
      },
      // 支付
      pay: function () {
        const that = this
        const srcChannel = getLocalStorage('srcChannel')
        // 微信小程序截断
        if (that.showWeiXinApp == 1) {
          that.wxAppPay(that.orderId, srcChannel)
          return
        }
        this.axios.post(this.apiUrl + 'index/order/pay', {
          orderID: that.orderId,
          type: that.currentItems,
          channel: srcChannel,
          src_channel: getLocalStorage('urlChannel')
        }).then((response) => {
          const data = response.data
          console.log(data)
          if (data.status === 1) {
            switch (that.currentItems) {
              case 'weixin':
                that.callPay(
                  data.res.jsApiParameters.appId,
                  data.res.jsApiParameters.timeStamp,
                  data.res.jsApiParameters.nonceStr,
                  data.res.jsApiParameters.package,
                  data.res.jsApiParameters.signType,
                  data.res.jsApiParameters.paySign,
                  that.webUrl + '#/user'
                )
                break
              default:
                break
            }
          } else {
            that.$toast(data.msg)
          }
        })
      },
      // 页面初始化默然选中的支付方式
      defaultItemActive: function () {
        if (this.showWeiXinApp === 1) {
          this.currentItems = 'weixinApp'
        } else if (this.showWeiXin === 1) {
          this.currentItems = 'weixin'
        }
      },
      wxAppPay: function (orderId, srcChannel) {
        const that = this
        wx.miniProgram.navigateTo({
          url: '/pages/payment/payment?orderID=' + orderId + '&channel=' + srcChannel + '&src_channel=' + srcChannel + '&openid=' + that.openid
        })
      }
    },
    mounted () {
      this.orderId = this.$route.params.orderId
      // 判断到小程序环境，支付的时候跳转去相应页面
      const that = this
      wx.miniProgram.getEnv(function (res) {
        if (res.miniprogram) {
          that.showWeiXinApp = 1
        }
      })
      this.init()
    }
  }
</script>

<style scoped>
    .price-wrapper {
        background-image: linear-gradient(to right, #333 , #444);
        padding: 3rem 0;
        text-align: center;
        color: #ffffff;
        border-bottom: 0.5rem solid #FFF177;
        margin-bottom: 1rem;
    }
    .price-wrapper > span:first-child {
        font-size: 1rem;
    }
    .price-wrapper > span:nth-child(2) {
        font-size: 3rem;
    }
    .pay-button {
        width: fit-content;
        color: #ffffff;
        margin: 1rem auto;
        background-image: linear-gradient(to right, #fd3b3b , #fc6446);
        box-shadow: 1px 2px 2px #cecece;
        padding: 1rem 6rem;
        border-radius: 10rem;
    }
    .footer-wrapper {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: #ffffff;
        box-shadow: 2px 0 6px #999;
    }
    .pay-item {
        width: 90%;
        height: 5rem;
        display: flex;
        flex-flow: row;
        align-items: center;
        box-shadow: 0 0 0.4rem #999999;
        border-radius: 0.5rem;
        background-color: #ffffff;
        padding: 0.5rem 1rem;
        margin: 0.8rem auto 0;
        box-sizing: border-box;
    }
    .pay-item img {
        /*width: 4rem;*/
        max-width: 4rem;
        max-height: 4rem;
        /*height: 4rem;*/
    }
    .pay-item p{
        margin-left: 2rem;
        font-size: 1.2rem;
    }
    .item-active {
        width: 94%;
        height: 5.5rem;
        border: 0.1rem solid #fa1f1f;
        box-shadow: 0 0 0.4rem #fa1f1f;
    }
</style>
