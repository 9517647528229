<template>
    <div>
        <!--banner-->
        <div class="swiper-container">
            <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide class="swiper-slide" v-for="item,key in banner" :key="key">
                    <img class="swipe-img" :src="item.img" @click="clickBanner(key)">
                </swiper-slide>
            </swiper>
        </div>
        <div class="class-wrapper" ref="class">
            <div class="class-container">
                <router-link class="class-item" to="/class/1">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城分类-婚纱礼服.png">
                    <div>婚纱礼服</div>
                </router-link>
                <router-link class="class-item" to="/class/2">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城分类-婚礼策划.png">
                    <div>婚礼策划</div>
                </router-link>
                <router-link class="class-item" to="/class/3">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城分类-婚纱摄影.png">
                    <div>婚纱摄影</div>
                </router-link>
                <router-link class="class-item" to="/class/9">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城分类-婚纱照.png">
                    <div>婚纱照</div>
                </router-link>
                <router-link class="class-item" to="/class/10">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城分类-婚礼喜娘.png">
                    <div>婚礼喜娘</div>
                </router-link>
                <router-link class="class-item" to="/class/11">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城分类-婚礼管家.png">
                    <div>婚礼管家</div>
                </router-link>
            </div>
            <div class="class-container">
                <router-link class="class-item" to="/class/5">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城分类-婚礼录像.png">
                    <div>婚礼录像</div>
                </router-link>
                <router-link class="class-item" to="/class/6">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城分类-婚礼主持.png">
                    <div>婚礼司仪</div>
                </router-link>
                <router-link class="class-item" to="/class/7">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城分类-婚礼跟妆.png">
                    <div>婚礼跟妆</div>
                </router-link>
                <router-link class="class-item" to="/class/8">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城分类-婚礼伴礼.png">
                    <div>婚礼伴礼</div>
                </router-link>
                <router-link class="class-item" to="/class/12">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城分类-婚礼酒店.png">
                    <div>婚礼酒店</div>
                </router-link>
                <router-link class="class-item" to="/class/13">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/商城分类-婚车租赁.png">
                    <div>婚车租赁</div>
                </router-link>
            </div>
        </div>
        <!--    class进度条    -->
        <div class="class-progress-wrapper">
            <div class="class-progress" :style="{ width: progress + 'px' }"></div>
        </div>
        <div class="ad-wrapper">
            <div>
                <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E5%95%86%E5%9F%8E%E9%A6%96%E9%A1%B5%E6%B5%8B%E8%AF%95%E5%B9%BF%E5%91%8A.jpg">
            </div>
            <div>
                <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E5%95%86%E5%9F%8E%E9%A6%96%E9%A1%B5%E6%B5%8B%E8%AF%95%E5%B9%BF%E5%91%8A.jpg">
            </div>
        </div>
        <div v-for="item,index in config" :key="index">
            <div class="title-container" v-if="item.type === 'product1'">
                <div class="title">{{item.name}}</div>
                <div class="more" @click="viewMore(item.url)" v-if="item.url">更多</div>
            </div>
            <div class="product-list-2" v-if="item.type === 'product1'">
                <product-item-middle
                        v-for="(item2, index2) in item.config" :key="index2"
                        :title="item2.name"
                        :price="item2.price"
                        :img="item2.thumbnail1"
                        :cellId="item2.product_cell_id"
                ></product-item-middle>
            </div>
        </div>
    </div>
</template>

<script>
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import ProductItemMiddle from '../components/ProductItemMiddle'
  import { globalConfig, listLoading } from '../utils/mixin'
  import { hrefUrlCommon } from '../utils/tools'

  export default {
    name: 'Shop',
    components: {
      ProductItemMiddle,
      swiper,
      swiperSlide
    },
    mixins: [globalConfig, listLoading],
    data () {
      return {
        banner: [
          {
            img: 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/banner1.png',
            url: ''
          },
          {
            img: 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/banner1.png',
            url: ''
          },
          {
            img: 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/banner1.png',
            url: ''
          }
        ],
        swiperOption: {
          initialSlide: 0,
          loop: true,
          slidesPerView: 1.1,
          centeredSlides: true,
          effect: 'coverflow',
          spaceBetween: -12,
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          }
        },
        config: [],
        progress: 0
      }
    },
    computed: {},
    methods: {
      loadData: function () {
        this.axios.post(this.apiUrl + 'index/product/getIndexProduct', {}).then((response) => {
          console.log(response.data)
          const data = response.data.data
          this.config = data
        })
      },
      viewMore: function (url) {
        this.hrefUrlCommon(window.location.href = url)
      },
      hrefUrlCommon,
      classScroll: function () {
        const minWidth = screen.availWidth
        const maxWidth = this.$refs.class.scrollWidth
        const _that = this
        _that.progress = (minWidth / maxWidth) * 50
        // class进度条
        this.$refs.class.addEventListener('scroll', function (e) {
          const scrollLeft = this.scrollLeft
          const percentage = (minWidth + scrollLeft) / maxWidth
          _that.progress = percentage * 50
          // console.log(percentage, progress)
        })
      }
    },
    mounted () {
      this.loadData()
      this.classScroll()
    }
  }
</script>

<style scoped>
    .swiper-container {
        width: 100%;
        margin-top: 0.3rem;
    }

    .swiper-slide {
        width: 100%;
        text-align: center;
        overflow: hidden;
        border-radius: 0.5rem;
    }

    .swipe-img {
        width: 100%;
        height: 200px;
    }

    .product-list-2 {
        padding: 1rem 1rem 0 1rem;
        width: 100%;
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;
        box-sizing: border-box;
    }

    .class-wrapper {
        overflow: scroll;
    }

    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }

    .class-container {
        display: flex;
        flex-flow: row;
        margin-top: 1rem;
    }

    .class-item {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        color: #333333;
        min-width: 25%;
    }

    .class-item img {
        width: 3rem;
    }

    .class-item div {
        font-size: 0.9rem;
        margin-top: 0.5rem;
    }

    .ad-wrapper {
        width: 91%;
        display: flex;
        flex-flow: row;
        margin: 1rem auto 1.1rem;
        justify-content: space-between;
    }

    .ad-wrapper > div {
        width: 48%;
    }

    .ad-wrapper img {
        width: 100%;
        border-radius: 0.5rem;
    }

    .title-container {
        width: 90%;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin: 0.5rem auto 0.3rem;
    }

    .title {
        font-size: 1.1rem;
        color: #000000;
        font-weight: 600;
    }

    .more {
        display: flex;
        align-items: center;
        color: #515151;
    }

    .more:after {
        width: 1rem;
        height: 1rem;
        content: "";
        background: url("https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E5%90%91%E5%8F%B3-%E9%BB%91.png") no-repeat;
        background-size: 100%;
        display: inline-block;
    }

    .swiper-pagination {
        width: 5rem;
        margin: 0 auto;
    }

    .class-progress-wrapper {
        height: 0.3rem;
        width: 50px;
        background-color: #C1C1C1;
        margin: 1rem auto 0;
    }

    .class-progress {
        height: 0.3rem;
        /*width: 80%;*/
        background-color: #F64C4C;
    }
</style>
