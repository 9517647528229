<template>
    <div>
        <van-collapse v-model="activeNames">
            <van-collapse-item title="一碗有囍是一个什么平台？" name="1">一碗有囍，一个婚庆产业特色平台，为您提供一站式婚庆产业消费体验。我们致力于为新人朋友和婚庆产业提供潮流、创新、便捷的交互服务。</van-collapse-item>
            <van-collapse-item title="我是商家，我如何入驻平台呢？" name="2">您可以通过个人中心，添加一碗有囍客服微信进行咨询了解。</van-collapse-item>
            <van-collapse-item title="我为什么进不去交友模块？" name="3">交友模块是一个线下交友专区，专区内信息不对外公开，您需要通过身份认证才可以进入，并且在专区请遵守准则否则可能被永久禁止封禁。</van-collapse-item>
            <van-collapse-item title="我咨询了但是一直没收到回复怎么办？" name="4">平台内部分咨询有可能直接转接商家，各个商家的活跃时间段有差别，请您关注平台信息和电话。</van-collapse-item>
            <van-collapse-item title="我购买的订单有疑问怎么办？" name="5">平台内所有的问题均可以在个人中心添加客服微信进行解决哦。</van-collapse-item>
        </van-collapse>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import FloatNav from '../components/FloatNav'
  export default {
    name: 'Question',
    components: { FloatNav },
    data () {
      return {
        activeNames: ['1']
      }
    }
  }
</script>

<style scoped>

</style>
