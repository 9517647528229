<!--
https://www.jianshu.com/p/03f9fa94c474 vue-awesome-swiper使用
https://github.surmon.me/vue-awesome-swiper/ Github使用实例点击<>
-->
<template>
    <div class="home">
        <search-input ref="searchInput" @onArticleSearch="onArticleSearch" @onClickLog="onArticleSearch"></search-input>
        <!--banner-->
        <div class="swiper-container">
            <swiper :options="swiperOption" ref="mySwiper">
                <swiper-slide class="swiper-slide" v-for="item,key in banner" :key="key">
                    <img class="swipe-img" :src="item.img" @click="clickBanner(key)">
                </swiper-slide>
                <!--        <div class="swiper-pagination" slot="pagination"></div>-->
            </swiper>
        </div>
        <!--tab标签-->
        <tabs style="padding: 0.3rem 0 0.5rem 0;" :class="{'fixTitle': isFixed}" :tabs="tabs" activeColor="#000000"
              v-on:click-tab="clickTab"></tabs>
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <waterfall ref="waterfall" :show-data="listLoadingList"></waterfall>
        </van-list>
        <!--发表-->
        <img class="publish"
             src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E5%8F%91%E8%A1%A8%E6%96%87%E7%AB%A0%E6%8C%89%E9%92%AE.png"
             @click="clickPublish"
        >
    </div>
</template>

<script>
  import { swiper, swiperSlide } from 'vue-awesome-swiper'
  import '../../node_modules/swiper/dist/css/swiper.css'
  import Tabs from '../components/Tabs'
  import { globalConfig, listLoading } from '../utils/mixin'
  import Waterfall from '../components/Waterfall'
  import SearchInput from '../components/SearchInput'

  export default {
    name: 'Home',
    components: {
      SearchInput,
      Waterfall,
      Tabs,
      swiper,
      swiperSlide
    },
    mixins: [globalConfig, listLoading],
    data () {
      return {
        isFixed: false,
        tabs: ['备婚攻略', '商家推荐', '婚纱摄影', '婚策案例'],
        banner: [
          {
            img: 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/20211109-banner2.png',
            url: ''
          },
          {
            img: 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/20211108-banner3.png',
            url: 'http://192.168.103.146:8080/#/article/32'
          },
          {
            img: 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/202101028-banner1.png',
            url: ''
          }
        ],
        swiperOption: {
          // pagination: {
          //   el: '.swiper-pagination'
          // },
          // paginationType: 'progress',
          initialSlide: 0,
          loop: true,
          slidesPerView: 1.1,
          centeredSlides: true,
          effect: 'coverflow',
          spaceBetween: -12,
          autoplay: {
            delay: 3000,
            stopOnLastSlide: false,
            disableOnInteraction: false
          }
        }
      }
    },
    methods: {
      clickTab: function (type) {
        this.$refs.waterfall.forceResetData()
        this.loadData(type)
      },
      clickBanner: function (index) {
        const obj = this.banner[index]
        const url = obj.url
        if (url) {
          window.location.href = url
        }
      },
      //  加载数据
      loadData: function (type) {
        this.listLoadingInit(this.apiUrl + 'index/article/getIndexList', 'list', 1, {
          type: type
        })
      },
      //  计算滑动
      handleScroll: function () {
        var scrollTop =
          window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        if (scrollTop > 210) {
          this.isFixed = true
        } else {
          this.isFixed = false
        }
      },
      //  发布
      clickPublish: function () {
        this.$toast('敬请期待')
      },
      onArticleSearch: function () {
        this.$router.push(`/articleSearch/${this.$refs.searchInput.getValue()}`)
      }
    },
    mounted () {
      this.loadData(this.tabs[0])
      window.addEventListener('scroll', this.handleScroll)
      console.log('Current Swiper instance object', this.swiper)
    },
    computed: {
      swiper () {
        return this.$refs.mySwiper.swiper
      }
    }
  }
</script>

<style scoped>
    .home {
        background-color: #F8F8F8;
    }

    .publish {
        width: 4rem;
        height: 4rem;
        position: fixed;
        right: 1rem;
        bottom: 5rem;
    }

    .fixTitle {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #ffffff;
    }

    .swipe-img {
        width: 100%;
        border-radius: 0.5rem;
    }

    .swiper-container {
        width: 100%;
        margin-top: 0.3rem;
    }

    .swiper-slide {
        width: 100%;
        text-align: center;
        overflow: hidden;
        border-radius: 0.5rem;
    }
</style>
