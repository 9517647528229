<template>
    <div class="box">
        <div class="index-top">
            <img v-if="business.trademark"  :src="'https://seetop-1257860468.file.myqcloud.com/panjiang/' + business.trademark">
            <div>{{business.business_name}}</div>
        </div>
        <div class="index-data">
            <div class="index-data1">
                <div>{{order.today_number}}</div>
                <div>今日销售</div>
            </div>
            <div class="index-data-line"></div>
            <div class="index-data1">
                <div>{{order.yesterday_number}}</div>
                <div>昨日销售</div>
            </div>
            <div class="index-data-line"></div>
            <div class="index-data1">
                <div>{{order.check_number}}</div>
                <div>核销订单</div>
            </div>
            <div class="index-data-line"></div>
            <div class="index-data1">
                <div>{{order.total_number}}</div>
                <div>全部订单</div>
            </div>
        </div>
        <div class="index_container">
            <div class="index_container1">
                <router-link class="index_content" to="/business/merchandise">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/icon2022010701.png">
                    <div>商品管理</div>
                </router-link>
                <router-link class="index_content" to="/business/orderList">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/icon2022010702.png">
                    <div>订单管理</div>
                </router-link>
                <router-link class="index_content" to="/business/data">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/icon2022010703.png">
                    <div>数据统计</div>
                </router-link>
                <div class="index_content"  @click="scan()">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/icon2022010704.png">
                    <div>扫码核销</div>
                </div>
            </div>
            <div class="index_container1">
                <router-link class="index_content" to="/business/chat">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/icon2022010705.png">
                    <div>客服服务</div>
                </router-link>
                <div class="index_content" ref="Confirm" @click="exit()">
                    <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/icon2022010706.png">
                    <div>退出登录</div>
                </div>
            </div>
        </div>

        <!--关注-->
        <div class="attention" v-if="isSubscribe=='0'">
            <div class="pop-contact">
                <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/yiwanyouxierweima0124.jpg">
                <p style="color: #856d44;font-size: 3vw">1、为了及时查收消息，请长按扫描关注公众号</p>
            </div>
        </div>
    </div>
</template>
<script>
    import { globalConfig } from '../../utils/mixin'
    import wx from 'weixin-js-sdk'
    export default {
        name: 'Index',
        mixins: [globalConfig],
        data () {
            return {
                business: [],
                order: [],
                isSubscribe: 0
            }
        },
        methods: {
            init: function () {
                this.axios.get(this.apiUrl + 'check/api/index').then((response) => {
                    console.log(response)
                    const data = response.data
                    if (data.status === 0) {
                        this.$router.replace('/business/login')
                    } else {
                        this.order = data.order
                        this.business = data.business
                       this.isSubscribe = data.isSubscribe
                    }
                })
            },
            exit: function () {
                const that = this
                that.axios.get(that.apiUrl + 'check/login/relieve').then((response) => {
                    console.log(response)
                    that.$router.replace('/business/login')
                })
            },
            scan: function () {
                wx.scanQRCode({
                    needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                    scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是一维码，默认二者都有
                    success: function (res) {
                        // var result = res.resultStr // 当needResult 为 1 时，扫码返回的结果
                    }
                })
            }
        },
        created () {
            this.init()
        },
        mounted () {
        },
        computed: {
        }
    }
</script>

<style scoped>
    *{
        box-sizing: border-box;
    }
    .attention{
        width: 100vw;
        height: 100vh;
        position: fixed;
        bottom: 0;
        background: rgba(0,0,0,0.5);
    }
    .pop-contact{
        width: 72vw;
        border-radius: 2vw;
        padding: 5vw 0;
        text-align: center;
        background: #fff;
        margin: 40vw 14vw 0 14vw;
    }
    .pop-contact img{
        width: 60vw;
        margin: 0 6vw;
    }
    .pop-contact p{
        font-size: 0.8rem;
        color: #856d44;
    }

    .box{
        width: 100vw;
        height: 100vh;
        background: url("https://seetop-1257860468.file.myqcloud.com/panjiang/img/banner2022010701.png") no-repeat #FEF9F6;
        background-size: 100%;
    }
    .index-top{
        display: flex;
        align-items: center;
        margin:0 0 3vw 5vw;
        padding-top: 8vw;
    }
    .index-top img{
        width: 20vw;
        border-radius: 20vw;
    }
    .index-top div{
        margin: 0 0 0 4vw;
        font-size: 4.2vw;
        color: #fff;
        font-weight: 600;
    }
    .index-data{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .index-data1 div{
        width: 20vw;
        text-align: center;
        color: #fff;
        font-size: 3vw;
        margin: 2vw;
    }
    .index-data1 div:nth-of-type(1){
        font-size: 5vw;
        font-weight: 600;
    }
    .index-data-line{
        width: 1px;
        height: 6vw;
        background: #fff;
    }
    .index_container{
        width: 88vw;
        padding: 3vw 0;
        margin: 4vw 6vw;
        border-radius: 5vw;
        box-shadow: 2px 2px 1px #efefef;
        background: #fff;
    }
    .index_container1{
        display: flex;
        align-items: center;
        margin: 3vw 4vw;
    }
    .index_content{
        width: 20vw;
    }
    .index_content img{
        width: 12vw;
        margin: 0 4vw;
    }
    .index_content div{
        font-size: 3vw;
        color: #333;
        text-align: center;
    }
</style>
