<template>
    <div>
        <!--banner-->
        <van-swipe :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="item,key in banner" :key="key">
                <img class="swipe-img" :src="item" @click="clickBanner(key)">
            </van-swipe-item>
        </van-swipe>
        <!--文章内容-->
        <div class="article-wrapper">
            <div class="title">{{title}}</div>
            <div class="content" v-html="content"></div>
            <div class="content-footer">
                <div class="article-time">{{createTime}}</div>
                <support-icon :like="articleLike" :has-like="hasLikeArticle" @click.native="clickLike"></support-icon>
            </div>
            <!--文中推荐-->
            <div class="article-recommend-item" v-for="item,key in adProduct" :key="key">
                <img class="recommend-img"
                     :src="'https://seetop-1257860468.file.myqcloud.com/panjiang/' + item.thumbnail1">
                <div class="recommend-info">
                    <div class="recommend-name-wrapper">
                        <span>文中推荐</span>
                        {{item.name}}
                    </div>
                    <div class="collect-icon-item">
                        <img class="collect-icon"
                             src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E6%96%87%E7%AB%A0%E8%AF%A6%E6%83%85-%E5%8A%A0%E5%85%A5%E6%94%B6%E8%97%8F%E7%82%B9%E4%BA%AE.png">
                        <div @click="viewAdProduct(item.product_cell_id)">查看详情</div>
                    </div>
                </div>
            </div>
        </div>
        <!--评论列表，需要支持点击加载更多-->
        <div class="comment-list" v-if="Object.keys(this.commentList).length > 0">
            <div class="comment-item" v-for="item,key in commentList" :key="key">
                <img class="user-head" :src="item.weixinInfo.headimgurl">
                <div class="comment-body">
                    <div class="user-name">{{item.weixinInfo.weixinNick}}</div>
                    <div class="comment-content">{{item.content}}</div>
                    <div class="content-footer">
                        <div class="article-time">{{item.create_time}}</div>
                        <support-icon :like="item.like" :has-like="item.hasLike"
                                      @click.native="clickCommentLike(item.id)"></support-icon>
                    </div>
                </div>
            </div>
            <div class="load-more" @click="loadMoreComment" v-if="commentLoadFinish == false">加载更多</div>
        </div>
        <div style="width: 100%;height: 5rem;"></div>
        <!--操作栏-->
        <div class="option-footer">
            <div class="comment-input" @click="clickPublish">来说点什么吧</div>
            <div class="option-right-wrapper">
                <icon-tag :img="collectIcon" name="收藏" @click.native="clickCollect"></icon-tag>
                <icon-tag :img="likeIcon" name="点赞" @click.native="clickLike"></icon-tag>
                <icon-tag
                        img="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%AF%A6%E6%83%85%E9%A1%B5-%E9%A6%96%E9%A1%B5.png"
                        name="首页" @click.native="toHome"></icon-tag>
            </div>
        </div>
        <!--弹出框评论-->
        <comment-publish ref="commentPublish" @publishComment="publishComment"></comment-publish>
    </div>
</template>

<script>
  import SupportIcon from '../components/SupportIcon'
  import IconTag from '../components/IconTag'
  import { globalConfig } from '../utils/mixin'
  import { ImagePreview } from 'vant'
  import CommentPublish from '../components/CommentPublish'

  export default {
    name: 'Article',
    components: {
      CommentPublish,
      IconTag,
      SupportIcon
    },
    mixins: [globalConfig],
    data () {
      return {
        articleId: 0,
        isBind: false,
        title: '',
        banner: [],
        content: '',
        createTime: '',
        articleLike: 0,
        hasLikeArticle: false,
        hasCollectArticle: false,
        commentList: [],
        currentCommentPage: 1,
        commentLoadFinish: false,
        adProduct: [],
        collectIcon: '',
        likeIcon: ''
      }
    },
    methods: {
      toHome: function () {
        this.$router.push('/home')
      },
      // 获取数据
      getData: function () {
        this.axios.post(this.apiUrl + 'index/article/article', {
          id: this.articleId
        }).then((response) => {
          const data = response.data
          if (data.status === 1) {
          } else {
            return
          }
          const article = data.data
          console.log(article)
          this.articleLike = article.like
          this.title = article.title
          this.content = article.content
          this.createTime = article.create_time
          this.banner = article.banner.map((value, index, array) => {
            return 'https://seetop-1257860468.file.myqcloud.com/panjiang/' + value
          })
          this.hasLikeArticle = article.hasLike
          this.hasCollectArticle = article.hasCollect
          this.updateCollectIcon(true)
          this.updateLikeIcon(true)
          this.isBind = article.isBind
          this.commentList = article.commentList
          this.adProduct = article.ad_product
          console.log(Object.keys(this.commentList))
          console.log(Object.keys(this.commentList).length)
        })
      },
      // 点赞文章
      clickLike: function () {
        //  避免重复点赞
        if (this.hasLikeArticle) {
          return
        }
        this.$store.commit('setIsShowLoading', false)
        this.axios.post(this.apiUrl + 'index/article/like', {
          id: this.articleId,
          type: '文章'
        }).then((response) => {
          // 点击的同时直接变颜色
          this.hasLikeArticle = true
          this.articleLike++
          this.updateLikeIcon(false)
        })
      },
      // 点击收藏
      clickCollect: function () {
        this.$store.commit('setIsShowLoading', false)
        this.axios.post(this.apiUrl + 'index/collect/collect', {
          type: 'article',
          follow: this.articleId
        }).then((response) => {
          console.log(response)
          this.hasCollectArticle = !this.hasCollectArticle
          this.updateCollectIcon(false)
        })
      },
      // 处理收藏的动效
      updateCollectIcon: function (isInit) {
        if (isInit) {
          if (this.hasCollectArticle) {
            this.collectIcon = 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E6%96%87%E7%AB%A0%E6%94%B6%E8%97%8F%E7%82%B9%E5%87%BB.png'
          } else {
            this.collectIcon = 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E6%94%B6%E8%97%8F%E6%9C%AA%E7%82%B9%E5%87%BB.png'
          }
        } else {
          if (this.hasCollectArticle) {
            this.collectIcon = 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E6%94%B6%E8%97%8F%E5%8A%A8%E6%95%88.gif'
          } else {
            this.collectIcon = 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E6%94%B6%E8%97%8F%E6%9C%AA%E7%82%B9%E5%87%BB.png'
          }
        }
      },
      // 处理点赞动效
      updateLikeIcon: function (isInit) {
        if (isInit) {
          if (this.hasLikeArticle) {
            this.likeIcon = 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%AF%A6%E6%83%85%E9%A1%B5%E7%82%B9%E8%B5%9E%E5%B7%B2%E7%82%B9%E5%87%BB.png'
          } else {
            this.likeIcon = 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%AF%A6%E6%83%85%E9%A1%B5%E7%82%B9%E8%B5%9E%E6%9C%AA%E7%82%B9%E5%87%BB.png'
          }
        } else {
          if (this.hasLikeArticle) {
            this.likeIcon = 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E7%82%B9%E8%B5%9E%E5%8A%A8%E6%95%88.gif'
          }
        }
      },
      // 点赞评论
      clickCommentLike: function (id) {
        if (this.commentList['ar' + id].hasLike) {
          return
        }
        this.axios.post(this.apiUrl + 'index/article/like', {
          id: id,
          type: '评论'
        }).then((response) => {
          // const data = response.data
          // this.$toast(data.msg)
          // 点击的同时直接变颜色
          this.commentList['ar' + id].hasLike = true
          this.commentList['ar' + id].like++
        })
      },
      // 评论文章
      publishComment: function (text) {
        this.axios.post(this.apiUrl + 'index/article/publishComment', {
          articleId: this.articleId,
          comment: text
        }).then((response) => {
          const data = response.data
          console.log(data)
          if (data.status === 1) {
            this.$toast({
              message: data.msg,
              onClose: function () {
                window.location.reload()
              }
            })
          } else {
            this.$toast(data.msg)
          }
        })
      },
      // 点击发布评论，弹出评论输入框
      clickPublish: function () {
        if (this.isBind) {
          this.$refs.commentPublish.showPop()
        } else {
          this.$toast({
            message: '请先前往绑定',
            icon: 'manager-o',
            onClose: () => {
              this.$router.push('/bind?url=' + window.location.href)
            }
          })
        }
      },
      // 点击banner查看大图
      clickBanner: function (index) {
        ImagePreview({
          images: this.banner,
          startPosition: index
        })
      },
      // 加载更多评论
      loadMoreComment: function () {
        this.axios.post(this.apiUrl + 'index/article/commentList', {
          articleId: this.articleId,
          page: this.currentCommentPage + 1
        }).then((response) => {
          const data = response.data
          const last = data.list
          if (Object.keys(last).length > 0) {
            this.commentList = { ...this.commentList, ...last }
            this.currentCommentPage++
          } else {
            this.commentLoadFinish = true
          }
        })
      },
      // 点击文中推荐前往商品详情
      viewAdProduct: function (id) {
        this.$router.push(`/product/${id}`)
      }
    },
    mounted () {
      this.articleId = this.$route.params.id
      this.getData()
    }
  }
</script>

<style scoped>
    .swipe-img {
        width: 100%;
    }

    .article-wrapper {
        padding: 1rem;
        background-color: #ffffff;
    }

    .title {
        font-weight: 600;
        font-size: 1.2rem;
        padding-bottom: 1rem;
        /*margin-bottom: 1rem;*/
    }

    .content {
        font-size: 1rem;
        color: #171717;
        margin-bottom: 1rem;
        line-height: 1.5rem;
        word-wrap: break-word;
    }

    .content >>> img {
        max-width: 100%;
    }

    .content-footer {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
    }

    .article-time {
        color: #999999;
    }

    .comment-list {
        display: flex;
        flex-flow: column;
        margin-top: 1rem;
    }

    .comment-item {
        background-color: #ffffff;
        padding: 1rem;
        border-bottom: 0.01rem solid #e6e6e6;
        display: flex;
        flex-flow: row;
        justify-content: space-around;
    }

    .user-head {
        width: 4rem;
        height: 4rem;
        border-radius: 10rem;
    }

    .comment-body {
        width: 75%;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    .user-name {
        font-weight: 600;
    }

    .comment-content {
        font-size: 0.9rem;
        color: #171717;
        margin: 1rem 0;
    }

    .option-footer {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        background-color: #ffffff;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        box-sizing: border-box;
        padding: 0 1rem;
    }

    .icon {
        width: 1.5rem;
        height: 1.5rem;
    }

    .option-right-wrapper {
        width: 40%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-around;
    }

    .icon-item {
        display: flex;
        flex-flow: column;
        align-items: center;
        font-size: 0.9rem;
        color: #666666;
        margin-left: 2rem;
    }

    .comment-input {
        width: 45%;
        color: #999999;
        background-color: #d9d9d9;
        border-radius: 10rem;
        padding: 0.5rem 1rem;
        text-align: center;
    }

    .comment-textarea {
        border: none;
        background-color: #ebebeb;
        padding: 1rem;
        width: 90%;
        height: 7rem;
        box-sizing: border-box;
        margin: 0 auto;
        display: block;
        border-radius: 0.2rem;
    }

    .comment-publish {
        width: 20rem;
        color: #ffffff;
        padding: 1rem 2rem;
        background-color: #F64C4C;
        margin: 0.7rem auto 1.2rem;
        text-align: center;
        border-radius: 0.2rem;
    }

    .article-recommend-item {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        align-items: center;
        background-color: #F8F8F8;
        margin: 0.5rem 0 0;
        box-sizing: border-box;
        padding: 0.5rem;
        width: 100%;
    }

    .recommend-img {
        width: 5rem;
        /*height: ;*/
    }

    .recommend-info {
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        /*padding: 0.3rem 0 0 0.5rem;*/
        width: 70%;
    }

    .recommend-name-wrapper {
        line-height: 1.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    .recommend-name-wrapper > span:first-child {
        background-color: #F64C4C;
        color: #ffffff;
        font-size: 0.9rem;
        padding: 0.2rem 0.7rem;
        border-radius: 10rem;
    }

    .collect-icon-item {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-end;
        color: #F64C4C;
        margin: 0.5rem 0.5rem 0 0;
    }

    .collect-icon {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.5rem;
    }

    .load-more {
        font-size: 0.9rem;
        color: #999999;
        text-align: center;
        margin: 1.2rem 0 0.2rem;
    }
</style>
