<template>
    <div class="body">
        <div>
            <div class="details-box">
                <div>商品名称：</div>
                <input type="text" v-model="name"/>
            </div>
            <div class="details-box">
                <div>原价(分)：</div>
                <input type="text" v-model="price"/>
            </div>
            <div class="details-box">
                <div>售价(分)：</div>
                <input type="text" v-model="mallprice"/>
            </div>
            <div class="details-box">
                <div style="font-size:2.8vw;color: #ca1416;width: 80vw;text-align: left">注意：价格单位为分</div>
            </div>
            <div class="details-box">
                <div>限购：</div>
                <input type="text" v-model="buylimit"/>
            </div>
            <div class="details-box">
                <div>库存：</div>
                <input type="text" v-model="stock"/>
            </div>
            <div class="details-box">
                <div>剩余库存：</div>
                <input type="text" v-model="redisStock" disabled/>
            </div>
            <div class="details-box">
                <div>上线时间：</div>
                <input type="datetime-local" v-model="online_start"/>
            </div>
            <div class="details-box">
                <div>下线时间：</div>
                <input type="datetime-local" v-model="online_end"/>
            </div>
            <div class="details-box">
                <div>有效开始时间：</div>
                <input type="datetime-local" v-model="valid_start"/>
            </div>
            <div class="details-box">
                <div>有效结束时间：</div>
                <input type="datetime-local" v-model="valid_end"/>
            </div>
            <div class="details-box">
                <div>备注：</div>
                <textarea name="remark"  id="remark" v-model="remark"></textarea>
            </div>
        </div>
        <div class="details-button">
            <div @click="tpBack()">返回</div>
            <div @click="editProduct()">
                确认修改
            </div>
        </div>
    </div>
</template>
<script>
  import { globalConfig } from '../../utils/mixin'
  export default {
    name: 'Login',
    mixins: [globalConfig],
    components: {},
    data () {
      return {
        id: '',
          name: '',
          mallprice: '',
          price: '',
          stock: '',
          redisStock: '',
          buylimit: '',
          remark: '',
          online_start: '',
          online_end: '',
          valid_start: '',
          valid_end: ''
      }
    },
    methods: {
      init: function () {
        this.axios.get(this.apiUrl + 'check/api/init').then((response) => {
            console.log(response)
            const data = response.data
            if (data.status === 0) {
                this.$router.replace('/business/login')
            }
        })
      },
        getBusinessInfo: function () {
        this.axios.post(this.apiUrl + 'check/business/getBusinessInfo', {
          id: this.id
        }).then((response) => {
          console.log(response)
          const data = response.data
          if (data.status === 1) {
              this.name = data.info.name
              this.mallprice = data.info.mallprice
              this.price = data.info.price
              this.stock = data.info.stock
              this.redisStock = data.info.redisStock
              this.buylimit = data.info.buylimit
              this.remark = data.info.remark
              this.online_start = data.info.online_start
              this.online_end = data.info.online_end
              this.valid_start = data.info.valid_start
              this.valid_end = data.info.valid_end
          } else {
              this.$toast(data.msg)
              this.$router.push('/business/warning/' + data.msg)
          }
        })
      },
        editProduct: function () {
            this.axios.post(this.apiUrl + 'check/business/editProductInfo', {
                id: this.id,
                name: this.name,
                mallprice: this.mallprice,
                price: this.price,
                stock: this.stock,
                buylimit: this.buylimit,
                remark: this.remark,
                online_start: this.online_start,
                online_end: this.online_end,
                valid_start: this.valid_start,
                valid_end: this.valid_end
            }).then((response) => {
                console.log(response)
                const data = response.data
                if (data.status === 1) {
                    this.$toast(data.msg)
                    window.location.reload()
                } else {
                    this.$toast(data.msg)
                }
            })
        },
        tpBack: function () {
            this.$router.push('/business/merchandise')
        }
    },
    created () {
        this.id = this.$route.params.id
        this.init()
        this.getBusinessInfo()
    },
    mounted () {
    },
    computed: {
    }
  }
</script>
<style scoped>
    .body{
        width: 100vw;
        background-color: #FEF9F6;
        padding: 3vw 0;
    }
    .details-box{
        width: 100vw;
        display: flex;
        align-items: center;
        justify-content: center;
        margin:0 0 3vw 0;
    }
    .details-box div{
        width: 24vw;
        color: #666;
        font-size: 3.2vw;
        text-align: center;
    }
    .details-box input{
        width: 64vw;
        height: 7.2vw;
        line-height: 7.2vw;
        border-radius:1vw;
        border: 1px solid #999;
        text-indent: 3vw;
        color: #666;
    }
    .details-box textarea{
        width: 64vw;
        height: 60vw;
        border-radius:1vw;
        border: 1px solid #999;
        text-indent: 3vw;
        color: #666;
        padding: 1vw 2vw;
        font-size: 2.8vw;
    }
    .details-button{
        width: 100vw;
        display: flex;
        align-items: center;
        padding:3vw 0 5vw 0;
        margin-left: 40vw;
    }
    .details-button div{
        width: 24vw;
        height: 9vw;
        line-height: 9vw;
        border-radius: 1vw;
        background:#ca1416;
        color: #fff;
        text-align: center;
        margin-left: 4vw;
    }
</style>
