<template>
    <div class="body">
        <img src="https://seetop-1257860468.file.myqcloud.com/html/img/banner2021122001.png" style="width: 100vw">
        <div class="right">
            <input type="text" class="input1" placeholder="请输入您账号" v-model="account" name="account"/>
        </div>
        <div class="right">
            <input type="password"  class="input2"  placeholder="请输入密码" v-model="password" name="password"/>
        </div>
        <div class="sure" @click="login()">登录</div>
    </div>
</template>
<script>
  import { globalConfig } from '../../utils/mixin'
  export default {
    name: 'Login',
    mixins: [globalConfig],
    components: {},
    data () {
      return {
        account: '',
        password: ''
      }
    },
    methods: {
      init: function () {
        this.axios.get(this.apiUrl + 'check/api/init').then((response) => {
          console.log(response)
          const data = response.data
          if (data.status === 1) {
            this.$router.replace('/business/index')
          } else {
            console.log('初始化成功')
          }
        })
      },
      login: function () {
        console.log(this.account)
        this.axios.post(this.apiUrl + 'check/login/login', {
          account: this.account,
          password: this.password
        }).then((response) => {
          console.log(response)
          const data = response.data
          if (data.status === 1) {
            this.$router.replace('/business/index')
          } else {
            this.$toast(data.msg)
          }
        })
      }
    },
    created () {
      this.init()
    },
    mounted () {
    },
    computed: {
    }
  }
</script>
<style scoped>
    .body{
        width: 100vw;
        height: 100vh;
        background-color: #FEF9F6;
    }
    .sure{
        display: block;
        width:60%;
        color: #ffffff;
        margin:2rem auto 0;
        background-color: #FA6565;
        height:3rem;
        line-height:3rem;
        border-radius:2vw;
        text-align: center;
        font-size: 1.2rem;
    }
    .right{
        width: 60%;
        margin:1.5rem auto 0;
    }
    .input1{
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/icon2021122008.png") 5vw center no-repeat;
        background-size: 4.5vw;
    }
    .input2{
        background: url("https://seetop-1257860468.file.myqcloud.com/html/img/icon2021122007.png") 5vw center no-repeat;
        background-size: 4.5vw;
    }
    .right input{
        background-color: #F6F6F6;
        border: 1px solid #ECE3E3;
        border-radius:2.8rem;
        height: 2.8rem;
        line-height:2.8rem;
        width: 100%;
        text-indent: 12vw;
        font-size: 1rem;
        outline:none;
        box-shadow:3px 2px 2px 0px #999;
    }
    input::placeholder{
        color: #999;
    }
</style>
