<template>
    <div>
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="moment-list">
                <div class="moment-item"  v-for="(item,index) in listLoadingList" :key="index" @click="toChatMessage(item.id)">
                    <img class="head" :src="item.headimgurl">
                    <div class="moment-right">
                        <div class="moment-top">
                            <div class="moment-name">
                                {{item.weixinNick}}
                                <span class="moment-status moment-chat" v-if="item.status == '1'">沟通中</span>
                                <span class="moment-status moment-over" v-else>已结束</span>
                            </div>
                            <div class="moment-time">{{item.time}}</div>
                        </div>
                        <div class="moment-top">
                            <div class="moment-content">{{item.content}}</div>
                            <div class="moment-view" v-if="item.is_view > 0">{{item.is_view}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-list>
        <float-nav-business></float-nav-business>
    </div>
</template>

<script>
    import { globalConfig, listLoading } from '../../utils/mixin'
    import FloatNavBusiness from '../../components/FloatNavBusiness'
  export default {
    name: 'Chat',
      mixins: [globalConfig, listLoading],
      components: { FloatNavBusiness },
    data () {
      return {
          list: [],
          loading: false
      }
    },
    methods: {
        init: function () {
            this.axios.get(this.apiUrl + 'check/api/init').then((response) => {
                console.log(response)
                const data = response.data
                if (data.status === 0) {
                    this.$router.replace('/business/login')
                }
            })
        },
        toChatMessage: function (e) {
            this.$router.push('/business/chatMessage/' + e)
        }
    },
    mounted () {
        this.init()
        this.listLoadingInit(this.apiUrl + 'check/chat/getChatList', 'list', 1, {

        })
    }
  }
</script>

<style scoped>
    .moment-list {
        display: flex;
        flex-flow: column;
    }
    .moment-item {
        background-color: #FFFFff;
        padding: 1rem 1rem;
        display: flex;
        align-items: center;
        flex-flow: row;
        justify-content: space-between;
        border-bottom: 0.01rem solid #dedede;
    }
    .head {
        width: 3.2rem;
        height: 3.2rem;
        border-radius: 10rem;
        border: 1px solid #efefef;
    }
    .moment-right {
        width: 82%;
    }
    .moment-top {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
    }
    .moment-name {
        font-weight: 800;
        font-size: 0.95rem;
        color: #333;
    }
    .moment-time {
        font-size: 0.95rem;
        color: #999;
    }
    .moment-content {
        width: 80%;
        margin: 0.8rem 0;
        color: #666666;
        letter-spacing: 0.05rem;
        font-size: 0.95rem;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
    }
    .moment-view{
        font-size: 2.8vw;
        color: #fff;
        background: #D05A56;
        height: 5vw;
        width: 5vw;
        line-height: 5vw;
        text-align: center;
        border-radius: 5vw;
    }
    .moment-status{
        font-weight: 400;
        font-size: 2.4vw;
        border-radius: 1vw;
        color: #fff;
        padding: 1vw 2vw;
        margin-left: 1vw;
    }
    .moment-over{
        background-color: #cecece;
    }
    .moment-chat{
        background-color: #D05A56;
    }
</style>
