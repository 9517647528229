<!--
点赞小组件
defaultImgc参数用来兼容不同的UI颜色
-->
<template>
    <div class="icon-wrapper">
        <img class="article-icon"
             v-if="hasLike"
             src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%AF%A6%E6%83%85%E9%A1%B5%E7%82%B9%E8%B5%9E%E5%B7%B2%E7%82%B9%E5%87%BB.png">
        <img class="article-icon"
             v-else-if="defaultImg"
             :src="defaultImg">
        <img class="article-icon"
             v-else
             src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%AF%A6%E6%83%85%E9%A1%B5%E7%82%B9%E8%B5%9E%E6%9C%AA%E7%82%B9%E5%87%BB.png">
        <div class="article-support-number">{{like}}</div>
    </div>
</template>

<script>
  export default {
    name: 'SupportIcon',
    props: ['like', 'hasLike', 'defaultImg'],
    data () {
      return {}
    },
    mounted () {
      // console.log(this.defaultImg)
    }
  }
</script>

<style scoped>
    .icon-wrapper {
        display: flex;
        flex-flow: row;
        align-items: flex-end;
    }
    .article-icon {
        width: 1.2rem;
        height: 1.2rem;
    }
    .article-support-number {
        margin-left: 0.3rem;
        font-size: 0.9rem;
        color: #999999;
    }
</style>
