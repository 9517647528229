<!--tab标签页组件-->
<template>
    <div class="tab-wrapper">
        <div
                v-for="item,key in tabs"
                :key="key"
                :class="['tab-item', key == currentIndex ? 'tab-active':'']"
                :style="{ color: currentTextColor(key)}"
                @click="clickTab(key)"
        >
            <div class="tab-name">{{item}}</div>
            <div :class="[key == currentIndex ? 'tab-footer':'']"></div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'Tabs',
    props: ['tabs', 'activeColor'],
    data () {
      return {
        currentIndex: 0
      }
    },
    computed: {},
    methods: {
      clickTab: function (index) {
        if (index === this.currentIndex) {
        } else {
          this.currentIndex = index
          this.$emit('click-tab', this.tabs[this.currentIndex])
        }
      },
      currentTextColor: function (key) {
        if (key === this.currentIndex && this.activeColor) {
          return this.activeColor
        } else {
          return '#999999'
        }
      }
    }
  }
</script>

<style scoped>
    .tab-wrapper {
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;
        overflow-x: scroll;
        z-index: 999;
    }

    .tab-item {
        font-size: 1.1rem;
        color: #999999;
        box-sizing: border-box;
        padding: 0.5rem 1rem;
    }

    .tab-name {
        white-space: nowrap;
    }

    .tab-active {
        font-weight: 600;
        color: #000000;
        font-size: 1.2rem;
    }

    .tab-footer {
        height: 0.3rem;
        width: 1.3rem;
        background-color: #F64C4C;
        margin: 0.2rem auto 0;
        border-radius: 1rem;
    }

    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
</style>
