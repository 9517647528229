<template>
    <div>2222
<!--        <div id="icon" class="icon"></div>-->
        {{obj.name}}
        <div @click="clickme">点我</div>
        <test-components></test-components>
    </div>
</template>

<script>
    // import Lottie from 'lottie-web'
    // import animationData from '../assets/icon/岗位取消.json'
  import TestComponents from '../components/TestComponents'
    export default {
    name: 'Test',
      components: { TestComponents },
      data () {
      return {
        obj: {
          name: 'wjy'
        }
      }
    },
    methods: {
      clickme () {
        this.obj.name = '..'
      }
    },
    beforeCreate () {
      console.log('主beforeCreate')
    },
    created () {
      console.log('主created')
    },
    beforeMount () {
      console.log('主beforeMount')
    },
    mounted () {
      console.log('主mounted')
    }
  }
</script>

<style scoped>

</style>
