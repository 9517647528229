<!--把规格列表传入进来，这边专注处理购买商品栏-->
<template>
    <div>
        <div style="width: 100%;height: 5rem;"></div>
        <share-pop ref="share"></share-pop>
        <!--        <customer-service ref="service"></customer-service>-->
        <subscribe ref="subscribe"></subscribe>
        <!--购买栏-->
        <div class="product-bar-wrapper">
            <!--当前规格没有在卖的，并且最少有一个待开售，则显示-->
            <div class="product-count-down" v-if="subSaleArr.length == 0 && subRemindArr.length >0">
                距离开始还有：
                <van-count-down :time="countDown" format="DD 天 HH 时 mm 分 ss 秒" class="vant-count-down"/>
            </div>
            <div class="product-bar">
                <div class="product-convenient-wrapper">
                    <icon-tag
                            img="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%AF%A6%E6%83%85%E9%A1%B5-%E9%A6%96%E9%A1%B5.png"
                            name="首页" @click.native="toHome"></icon-tag>
                    <icon-tag
                            img="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%AF%A6%E6%83%85%E9%A1%B5-%E5%88%86%E4%BA%AB.png"
                            name="分享" @click.native="viewShare"></icon-tag>
                    <icon-tag
                            img="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E8%AF%A6%E6%83%85%E9%A1%B5-%E5%AE%A2%E6%9C%8D.png"
                            name="客服" @click.native="customerService"></icon-tag>
                </div>
                <div class="product-button-wrapper">
                    <div class="product-button-large" style="background-color: rgb(246,189,76)"
                         @click="viewSpecsPanel('addCart')" v-show="express === 1">加入购物车
                    </div>
                    <!--如果规格里面有在卖的，那么现实立即购买-->
                    <div class="product-button-large" v-if="subSaleArr.length > 0" @click="viewSpecsPanel('order')">
                        立即购买
                    </div>
                    <!--判断是否有待开售的，如果有就显示-->
                    <div class="product-button-large" style="background-color: #3cc51f;"
                         v-else-if="subRemindArr.length >0" @click="setRemind">预约提醒
                    </div>
                    <!--规格全部都已结束才显示已结束-->
                    <div class="product-button-large" style="background-color: #9c9c9c;"
                         v-else-if="subFinishArr.length == specsCount">已结束
                    </div>
                    <!--规格全部都已售罄才显示已售罄-->
                    <div class="product-button-large" style="background-color: #9c9c9c;"
                         v-else-if="subSellOutArr.length == specsCount && specsCount != 0">售罄
                    </div>
                    <!--容错，理论上就三种状态：有在售商品，还没上线，已结束-->
                    <div class="product-button-large" style="background-color: #9c9c9c;" v-else>已结束</div>
                </div>
            </div>
        </div>
        <!--选购栏-->
        <van-action-sheet v-model="showSpecsPanel">
            <div class="content">
                <div class="specs-name">{{productName}}</div>
                <!--多规格才出现，辅助选择规格的提示-->
                <div v-if="specsCount > 1">
                    <div class="specs-product-tip" v-if="currentSelectProductId == ''">您还未选择商品</div>
                    <div class="specs-product-tip" v-else>已选：{{currentSelectProductSkuText}}</div>
                </div>
                <div class="specs-price-wrapper" v-if="currentSelectProductId">
                    <div>￥{{selectAllProductPrice}}</div>
                    <div>￥{{selectAllProductOrgPrice}}</div>
                </div>
                <div class="sku-wrapper" v-if="specsCount > 1">
                    <div class="sku-item-wrapper" v-for="item,key in productSkuSourceMap" :key="key">
                        <div class="sku-name">{{item[0]}}</div>
                        <div class="sku-box">
                            <span v-for="item2,key2 in item[1]" :key="key2" @click="clickSku(key,item2)"
                                  :class="[item2 == currentSelectSkuArr.get(key) ? 'sku-item-active' : '', judgeSku(key,item2) ? '' : 'sku-default']"
                                  :ref="`${key}_${item2}`">{{item2}}</span>
                        </div>
                    </div>
                </div>
                <div class="specs-number-wrapper">
                    <div>数量</div>
                    <div>
                        <van-stepper v-model="productNumber" integer disable-input :max="limitBuyNumber"/>
                    </div>
                </div>
                <div class="specs-button" @click="submitOrder">确定</div>
            </div>
        </van-action-sheet>
    </div>
</template>

<script>
  import SharePop from '../components/SharePop'
  import { getCurrentTimestamp, moneyFen2Yuan, standardTime2Timestamp, standardTimeIsOver } from '../utils/tools'
  import { globalConfig } from '../utils/mixin'
  import { appendShopCart, buyNow } from '../utils/cart'
  import IconTag from './IconTag'
  import Subscribe from './Subscribe'
  // import SellOutGuide from './SellOutGuide'
  export default {
    name: 'BuyProductBarV2',
    components: {
      Subscribe,
      IconTag,
      SharePop
    },
    props: ['subProduct', 'productSku', 'apiTimestamp', 'productName', 'cellId', 'isBind', 'businessId', 'productImg', 'express', 'isSubscribe'],
    mixins: [globalConfig],
    data () {
      return {
        // 规格总数，计算了所有status不等于0的规格，最少都是1个
        specsCount: 0,
        // 规格里 还没开售的
        subRemindArr: [],
        // 规格里 正在卖的
        subSaleArr: [],
        // 规格里 已结束的
        subFinishArr: [],
        // 规格里 已经售罄的
        subSellOutArr: [],
        // 规格里 没有上线的
        subBePending: [],
        // 规格面板
        showSpecsPanel: false,
        // 当sku都选择确定以后的商品属性
        currentSelectProductId: '',
        currentSelectProductSkuText: '',
        currentSelectProductPrice: 0,
        currentSelectProductOrgPrice: 0,
        // 购买个数
        productNumber: 0,
        // 限制购买数量，默认是9，实际要选规格的时候根据后台设置的限购来
        limitBuyNumber: 9,
        intention: 'order', // 意图，是想加入购物车还是购买，默认是购买
        productSkuSourceMap: new Map(), // 还没有加入空值补齐的Sku数组
        subProductMap: new Map(), // props传入的数组处理
        currentSelectSkuArr: new Map(),
        allSukComboMap: new Map()
      }
    },
    methods: {
      standardTime2Timestamp,
      // 分享遮罩
      viewShare: function () {
        this.$refs.share.showPop()
      },
      // 跳转首页
      toHome: function () {
        this.$router.push('/home')
      },
      // 客服聊天
      customerService: function () {
        if (this.isBind === 1) {
          if (this.isSubscribe === 1) {
            this.$router.push('/launchChat/' + this.businessId)
          } else {
            this.$refs.subscribe.showSubscribe()
          }
        } else {
          this.$toast({
            message: '请先前往绑定',
            icon: 'manager-o',
            onClose: () => {
              this.$router.push('/bind?url=' + window.location.href)
            }
          })
        }
      },
      // 规格面板
      viewSpecsPanel: function (intention) {
        const that = this
        if (that.isBind === 1) {
        } else {
          this.$toast({
            message: '请先前往绑定',
            icon: 'manager-o',
            onClose: function () {
              that.$router.push('/bind?url=' + window.location.href)
            }
          })
          return
        }
        this.showSpecsPanel = true
        this.intention = intention
      },
      // // 设置开售提醒
      setRemind: function () {
        const that = this
        if (that.isBind === 1) {
        } else {
          this.$toast({
            message: '请先前往绑定',
            onClose: function () {
              that.$router.push('/bind?url=' + window.location.href)
            }
          })
          return
        }
        this.axios.post(this.apiUrl + 'index/remind/remind', {
          type: 'product',
          gid: this.cellId,
          url: window.location.href
        }).then((response) => {
          const data = response.data
          that.$toast(data.msg)
        })
      },
      // 选好规格和数量，提交
      submitOrder: function () {
        if (this.currentSelectProductId) {
          if (this.intention === 'order') {
            buyNow(this.businessId, this.currentSelectProductId, this.productNumber)
            this.$router.push('/payOrder')
          } else {
            // 加入购物车
            appendShopCart(
              this.businessId,
              this.currentSelectProductId,
              this.productNumber, this.productName,
              this.currentSelectProductPrice,
              this.currentSelectProductOrgPrice,
              this.currentSelectProductSkuText,
              this.productImg,
              this.limitBuyNumber
            )
            this.$dialog.confirm({
              title: '提示',
              message: '添加成功，前往购物车'
            }).then(() => {
              this.$router.push('/cart')
            })
          }
        } else {
          this.$toast('请先选择商品和规格')
        }
      },
      // 点击规格，V 2.0
      clickSku: function (key, sku) {
        const _ref = `${key}_${sku}`
        const target = this.$refs[_ref]
        // console.log(target, target[0].className)
        if (target[0].className === 'sku-default') {
          return
        }
        if (this.currentSelectSkuArr.get(key) === sku) {
          this.currentSelectSkuArr.set(key, '')
        } else {
          this.currentSelectSkuArr.set(key, sku)
        }
        console.log(this.currentSelectSkuArr)
        // 所有sku都选择
        let selectFinish = 1
        this.currentSelectSkuArr.forEach((val) => {
          if (val === '') {
            selectFinish = 0
            return 0
          }
        })
        // 如果选择已经做完
        if (selectFinish === 1) {
          // sku都选择了
          const key = this.map2key(this.currentSelectSkuArr)
          const product = this.subProductMap.get(key)
          console.log(product)
          this.currentSelectProductId = product.id
          this.currentSelectProductSkuText = key.replaceAll('|', ' ')
          this.currentSelectProductPrice = product.mallprice
          this.currentSelectProductOrgPrice = product.price
          this.limitBuyNumber = product.buylimit
        } else {
          this.currentSelectProductId = ''
          this.currentSelectProductSkuText = ''
          this.currentSelectProductPrice = 0
          this.currentSelectProductOrgPrice = 0
          this.limitBuyNumber = 9
        }
        this.$forceUpdate()
      },
      // 传入一个sku 预判是否可以选择  算法：用当前sku和其他sku组合，查看是否有值
      judgeSku: function (index, sku) {
        const judgeMap = new Map()
        this.currentSelectSkuArr.forEach((val, index) => {
          judgeMap.set(index, val)
        })
        judgeMap.set(index, sku)
        const judgeKey = this.map2key(judgeMap)
        const s = this.allSukComboMap.get(judgeKey)
        if (s.size > 0) {
          return true
        } else {
          return false
        }
      },
      // 笛卡尔积
      descartes: function (args) {
        if (args.length < 2) {
          return args[0] || []
        }
        return [].reduce.call(args, (col, set) => {
          const res = []
          col.forEach(c => {
            set.forEach(s => {
              const t = [].concat(Array.isArray(c) ? c : [c])
              t.push(s)
              res.push(t)
            })
          })
          return res
        })
      },
      // 数据初始化
      dataInit: function () {
        this.initSubProduct()
        const dikaer = this.initSpecDikaer()
        // 几个参数的初始化
        this.productSkuSourceMap = new Map(Object.entries(this.productSku))
        const skuNumber = this.productSkuSourceMap.size // 一共有多少个sku属性
        for (let i = 0; i < skuNumber; i++) {
          this.currentSelectSkuArr.set(i, '')
        }
        const allSukComboMap = new Map()
        dikaer.forEach((val) => {
          const sukComboMap = new Map() // 满足条件且有库存
          const currentMapKey = this.arr2key(val)
          // 优化，对于key而言，如果不存在空值'' 是所有sku属性都有的话 就没有循环的必要
          if (val.indexOf('') === -1) {
            this.createSukComboMap(sukComboMap, val)
            allSukComboMap.set(currentMapKey, sukComboMap)
            return
          }
          dikaer.forEach((val2) => {
            // 过滤掉模糊sku，例如||电信  |128G|移动，而白色|64G|联通可以统计
            if (val2.indexOf('') === -1) {
            } else {
              return
            }
            // 依次比对两个循环的sku属性，这个循环会判断return结束当前循环，所以能通过这个for循环的其实都是满足条件的
            for (let i = 0; i < skuNumber; i++) {
              if (val[i] === '') { // 目标循环为空，包罗万象
              } else { // 不为空，一定要目标循环和当前循环相同才可以通过
                if (val[i] === val2[i]) {
                } else {
                  // 只要有一个值对不上，本次循环即作废，不纳入库存统计
                  return
                }
              }
            }
            this.createSukComboMap(sukComboMap, val2)
          })
          allSukComboMap.set(currentMapKey, sukComboMap)
        })
        console.log('最终结果', allSukComboMap)
        this.allSukComboMap = allSukComboMap
        this.$forceUpdate()
      },
      // 利用了引用类型的特性 把sku数组依次push加入对应key的combomap中， key => combomap
      createSukComboMap: function (sukComboMap, arr) {
        // console.log('createSukComboMap', sukComboMap, arr)
        const key = this.arr2key(arr)
        let currentStock = 0
        // 在这里加一个判断，subProductMap必须存在才进行
        // 因为售罄、未上线、已下线的可能不录入subProductMap，如果去获取status属性会报错
        if (this.subProductMap.get(key)) {
          currentStock = this.subProductMap.get(key).stock
        }
        if (currentStock > 0) { // 当前这个sku既满足条件并且有库存
          arr.forEach((val3, index3) => {
            const aldArr = sukComboMap.get(index3)
            let singleSukCombo = []
            if (aldArr) {
              singleSukCombo = aldArr
            }
            singleSukCombo.push(val3)
            sukComboMap.set(index3, singleSukCombo)
          })
        }
      },
      // 数组转key ，例如 [白色,64G,''] 转成 白色|64G||
      arr2key: function (arr) {
        let key = ''
        for (const value of arr) {
          key = value + '|' + key // 白色|64G|联通
        }
        return key
      },
      // map转key
      map2key: function (map) {
        let key = ''
        map.forEach((item) => {
          key = item + '|' + key
        })
        return key
      },
      // 主要功能SubProduct从对象转成Map  key = 白色|64G|移动，value为原数据的Map对象
      // 和initProductArr函数类似，都做了循环
      initSubProduct: function () {
        const subProductMap = new Map()
        console.log(this.subProduct)
        this.subProduct.forEach((val) => {
          // 还没上线
          if (standardTimeIsOver(val.online_start)) {
          } else {
            return
          }
          // 已经下线
          if (standardTimeIsOver(val.online_end)) {
            return
          }
          // 如果还没上线
          if (val.status === 0) {
            return
          }
          let currentSkuString = ''
          for (const key in val.specs) {
            currentSkuString = val.specs[key] + '|' + currentSkuString
          }
          subProductMap.set(currentSkuString, val)
        })
        this.subProductMap = subProductMap
      },
      // 初始化商品数组，设置好各个数组
      // 和initSubProduct函数类似，都做了循环
      initProductArr: function () {
        for (const i in this.subProduct) {
          // console.log(this.subProduct[i])
          // 获取参数
          const mOnlineStart = this.subProduct[i].online_start
          const mOnlineEnd = this.subProduct[i].online_end
          const mStatus = this.subProduct[i].status
          const mLastStock = this.subProduct[i].lastStock
          // 至关重要的分类，对所有规格商品进行分类，判断其状态
          // status和laststock判断优先级最高，接下去才是判断时间
          if (mStatus === 0) {
            // 没上线，判断优先级最高
            this.subBePending.push(this.subProduct[i])
            // console.log('mark1')
          } else if (mLastStock <= 0) {
            // 售罄，优先级第二
            this.subSellOutArr.push(this.subProduct[i])
            this.specsCount++
            // console.log('mark2')
          } else if (this.currentTimestamp < this.standardTime2Timestamp(mOnlineStart)) {
            // 即将上线
            this.subRemindArr.push(this.subProduct[i])
            this.specsCount++
            // console.log('mark3')
          } else if (this.currentTimestamp > this.standardTime2Timestamp(mOnlineEnd)) {
            // 已结束
            this.subFinishArr.push(this.subProduct[i])
            this.specsCount++
            // console.log('mark4')
          } else {
            // 正在卖
            this.subSaleArr.push(this.subProduct[i])
            this.specsCount++
            // console.log('mark5')
          }
        }
        // console.log('subBePending', this.subBePending)
        // console.log('subSellOutArr', this.subSellOutArr)
        // console.log('subRemindArr', this.subRemindArr)
        // console.log('subFinishArr', this.subFinishArr)
        // console.log('subSaleArr', this.subSaleArr)
      },
      initSpecDikaer: function () {
        var arr = []
        const descartesArr = []
        const productSkuMap = new Map(Object.entries(this.productSku))
        console.log('mark2', productSkuMap)
        // 如果是单规格商品，执行下面的代码
        if (productSkuMap.size === 1) {
          const b = productSkuMap.get(Object.keys(this.productSku)[0])
          b.forEach((val) => {
            const c = []
            c.push(val)
            descartesArr.push(c)
          })
          descartesArr.push([''])
          console.log('补充空值以后111', descartesArr)
          return descartesArr
        }
        productSkuMap.forEach((val) => {
          arr = [...val]
          arr.push('')
          descartesArr.push(arr)
        })
        const dikaer = this.descartes(descartesArr)
        console.log('补充空值以后222', dikaer)
        return dikaer
      }
    },
    computed: {
      // 开售倒计时，实际上是第一个商品的倒计时
      countDown: function () {
        const startTimestamp = standardTime2Timestamp(this.subRemindArr[0].online_start)
        return (startTimestamp - this.apiTimestamp) * 1000
      },
      currentTimestamp: function () {
        return getCurrentTimestamp()
      },
      // 计算商品价格 单品价 * 选择的数量
      selectAllProductPrice: function () {
        const price = moneyFen2Yuan(this.productNumber * this.currentSelectProductPrice)
        return price
      },
      // 计算商品原价格 单品原价 * 选择的数量
      selectAllProductOrgPrice: function () {
        const price = moneyFen2Yuan(this.productNumber * this.currentSelectProductOrgPrice)
        return price
      }
    },
    mounted () {
      this.initProductArr()
      // 如果是单商品，也就是specsCount为1的时候，用户是不需要去选择规格的，直接帮他选择【第一个】
      if (this.specsCount === 1) {
        // console.log(this.subSaleArr)
        this.currentSelectProductId = this.subSaleArr.length ? this.subSaleArr[0].id : 0
        this.limitBuyNumber = this.subSaleArr.length ? this.subSaleArr[0].buylimit : 9
        this.currentSelectProductPrice = this.subSaleArr.length ? this.subSaleArr[0].mallprice : 0
        this.currentSelectProductOrgPrice = this.subSaleArr.length ? this.subSaleArr[0].price : 0
      }
      this.dataInit()
    }
  }
</script>

<style scoped>
    .product-bar-wrapper {
        box-sizing: border-box;
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        border-top: 0.1rem solid #e6e6e6;
        background-color: #ffffff;
    }

    .product-bar {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
    }

    .product-count-down {
        background-color: #eab867;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        padding: 0.1rem 0;
        color: #ffffff;
    }

    .vant-count-down {
        color: #ffffff;
    }

    .product-convenient-wrapper {
        width: 40%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-around;
    }

    .product-convenient-item {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        padding-top: 0.1rem;
    }

    .product-convenient-item img {
        width: 1.4rem;
    }

    .product-convenient-item p {
        text-align: center;
        font-size: 0.8rem;
        margin-top: 0.2rem;
        color: #7c7c7c;
    }

    .product-button-wrapper {
        display: flex;
        flex-flow: row;
        align-items: center;
        width: 60%;
    }

    .product-button-large {
        background-color: rgb(246, 76, 76);
        color: #ffffff;
        text-align: center;
        /*padding:;*/
        font-size: 1.1rem;
        font-weight: 600;
        height: 4rem;
        line-height: 4rem;
        width: 100%;
    }

    .product-button-remin {
        border-radius: 10rem;
        background-color: #3cc51f;
        color: #ffffff;
        text-align: center;
        padding: 0.5rem 3.5rem;
        font-size: 1rem;
    }

    .specs-name {
        font-size: 1.1rem;
        font-weight: 600;
        margin-top: 1rem;
        padding: 0 1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
    }

    .specs-product-tip {
        font-size: 0.9rem;
        color: #7c7c7c;
        padding: 1rem;
    }

    .specs-price-wrapper {
        display: flex;
        flex-flow: row;
        align-items: flex-end;
        padding: 0 1rem 1rem;
    }

    .specs-price-wrapper > div:first-child {
        font-size: 1.1rem;
        color: #fa1f1f;
    }

    .specs-price-wrapper > div:nth-child(2) {
        font-size: 0.9rem;
        color: #7c7c7c;
        text-decoration: line-through;
        margin-left: 0.5rem;
    }

    .specs-number-wrapper {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 1rem 1rem;
    }

    .specs-button {
        color: #ffffff;
        background-color: #ff3a35;
        text-align: center;
        padding: 0.8rem 0;
        font-size: 1.2rem;
    }

    .sku-wrapper {
        display: flex;
        flex-flow: column;
        width: 93%;
        margin: 0 auto;
    }

    .sku-item-wrapper {
        margin-bottom: 1rem;
    }

    .sku-name {
        color: #333333;
    }

    .sku-box {
        display: flex;
        flex-flow: wrap;
    }

    .sku-box span {
        margin: 0.5rem 1rem 0 0;
        background-color: #e5e5e5;
        color: #333333;
        padding: 0.5rem 1rem;
        font-size: 0.9rem;
        box-sizing: border-box;
    }

    .sku-item-active {
        background-color: #ffc9c9 !important;
        color: #ee0a24 !important;
    }

    .sku-default {
        text-decoration: line-through;
    }
</style>
