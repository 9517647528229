<template>
    <div>
        <div class="info-box">
            <div style="border: 0.1rem solid #bcbcbc">
                <div class="check_title">
                    <p>———— 订单信息 ————</p>
                </div>
                <div class="line"></div>
                <div v-if="id">
                    <div class="check_information">
                        <p>id：</p>
                        <p>{{id}}</p>
                    </div>
                    <div class="line"></div>
                </div>
                <div class="check_information">
                    <p>订单号：</p>
                    <p>{{order.orderID}}</p>
                </div>
                <div class="line"></div>
                <div class="check_information">
                    <p>套餐名：</p>
                    <p>{{order.gooods_name}}</p>
                </div>
                <div class="line"></div>
                <div class="check_information">
                    <p>核销码：</p>
                    <p>{{order.randomcode}}</p>
                </div>
                <div class="line"></div>
                <div class="check_information">
                    <p>核销状态：</p>
                    <p class="check_information1">{{orderStatusStr(2,order.order_status)}}</p>
                </div>
                <div class="line"></div>
                <div class="check_information">
                    <p>商户名：</p>
                    <p>{{order.business_name}}</p>
                </div>
                <div>
                    <div class="line"></div>
                    <div class="check_information">
                        <p>购买时间：</p>
                        <p style="color: #C20B09">{{order.create_time}}</p>
                    </div>
                </div>
                <div class="line"></div>
                <div class="check_information">
                    <p>有效期至：</p>
                    <p style="color: #C20B09">{{order.valid_end}}</p>
                </div>
                <div>
                    <div class="line"></div>
                    <div class="check_information">
                        <p>支付金额：</p>
                        <p id="money">{{moneyFen2Yuan(order.total_fee)}}</p>
                    </div>
                </div>
                <div class="line"></div>
                <div class="check_information" v-if="order['order_type'] == 'package'">
                    <p>核销数量</p>
                    <div>
                        <div class="check_information_number">
                            <p @click="reduce">-</p>
                            {{amount}}
                            <p @click="add" style="width: 5vw;text-align: center">+</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="btn-box">
            <router-link class="container-div p_key" to="/check/index">返回主页</router-link>
            <p class="p_key" @click="check">确认核销</p>
        </div>
    </div>
</template>

<script>
    import { moneyFen2Yuan, orderStatusStr } from '../../utils/tools'
    import { globalConfig } from '../../utils/mixin'
    export default {
        name: 'Check',
        mixins: [globalConfig],
        components: {},
        data () {
            return {
                id: '',
                sign: '',
                type: '',
                code: '',
                url: '',
                checkUrl: '',
                order: [],
                permission: []
            }
        },
        methods: {
            moneyFen2Yuan,
            orderStatusStr,
            check: function () {
                const that = this
                this.axios.post(this.apiUrl + this.checkUrl, {
                    id: this.id,
                    sign: this.sign,
                    code: this.code,
                    amount: this.amount
                }).then((response) => {
                    console.log(response)
                    const data = response.data
                    if (data.status === 1) {
                        this.$toast({
                            message: data.msg,
                            onClose: function () {
                                that.$router.push('/business/index')
                            }
                        })
                    } else {
                        this.$router.push('/business/warning/' + data.msg)
                    }
                })
            },
            add: function () {
                const amount = this.amount
                const max = this.max
                if (amount >= max) {
                    this.$toast('无法继续添加数量')
                } else {
                    this.amount = amount + 1
                }
            },
            reduce: function () {
                const amount = this.amount
                if (amount <= 1) {
                    this.$toast('无法继续减少数量')
                } else {
                    this.amount = amount - 1
                }
            },
            init: function () {
                this.axios.post(this.apiUrl + this.url, {
                    id: this.id,
                    sign: this.sign,
                    code: this.code
                }).then((response) => {
                    console.log(response)
                    const data = response.data
                    if (data.status === 1) {
                        this.order = data.order
                        this.permission = data.permission
                    } else {
                        this.$router.push('/business/warning/' + data.msg)
                    }
                })
            }
        },
        created () {
            this.type = this.$route.params.type
            if (this.type === 'code') {
                this.code = this.$route.params.id
                this.url = 'check/order/getOrderCodeInfo'
                this.checkUrl = 'check/order/checkCode'
            } else if (this.type === 'qr') {
                this.id = this.$route.params.id
                this.sign = this.$route.params.sign
                this.url = 'check/order/getOrderInfo'
                this.checkUrl = 'check/order/check'
            }
            this.init()
        },
        mounted () {
        },
        computed: {
        }
    }
</script>

<style scoped>
    body{
        background: #f3f3f3;
    }
    .check_title{
        display: flex;
        justify-content: space-around;
        height: 10vw;
        align-items: center;
        border: 0.1rem solid #bcbcbc;
        padding: 1rem 0;
    }
    .check_title p{
        font-size: 4vw;
        font-weight: 500;
        color: #C20B09;
        margin-left: 1.8vw;
    }
    .check_information{
        padding: 1vw 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 3.4vw;
        color: #666666;
    }
    .check_information1{
        font-weight: 900;color:#C20B09
    }
    .check_information p:first-child{
        margin-left: 2rem;
    }
    .check_information p:nth-child(2){
        width: 50vw;
        margin-right: 2rem;
        text-align: right;
    }
    .line{
        width: 88vw;
        height: 1px;
        margin-left: 6vw;
        background: #f4f4f4;
    }
    .check_information_number p{
        width: 5vw;
        height: 5vw;
        text-align: center;
        line-height: 5vw;
        background-color: #eee;
        margin: 0 0.8vw;
        border-radius: 1vw;
    }
    .info-box {
        background-color: white;
        width: 95%;
        margin: 2rem auto;
    }
    .check_information{
        padding: 1vw 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 3.4vw;
        color: #666666;
    }
    .check_information p:first-child{
        margin-left: 2rem;
    }
    .check_information p:nth-child(2){
        width: 50vw;
        margin-right: 2rem;
        text-align: right;
    }
    .line{
        width: 88vw;
        height: 1px;
        margin-left: 6vw;
        background: #f4f4f4;
    }
    .p_key{
        border-radius: 0.5rem;
        background-color: #C20B09;
        color: white;
        text-align: center;
        font-size: 1rem;
        padding: 0.5rem 2rem;
    }
    .btn-box {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
    }
    .check_information_number{
        display: flex;
    }
    .check_information_number p{
        width: 5vw;
        height: 5vw;
        text-align: center;
        line-height: 5vw;
        background-color: #eee;
        margin: 0 0.8vw;
        border-radius: 1vw;
    }
</style>
