import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isShowLoading: true, // 这个参数用来设置axios请求的时候是否显示加载动画，比如点赞收藏这种场景如果显示加载动画 用户体验就会大大下降
    loading: false,
    // 最后一次下单的url，方便地址和优惠券页面回跳回去
    lastOrderRoute: '',
    addressIsOrder: 0, // 记录最后一次进入地址编辑是从哪里来
    beforeUrl: ''
  },
  mutations: {
    beginLoad: function (state) {
      this.state.loading = true
    },
    endLoad: function (state) {
      this.state.loading = false
    },
    setIsShowLoading: function (state, status) {
      this.state.isShowLoading = status
    },
    setLastOrderRoute (state, status) {
      this.state.lastOrderRoute = status
    },
    setBeforeUrl (state, status) {
      this.state.beforeUrl = status
    },
    setAddressIsOrder (state, status) {
      this.state.addressIsOrder = status
    }
  },
  actions: {
  },
  modules: {
  }
})
