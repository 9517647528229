<!--订单列表-->
<template>
    <div class="body">
        <!--顶部tab-->
        <div class="fix-top">
            <van-tabs v-model="tabActive" swipeable @click="onClickTab">
                <van-tab v-for="index in tabName" v-bind:key="index" :title="index">
                </van-tab>
            </van-tabs>
        </div>
        <!--订单列表-->
        <div class="list-wrapper">
            <div v-if="isList">
                <van-list
                        v-model="listLoading"
                        :finished="listLoadingFinished"
                        finished-text="没有更多了"
                        @load="onLoad"
                >
                    <common-order-item v-for="(item, index) in listLoadingList" :key="index"
                                       :createTime="item.create_time"
                                       :productName="item.gooods_name"
                                       :trademark="item.trademark"
                                       :type="item.type"
                                       :totalFee="item.total_fee"
                                       :number="item.number"
                                       :id="item.id"
                                       :orderType="item.express"
                                       :orderStatus="item.order_status"
                    ></common-order-item>
                </van-list>
            </div>
            <wait-pay-order-item v-if="isWait" :order="waitPayOrder" :payInfo="waitPayInfo"></wait-pay-order-item>
        </div>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import WaitPayOrderItem from '../components/order/WaitPayOrderItem'
  import { globalConfig, listLoading } from '../utils/mixin'
  import CommonOrderItem from '../components/order/CommonOrderItem'
  import FloatNav from '../components/FloatNav'
  export default {
    name: 'OrderList',
    components: { FloatNav, CommonOrderItem, WaitPayOrderItem },
    mixins: [globalConfig, listLoading],
    data () {
      return {
        tabActive: 0,
        tabName: ['未使用', '已使用', '失效', '退款', '待支付'],
        status: 1,
        waitPayOrder: [],
        waitPayInfo: [],
        isList: false,
        isWait: false
      }
    },
    methods: {
      // 点击切换标签
      onClickTab: function (name, title) {
        // console.log(name)
        console.log(title)
        if (title === '待支付') {
          this.clearLoading()
          this.getWaitPay()
        } else {
          switch (title) {
            case '未使用':
              this.status = 1
              break
            case '已使用':
              this.status = 2
              break
            case '失效':
              this.status = 3
              break
            case '退款':
              this.status = 6
              break
            default:
              break
          }
          console.log(this.status)
          this.isList = true
          this.isWait = false
          this.listLoadingInit(this.apiUrl + 'index/order/getOrderList', 'list', 1, {
            status: this.status
          })
        }
      },
      // 获取待支付订单
      getWaitPay: function () {
        const that = this
        this.axios.post(this.apiUrl + 'index/order/getWaitPay', {
          status: 20,
          page: 1
        }).then((response) => {
          const data = response.data
          that.waitPayOrder = data.list
          that.waitPayInfo = data.waitPay
          if (data.status === 0) {
            that.isList = false
            that.isWait = false
          } else {
            that.isList = false
            that.isWait = true
          }
        })
      },
      // 首屏渲染active
      initTab: function () {
        let title = ''
        switch (this.status) {
          case '1':
            this.tabActive = 0
            title = '未使用'
            break
          case '2':
            this.tabActive = 1
            title = '已使用'
            break
          case '3':
            this.tabActive = 2
            title = '失效'
            break
          case '20':
            this.tabActive = 4
            title = '待支付'
            break
          default:
            this.tabActive = 0
            break
        }
        return title
      }
    },
    mounted () {
      // 处理初始传值
      if (this.$route.params.status === undefined) {
        this.status = 1
      } else {
        this.status = this.$route.params.status
        console.log(this.status)
      }
      // 首屏加载
      this.onClickTab('', this.initTab())
    }
  }
</script>

<style scoped>
    .fix-top {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
    }
    *{
        box-sizing: border-box;
    }
    .body{
        position:absolute;
        min-height: 100%;
        min-width: 100%;
        background-color:#efefef;
    }
    .list-wrapper{
        width: 94vw;
        margin: 4rem 3vw 5vw 3vw;
    }
</style>
