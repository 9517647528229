import BusinessIndex from '../views/business/Index'
import BusinessLogin from '../views/business/Login'
import BusinessMerchandise from '../views/business/Merchandise'
import Details from '../views/business/Details'
import Warning from '../views/business/Warning'
import OrderList from '../views/business/OrderList'
import Chat from '../views/business/Chat'
import ChatMessage from '../views/business/ChatMessage'
import Data from '../views/business/data'
import Check from '../views/business/Check'

export const businessRouter = [
    {
      path: '/business/index',
      component: BusinessIndex
    },
    {
        path: '/business/login',
        name: '登录页面',
        component: BusinessLogin
    },
    {
        path: '/business/warning/:msg',
        name: '警告页面',
        component: Warning
    },
    {
        path: '/business/merchandise',
        name: '商品',
        component: BusinessMerchandise
    },
    {
        path: '/business/details/:id',
        name: '商品详情',
        component: Details
    },
    {
        path: '/business/orderList',
        name: '订单页面',
        component: OrderList
    },
    {
        path: '/business/chat',
        name: '咨询列表',
        component: Chat
    },
    {
        path: '/business/chatMessage/:id',
        name: '咨询详情',
        component: ChatMessage
    },
    {
        path: '/business/data',
        name: '数据统计',
        component: Data
    },
    {
        path: '/business/check/:type/:id/:sign?',
        name: '核销',
        component: Check
    }
]
