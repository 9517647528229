<!--交友圈个人主页-->
<template>
    <div class="page">
        <div class="title">基本信息</div>
        <van-cell title="性别" :value="sex"/>
        <van-cell title="生日" :value="birth"/>
        <van-cell title="状态" :value="status"/>
        <van-cell title="身高" :value="stature"/>
        <van-cell title="体重" :value="weight"/>
        <van-cell title="职业" :value="job"/>
        <van-cell title="个性宣言" :value="declaration"/>
        <div class="title" style="margin: 1rem ;">相册</div>
        <div class="img-container">
            <van-image v-for="item,index in img" :key="index"
                       class="moment-img"
                       fit="cover"
                       :src="item"
                       @click="viewImage(index)"
            />
        </div>
        <div style="width: 100%;height: 4rem;"></div>
        <div class="button-container">
            <div @click="returnPage">上一页</div>
            <div @click="chat">私信</div>
        </div>
    </div>
</template>

<script>
  import { globalConfig } from '../utils/mixin'
  import { ImagePreview } from 'vant'

  export default {
    name: 'FriendUser',
    mixins: [globalConfig],
    data () {
      return {
        userId: '',
        sex: '男',
        birth: '2021-01-01',
        status: '单身',
        stature: '', // 身高
        weight: '', // 体重
        declaration: '', // 宣言
        job: '', // 职业
        img: []
      }
    },
    methods: {
      getFriendUserInfo: function () {
        this.axios.post(this.apiUrl + 'index/friend/getFriendUserInfo', {
          userId: this.userId
        }).then((response) => {
          const res = response.data
          const data = res.data
          console.log(data)
          if (res.status === 1) {
            this.sex = data.sex
            this.status = data.love_state
            this.stature = data.stature
            this.weight = data.weight
            this.job = data.job
            this.birth = data.birth
            this.declaration = data.declaration
            this.img = data.img
            // this.fileList = data.img.map((item) => {
            //   return { url: item }
            // })
          } else {
            this.$toast({
              message: res.msg,
              onClose: () => {
                this.$router.push('user')
              }
            })
          }
        })
      },
      viewImage: function (index) {
        ImagePreview({
          images: this.img,
          startPosition: index
        })
      },
      returnPage: function () {
        this.$router.push('/friend')
      },
      chat: function () {
        this.$toast('暂未开放')
      }
    },
    mounted () {
      this.userId = this.$route.params.id
      this.getFriendUserInfo()
    }
  }
</script>

<style scoped>
    .page {
        background-color: #ffffff;
        padding: 1rem 0;
    }

    .title {
        font-size: 1rem;
        color: #666666;
        margin: 0 1rem;
        border-left: 0.3rem solid #fa1f1f;
        padding-left: 0.5rem;
    }

    .button-container {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        border-top: 0.01rem solid #F64C4C;
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
    }

    .button-container > div {
        width: 50%;
        box-sizing: border-box;
        text-align: center;
        padding: 1rem 0;
        font-size: 1.1rem;
    }

    .button-container > div:first-child {
        color: #F64C4C;
        background-color: #ffffff;
    }

    .button-container > div:nth-child(2) {
        color: #FFFFff;
        background-color: #F64C4C;
    }

    .img-container {
        width: 93%;
        margin: 0 auto;
    }

    .moment-img {
        height: 6rem;
        width: 6rem;
        margin: 0 0.5rem 0.25rem 0;
        border-radius: 0.5rem;
        overflow: hidden;
    }
</style>
