<template>
    <div>
        <van-calendar
                :show-title="false"
                v-if="loadFinish"
                title="日历"
                :poppable="false"
                :show-confirm="false"
                :style="{ height: '350px' }"
                :formatter="formatter"
                :max-date="maxDate"
                @select="clickDate"
        />
        <!--简要信息-->
        <div class="info-wrapper" v-if="currentHuangli.yi">
            <div>
                <div class="nongli">{{currentHuangli.nongli}}</div>
                <div class="yiji-wrapper">
                    <div class="yi">宜</div>
                    <span v-for="item,key in currentHuangli.yi" :key="key">{{item}}</span>
                </div>
                <div class="yiji-wrapper">
                    <div class="ji">忌</div>
                    <span v-for="item,key in currentHuangli.ji" :key="key">{{item}}</span>
                </div>
            </div>
            <div @click="viewDetail"><span class="view-more">查看详情</span></div>
        </div>
        <div class="info-wrapper" v-else><div class="none-date">暂无数据</div></div>
        <!--弹窗-->
        <van-popup v-model="showPop" style="background-color: rgba(255,255,255,0)">
            <div class="huangli-detail-wrapper">
                <div class="detail-head"></div>
                <div class="detail-body">
                    <div class="detail-info-line">
                        <span>{{currentHuangli.nongli}}</span>
                    </div>
                    <div class="pop-yiji">
                        <div class="yiji-wrapper">
                            <div class="yi">宜</div>
                            <span v-for="item,key in currentHuangli.yi" :key="key">{{item}}</span>
                        </div>
                        <div class="yiji-wrapper">
                            <div class="ji">忌</div>
                            <span v-for="item,key in currentHuangli.ji" :key="key">{{item}}</span>
                        </div>
                    </div>
                    <div class="huangli-table">
                        <div class="table-row">
                            <div class="table-100">
                                <div class="red-tag">岁次</div>
                                <div class="grey-tag"><span v-for="item,key in currentHuangli.suici" :key="key"> {{item}} </span></div>
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-50">
                                <div class="red-tag">吉日</div>
                                <div class="grey-tag">{{currentHuangli.jiri}}</div>
                            </div>
                            <div class="table-50">
                                <div class="red-tag">冲</div>
                                <div class="grey-tag">{{currentHuangli.chong}}</div>
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-33">
                                <div class="red-tag">五行</div>
                                <div class="grey-tag">{{currentHuangli.wuxing}}</div>
                            </div>
                            <div class="table-33">
                                <div class="red-tag">煞</div>
                                <div class="grey-tag">{{currentHuangli.sha}}</div>
                            </div>
                            <div class="table-33">
                                <div class="red-tag">生肖</div>
                                <div class="grey-tag">{{currentHuangli.shengxiao}}</div>
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-50">
                                <div class="red-tag">胎神</div>
                                <div class="grey-tag">{{currentHuangli.taishen}}</div>
                            </div>
                            <div class="table-50">
                                <div class="red-tag">值日天神</div>
                                <div class="grey-tag">{{currentHuangli.zhiri}}</div>
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-33">
                                <div class="red-tag">财神</div>
                                <div class="grey-tag">{{currentHuangli.caishen}}</div>
                            </div>
                            <div class="table-33">
                                <div class="red-tag">喜神</div>
                                <div class="grey-tag">{{currentHuangli.xishen}}</div>
                            </div>
                            <div class="table-33">
                                <div class="red-tag">福神</div>
                                <div class="grey-tag">{{currentHuangli.fushen}}</div>
                            </div>
                        </div>
                        <div class="table-row">
                            <div class="table-50">
                                <div class="red-tag">吉神宜趋</div>
                                <div class="grey-tag">{{currentHuangli.jishenyiqu}}</div>
                            </div>
                            <div class="table-50">
                                <div class="red-tag">凶神</div>
                                <div class="grey-tag">{{currentHuangli.xiongshen}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="detail-footer"></div>
            </div>
        </van-popup>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import { globalConfig } from '../utils/mixin'
  import FloatNav from '../components/FloatNav'

  export default {
    name: 'Huangli',
    components: { FloatNav },
    mixins: [globalConfig],
    data () {
      return {
        loadFinish: false,
        showPop: false,
        date: '',
        simpleList: new Map(),
        maxDate: new Date(),
        currentHuangli: {}
      }
    },
    methods: {
      formatter (day) {
        const year = day.date.getFullYear()
        let month = day.date.getMonth() + 1
        let date = day.date.getDate()
        month = month < 10 ? '0' + month : month
        date = date < 10 ? '0' + date : date
        const gongyuan = `公元${year}年${month}月${date}日`
        const info = this.simpleList.get(gongyuan)
        if (info) {
          day.bottomInfo = info.nongli.substr(-2)
        } else {
          day.bottomInfo = ''
        }
        return day
      },
      init () {
        this.axios.post(this.apiUrl + 'index/huangli/getHuangli').then((response) => {
          const result = response.data
          result.list.forEach((item, key) => {
            this.simpleList.set(item.yangli, item)
          })
          // console.log(this.simpleList)
          this.loadFinish = true
        })
      },
      getDateHuangli (year, month, day) {
        this.axios.post(this.apiUrl + 'index/huangli/getDate', {
          year: year,
          month: month,
          day: day
        }).then((response) => {
          const result = response
          const data = result.data
          this.currentHuangli = data.info
          console.log(data)
        })
      },
      clickDate (date) {
        const info = this.formatDate(date)
        this.getDateHuangli(info[0], info[1], info[2])
      },
      formatDate (date) {
        return [date.getFullYear(), date.getMonth() + 1, date.getDate()]
      },
      viewDetail () {
        this.showPop = true
      }
    },
    mounted () {
      this.init()
      // 首页默认加载今日黄历
      const today = new Date()
      this.getDateHuangli(today.getFullYear(), today.getMonth() + 1, today.getDate())
      // 设置最大日期
      this.maxDate = new Date(today.setFullYear(today.getFullYear() + 1))
      // const a = [1, 2, 3]
      // const a = { a: 1 }
      const a = 'aaaa'
      console.log(typeof a)
      console.log(Array.isArray(a))
      console.log(a instanceof String)
    }
  }
</script>

<style scoped>
    .info-wrapper {
        display: flex;
        flex-flow: column;
        margin-top: 1rem;
        width: 100%;
        background-color: #FFFFff;
    }
    .info-wrapper > div:first-child {
        text-align: center;
        padding: 1rem;
    }
    .info-wrapper > div:first-child > * {
        margin-top: 0.5rem;
    }
    .info-wrapper > div:nth-child(2) {
        text-align: center;
        padding: 1rem 0;
        font-size: 1.1rem;
        border-top: 0.1rem solid #d5d5d5;
        margin: 0 2rem;
    }
    .nongli {
        font-size: 0.9rem;
        color: #888888;
    }
    .yiji-wrapper {
        font-size: 0.9rem;
    }
    .yi {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        background-color: #46D788;
        color: #FFFFff;
        display: inline-block;
        border-radius: 10rem;
        text-align: center;
    }
    .ji {
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        background-color: #333333;
        color: #FFFFff;
        display: inline-block;
        border-radius: 10rem;
        text-align: center;
    }
    .yiji-wrapper span {
        margin-left: 0.5rem;
    }
    .none-date {
        text-align: center;
        color: #d5d5d5;
        font-size: 1.1rem;
        background-color: #FFFFff;
    }
    .view-more {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
    }
    .view-more:before {
        content: "";
        display: inline-block;
        width: 1.1rem;
        height: 1.1rem;
        background: url("https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E9%BB%84%E5%8E%86-%E6%9F%A5%E7%9C%8B%E8%AF%A6%E6%83%85.png") no-repeat;
        background-size: 100% 100%;
    }
    .detail-head {
        width: 20rem;
        height: 2rem;
        background: url("https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E9%BB%84%E5%8E%86-%E5%9B%BE%E5%B1%821.png") no-repeat;
        background-size: 100% 100%;
    }
    .detail-body {
        width: 20rem;
        background: url("https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E9%BB%84%E5%8E%86-%E5%9B%BE%E5%B1%822.png") no-repeat;
        background-size: 100% 100%;
    }
    .detail-footer {
        width: 20rem;
        height: 2rem;
        background: url("https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E9%BB%84%E5%8E%86-%E5%9B%BE%E5%B1%823.png") no-repeat;
        background-size: 100% 100%;
    }
    .detail-info-line {
        display: flex;
        flex-flow: row;
        justify-content: center;
        width: 80%;
        margin: 0 auto;
        font-size: 1.1rem;
        font-weight: 600;
        padding-bottom: 0.5rem;
    }
    .detail-info-line:last-of-type {
        padding-bottom: 0;
    }
    .detail-info-line > span:first-child {
        color: #fa1f1f;
    }
    .detail-info-line > span:nth-child(2) {
        color: #000000;
        margin-left: 0.5rem;
    }
    .huangli-table {
        background-color: #fff9f1;
        width: 80%;
        margin: 0 auto;
        text-align: center;
    }
    .table-row {
        display: flex;
        flex-flow: row;
    }
    .table-row > div {
        border: 0.01rem solid #eeeeee;
    }
    .table-33 {
        width: 33%;
        text-align: center;
    }
    .table-50 {
        width: 50%;
        text-align: center;
    }
    .table-100 {
        width: 100%;
        text-align: center;
    }
    .red-tag {
        color: #c32121;
    }
    .grey-tag {
        color: #888888;
        font-size: 0.9rem;
    }
    .pop-yiji {
        width: 80%;
        margin: 0 auto;
        display: flex;
        flex-flow: column;
    }
    .pop-yiji > div {
        margin-bottom: 0.5rem;
    }
</style>
