<template>
    <div>
        <div class="waterfall-container">
            <!--左侧瀑布-->
            <div class="list-lie" ref="list1">
                <article-preview v-for="item,key in leftList" :key="key"
                                 :img="item.img"
                                 :name="item.title"
                                 :support="item.like"
                                 :id="item.id"
                                 :hasLike="item.hasLike"
                ></article-preview>
            </div>
            <!--右侧瀑布-->
            <div class="list-lie" ref="list2">
                <article-preview v-for="item,key in rightList" :key="key"
                                 :img="item.img"
                                 :name="item.title"
                                 :support="item.like"
                                 :id="item.id"
                                 :hasLike="item.hasLike"
                ></article-preview>
            </div>
        </div>
    </div>
</template>

<script>
  import ArticlePreview from './ArticlePreview'

  export default {
    name: 'Waterfall',
    components: { ArticlePreview },
    props: ['showData'],
    data () {
      return {
        leftList: [],
        rightList: []
      }
    },
    methods: {
      /**
       * 接收data为每次新增的数据
       * @param data
       */
      refresh: function (data) {
        // 先确定左右两边瀑布流的高度
        // 拿到对应数据，然后定义回调，回调的时候再去判断该放在哪里
        for (const key in data) {
          const currentData = data[key]
          const currentImage = new Image()
          currentImage.src = 'https://seetop-1257860468.file.myqcloud.com/panjiang/' + data[key].img
          currentImage.onload = () => {
            // 在回调里面操作，确保了每一张图片都是已经加载完成的，不会出现未加载 计算不到高度的情况
            // 进入回调，先判断高度，加入到对应瀑布列
            const leftHeight = this.$refs.list1.clientHeight
            const rightHeight = this.$refs.list2.clientHeight
            // console.log('左右两边高度', leftHeight, rightHeight, data)
            if (leftHeight > rightHeight) {
              this.rightList.push(currentData)
            } else {
              this.leftList.push(currentData)
            }
            // console.log('左右两边队列情况', this.leftList, this.rightList)
            // console.log(data)
            // console.log('这张图片加载完了', currentImage.height, currentImage.width)
            // 比较高的图片就裁剪成3：4、比较宽的图片裁剪成4：3、正方形1：1
            const height = currentImage.height
            const width = currentImage.width
            if (height === width) {
              // 正方形图片，不裁剪
            } else if (height > width) {
              // 比较高的图片 3:4
              if ((height / width) > (4 / 3)) {
                // 按照宽的原长度填满3x，裁高
              } else {
                // 按照高的原长度填满4x，裁宽
              }
            } else if (width > height) {
              // 比较宽的图片 4：3
              if ((width / height) > (4 / 3)) {
                // 按照高的原长度填满3x，裁宽
              } else {
                // 按照宽的原长度填满4x，裁高
              }
            }
          }
        }
      },
      forceResetData: function () {
        this.leftList = []
        this.rightList = []
      }
    },
    computed: {},
    watch: {
      showData: {
        deep: true,
        handler (newVal, oldVal) {
          if (newVal !== oldVal) {
            const newLength = newVal.length
            const oldLength = oldVal.length
            const newAdd = newVal.slice(oldLength, newLength)
            this.refresh(newAdd)
            // console.log(newVal, oldVal)
            // console.log('这是一段监听========>', newLength, oldLength, newAdd)
          }
        }
      }
    },
    created () {
    },
    mounted () {
    },
    updated () {
    }
  }
</script>

<style scoped>
    .waterfall-container {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        height: 100%;
    }

    .list-lie {
        height: fit-content;
    }
</style>
