<template>
    <div class="box">
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="item" v-for="(item,index) in listLoadingList" :key="index">
                <p>套餐名：{{item.name}}</p>
                <p>原件：{{item.price}}</p>
                <p>售价：{{item.mallprice}}</p>
                <p>今日订单：{{item.today_number}}</p>
                <p>昨日订单：{{item.yesterday_number}}</p>
                <p>核销订单：{{item.check_number}}</p>
                <p>全部订单：{{item.total_number}}</p>
            </div>
        </van-list>
        <float-nav-business></float-nav-business>
    </div>
</template>
<script>
    import { globalConfig, listLoading } from '../../utils/mixin'
    import FloatNavBusiness from '../../components/FloatNavBusiness'
    export default {
        name: 'Index',
        mixins: [globalConfig, listLoading],
        components: { FloatNavBusiness },
        data () {
            return {
                list: [],
                loading: false
            }
        },
        methods: {
            init: function () {
                this.axios.get(this.apiUrl + 'check/api/init').then((response) => {
                    console.log(response)
                    const data = response.data
                    if (data.status === 0) {
                        this.$router.replace('/business/login')
                    }
                })
            }
        },
        created () {
            this.init()
            this.listLoadingInit(this.apiUrl + 'check/order/getOrderData', 'list', 1, {

            })
        },
        mounted () {
        },
        computed: {
        }
    }
</script>

<style scoped>
    *{
        box-sizing: border-box;
    }
    .box{
        background-color: #FEF9F6;
        padding: 3vw 2vw;
    }
    .item{
        width: 92%;
        margin: 0 auto 3vw auto;
        background-color: white;
        box-shadow:0 0 5px #999999;
        border-radius: 1vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding:0.8rem 0.7rem;
    }
    .item p{
        font-size: 1rem;
        color: #808080;
        margin-top: 1.8vw;
    }
</style>
