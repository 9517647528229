<template>
    <div>
        <!--        聊天预览-->
        <div class="message-wrapper" @click="viewService">
            <div class="message-head-wrapper">
                <img class="message-head"
                     src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E6%94%80%E8%AE%B2%E5%AE%A2%E6%9C%8D%E5%A4%B4%E5%83%8F.png">
            </div>
            <div class="message-body">
                <div class="message-base">
                    <div class="message-user-name">客服咨询</div>
                </div>
                <div class="message-preview">您有任何问题都可以添加客服微信进行咨询</div>
            </div>
        </div>
        <customer-service ref="service"></customer-service>
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text=""
                @load="onLoad"
        >
            <div v-for="(item, index) in listLoadingList" :key="index">
                <div class="message-wrapper" @click="toChat(item.id)">
                    <div class="message-head-wrapper">
                        <img class="message-head"
                             :src="'https://seetop-1257860468.file.myqcloud.com/panjiang/' + item.trademark">
                        <!--                    <div class="message-unread">5</div>-->
                    </div>
                    <div class="message-body">
                        <div class="message-base">
                            <div class="message-user-name">{{item.businessName}}</div>
                            <div class="message-time">{{item.update_time}}</div>
                        </div>
                        <div class="message-preview">{{item.lastMessage}}</div>
                    </div>
                </div>
            </div>
        </van-list>
    </div>
</template>

<script>
  import { globalConfig, listLoading } from '../utils/mixin'
  import CustomerService from '../components/CustomerService'

  export default {
    name: 'Message',
    components: { CustomerService },
    mixins: [globalConfig, listLoading],
    methods: {
      viewService: function () {
        this.$refs.service.showPop()
      },
      toChat: function (id) {
        this.$router.push('chat/' + id)
      },
      getUserMessage: function () {
        this.axios.post(this.apiUrl + 'index/chat/getUserMessage', {
          page: 1
        }).then((response) => {
          console.log(response)
        })
      }
    },
    mounted () {
      this.listLoadingInit(this.apiUrl + 'index/chat/getUserMessage', 'list', 1, {})
    }
  }
</script>

<style scoped>
    .test {
        width: 300px;
        height: 300px;
        background-color: #fa1f1f;
    }

    .message-wrapper {
        /*width: 90%;*/
        margin: 0 auto;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 0.05rem solid #e2e2e2;
        padding: 1rem;
        background-color: #FFFFff;
    }

    .message-head-wrapper {
        position: relative;
    }

    .message-head {
        width: 4rem;
        height: 4rem;
        border-radius: 10rem;
    }

    .message-unread {
        background-color: #F64C4C;
        color: #ffffff;
        width: 1.2rem;
        height: 1.2rem;
        line-height: 1.3rem;
        text-align: center;
        font-size: 0.9rem;
        border-radius: 10rem;
        position: absolute;
        right: -0.5rem;
        top: 0;
    }

    .message-body {
        width: 80%;
        height: 3.5rem;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
    }

    .message-base {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        align-items: center;
    }

    .message-preview {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        font-size: 0.9rem;
        color: #999999;
    }

    .message-user-name {
        font-weight: 600;
    }

    .message-time {
        color: #999999;
    }
</style>
