<template>
    <div>
        <div class="about-wrapper">
            <img class="logo" src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/个人中心-关于我们LOGO.png">
<!--            <div class="name">一碗 · 有囍</div>-->
            <div class="text">
                一碗有囍，一个婚庆产业特色平台，为您提供一站式婚庆产业消费体验。我们致力于为新人朋友和婚庆产业提供潮流、创新、便捷的交互服务。
            </div>
        </div>
        <div class="service" @click="clickCustomerService">一碗 · 有囍 客服微信</div>
        <customer-service ref="service"></customer-service>
        <float-nav></float-nav>
    </div>
</template>

<script>
  import FloatNav from '../components/FloatNav'
  import CustomerService from '../components/CustomerService'

  export default {
    name: 'About',
    components: {
      CustomerService,
      FloatNav
    },
    methods: {
      clickCustomerService: function () {
        this.$refs.service.showPop()
      }
    }
  }
</script>

<style scoped>
    .about-wrapper {
        background-color: #ffffff;
        width: 100%;
    }

    .text {
        background-color: #FFFFff;
        line-height: 1.8rem;
        padding: 0 1rem 1rem 1rem;
        color: #666666;
    }

    .logo {
        width: 9rem;
        /*height: 9rem;*/
        display: block;
        margin: 0 auto 3rem;
        padding-top: 3rem;
    }

    .name {
        font-weight: 600;
        font-size: 1.3rem;
        text-align: center;
        padding-bottom: 0.5rem;
        color: #333333;
    }

    .service {
        background-color: #ffffff;
        margin-top: 0.5rem;
        width: 100%;
        padding: 1rem;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #333333;
    }

    .service:after {
        content: "";
        width: 1rem;
        height: 1rem;
        background: url('https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E5%90%91%E5%8F%B3-%E9%BB%91.png') no-repeat;
        background-size: 100% 100%;
        display: inline-block;
    }
</style>
