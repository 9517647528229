<template>
    <div class="moment-item">
        <img class="head"
             :src="headimgurl" @click="clickHead">
        <div class="moment-right">
            <div class="moment-top">
                <div class="moment-user-info">
                    <div>
                        <div>{{weixinNick}}</div>
                        <img class="sex"
                             :src="sex == '男' ? 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/sex-nan.png' : 'https://seetop-1257860468.file.myqcloud.com/panjiang/img/sex-nv.png'">
                    </div>
                    <div>{{age}}岁 &nbsp;| &nbsp;{{stature}}cm &nbsp;| &nbsp;{{weight}}kg</div>
                </div>
                <div>···</div>
            </div>
            <div class="moment-content">{{content}}</div>
            <div class="moment-img-container">
                <van-image v-for="item2,index2 in img" :key="index2"
                           class="moment-img"
                           fit="cover"
                           :src="item2"
                           @click="viewImage(index2)"
                />
            </div>
            <div class="moment-time">
                <div>{{createTime}}</div>
                <div class="icon-container">
                    <div @click="writeComment">
                        <img src="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%BA%A4%E5%8F%8B%E8%AF%84%E8%AE%BA.png">
                        <div>评论</div>
                    </div>
                    <support-icon :like="like" :hasLike="isLike"
                                  default-img="https://seetop-1257860468.file.myqcloud.com/panjiang/img/%E4%BA%A4%E5%8F%8B%E7%82%B9%E8%B5%9E.png"
                                  @click.native="clickLike(id)"></support-icon>
                </div>
            </div>
            <div class="moment-reply" v-if="followComment.length > 0">
                <div v-for="item,index in followComment" :key="index">{{item['weixinNick']}}：{{item['content']}}</div>
                <div class="view-more-reply" v-if="showMoreComment" @click="viewMoreComment">查看更多回复</div>
            </div>
        </div>
        <comment-publish ref="commentPublish" @publishComment="publishComment"></comment-publish>
    </div>
</template>

<script>
  import { ImagePreview } from 'vant'
  import SupportIcon from './SupportIcon'
  import { globalConfig } from '../utils/mixin'
  import CommentPublish from './CommentPublish'

  export default {
    name: 'FriendMoment',
    components: {
      CommentPublish,
      SupportIcon
    },
    // props中的lastcomment是最新的可以直接显示 followcomment代表后续的
    props: ['id', 'headimgurl', 'weixinNick', 'sex', 'age', 'stature', 'weight', 'content', 'img', 'createTime', 'support', 'hasLike', 'lastComment', 'hasMoreComment', 'userHead', 'userName', 'userId'],
    mixins: [globalConfig],
    data () {
      return {
        isLike: false,
        like: 0,
        followComment: [],
        showMoreComment: true
      }
    },
    methods: {
      viewImage: function (index) {
        ImagePreview({
          images: this.img,
          startPosition: index
        })
      },
      clickLike: function () {
        //  避免重复点赞
        if (this.isLike) {
          return
        }
        this.axios.post(this.apiUrl + 'index/article/like', {
          id: this.id,
          type: '交友'
        }).then((response) => {
          const data = response.data
          this.$toast(data.msg)
          // 点击的同时直接变颜色
          this.isLike = true
          this.like++
        })
      },
      writeComment: function () {
        this.$refs.commentPublish.showPop()
      },
      // 进行评论
      publishComment: function (text) {
        this.axios.post(this.apiUrl + 'index/friend/momentComment', {
          momentId: this.id,
          comment: text
        }).then((response) => {
          const data = response.data
          console.log(data)
          if (data.status === 1) {
            // 如果当前有显示更多，那么什么都不做，因为新提交的评论默认被隐藏了
            if (this.showMoreComment) {
              this.$toast({
                message: '评论成功',
                onClose: () => {
                  this.$refs.commentPublish.closePop()
                }
              })
            } else {
              // 如果当前已经是没有办法显示更多了，直接添加一条
              this.$toast({
                message: '评论成功',
                onClose: () => {
                  this.$refs.commentPublish.closePop()
                  this.followComment = [...this.followComment, {
                    content: text,
                    weixinNick: this.userName
                  }]
                }
              })
            }
          } else {
            this.$toast(data.msg)
          }
        })
      },
      // 加载更多交友评论
      viewMoreComment: function () {
        this.axios.post(this.apiUrl + 'index/friend/momentCommentList', {
          momentId: this.id,
          start: this.followComment.length
        }).then((response) => {
          const data = response.data
          const last = data.list
          if (last.length >= 5) {
            // 还可以可以查看更多
          } else {
            // 查看不了更多了
            this.showMoreComment = false
          }
          this.followComment = [...this.followComment, ...last]
        })
      },
      clickHead: function () {
        console.log('点击头像')
        this.$router.push('/friendUser/' + this.userId)
      }
    },
    mounted () {
      this.isLike = this.hasLike
      this.like = this.support
      this.followComment = this.lastComment
      this.showMoreComment = this.hasMoreComment
      // console.log(typeof this.followComment)
      // console.log(this.followComment instanceof Array)
      // console.log(this.followComment instanceof Object)
    }
  }
</script>

<style scoped>
    .moment-item {
        background-color: #FFFFff;
        padding: 1rem 1rem;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        border-bottom: 0.01rem solid #dedede;
    }

    .moment-right {
        width: 85%;
    }

    .moment-top {
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        margin-bottom: 0.5rem;
    }

    .moment-top > div:nth-child(2) {
        font-weight: 800;
    }

    .moment-user-info {
        display: flex;
        flex-flow: column;
    }

    .moment-user-info > div:first-child {
        color: #000000;
        font-size: 1rem;
        display: flex;
        flex-flow: row;
        align-items: center;
    }

    .sex {
        height: 1rem;
        margin-left: 0.6rem;
    }

    .moment-user-info > div:nth-child(2) {
        color: #666666;
        font-size: 0.9rem;
        margin-top: 0.5rem;
        letter-spacing: 0.05rem;
    }

    .head {
        width: 3rem;
        height: 3rem;
        border-radius: 10rem;
    }

    .moment-content {
        margin: 0.8rem 0;
        color: #666666;
        letter-spacing: 0.07rem;
        font-size: 0.95rem;
    }

    .moment-img-container {
        margin-bottom: 0.8rem;
    }

    .moment-img {
        height: 6rem;
        width: 6rem;
        margin: 0 0.5rem 0.25rem 0;
        border-radius: 0.5rem;
        overflow: hidden;
    }

    .moment-time {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        font-size: 0.9rem;
        color: #999999;
        letter-spacing: 0.06rem;
    }

    .moment-reply {
        background-color: #F7F7F7;
        padding: 0.5rem 1rem;
        margin-top: 0.8rem;
        font-size: 0.95rem;
        color: #666666;
    }

    .moment-reply > div {
        margin-bottom: 0.4rem;
    }

    .view-more-reply {
        color: #F64C4C;
        margin-top: 0.5rem;
    }

    .icon-container {
        display: flex;
        flex-flow: row;
    }

    .icon-container > div {
        display: flex;
        flex-flow: row;
        /*align-items: center;*/
    }

    .icon-container > div > img {
        width: 1.2rem;
        height: 1.2rem;
    }

    .icon-container > div:first-child {
        margin-right: 0.5rem;
    }

    .icon-container > div:first-child > img {
        margin-right: 0.5rem;
    }
</style>
