<!--交友个人信息编辑-->
<template>
    <div class="page">
        <div class="title">基本信息</div>
        <van-cell title="性别" is-link :value="sex" @click="clickSex"/>
        <van-cell title="生日" is-link :value="birth" @click="clickBirth"/>
        <van-cell title="状态" is-link :value="status" @click="clickStatus"/>
        <van-field v-model="stature" type="digit" label="身高" input-align="right" placeholder="请填写您的身高" maxlength="3"/>
        <van-field v-model="weight" type="digit" label="体重" input-align="right" placeholder="请填写您的体重" maxlength="3"/>
        <van-field v-model="job" label="职业" input-align="right" placeholder="请填写您的职业"/>
        <!--        <van-cell title="个人标签" is-link value="技术宅，书法，美术，唱歌，跳舞，白领"/>-->
        <van-field v-model="declaration" rows="1" label="个性宣言" placeholder="填写您的个性宣言" type="textarea" maxlength="50"
                   show-word-limit autosize/>
        <div class="title" style="margin: 1rem ;">相册</div>
        <div class="img-container">
            <van-uploader v-model="fileList" multiple :max-count="6" :max-size="500 * 1024" @oversize="onOversize"
                          :before-read="asyncBeforeRead"/>
        </div>
        <div style="width: 100%;height: 4rem;"></div>
        <div class="button-container">
            <div @click="returnPage">上一页</div>
            <div @click="saveInfo">发布</div>
        </div>
        <!--弹窗-->
        <van-action-sheet
                v-model="sexShow"
                :actions="sexActions"
                cancel-text="取消"
                description="选择您的性别"
                close-on-click-action
                @select="selectSex"
        />
        <van-action-sheet
                v-model="statusShow"
                :actions="statusActions"
                cancel-text="取消"
                description="选择您的状态"
                close-on-click-action
                @select="selectStatus"
        />
        <van-action-sheet v-model="birthShow">
            <div class="content">
                <van-datetime-picker
                        v-model="currentDate"
                        type="date"
                        title="选择年月日"
                        :min-date="minDate"
                        :max-date="maxDate"
                        @confirm="confirmBirth"
                />
            </div>
        </van-action-sheet>
    </div>
</template>

<script>

  import { formatGMT2StandardDate } from '../utils/tools'
  import { globalConfig } from '../utils/mixin'
  import * as imageConversion from 'image-conversion'

  export default {
    name: 'FriendEdit',
    components: {},
    mixins: [globalConfig],
    data () {
      return {
        fileList: [
          // { url: 'https://img01.yzcdn.cn/vant/leaf.jpg' },
          // // Uploader 根据文件后缀来判断是否为图片文件
          // // 如果图片 URL 中不包含类型信息，可以添加 isImage 标记来声明
          // {
          //   url: 'https://cloud-image',
          //   isImage: true
          // }
        ],
        sexShow: false, // 点击性别
        sexActions: [{ name: '男' }, { name: '女' }
        ],
        statusShow: false, // 点击状态，选择单身，已婚，备婚
        statusActions: [
          {
            name: '单身'
          },
          { name: '已婚' },
          {
            name: '备婚'
            // subname: '描述信息'
          }
        ],
        birthShow: false,
        sex: '男',
        birth: '2021-01-01',
        status: '单身',
        stature: '', // 身高
        weight: '', // 体重
        declaration: '', // 宣言
        job: '', // 职业
        minDate: new Date(1950, 0, 1),
        maxDate: new Date(2025, 10, 1),
        currentDate: new Date(2021, 11, 16)
      }
    },
    methods: {
      clickSex: function () {
        this.sexShow = true
      },
      clickStatus: function () {
        this.statusShow = true
      },
      clickBirth: function () {
        this.birthShow = true
      },
      confirmBirth: function (c) {
        this.birth = formatGMT2StandardDate(c)
        this.birthShow = false
      },
      selectSex: function (action, index) {
        this.sex = action.name
      },
      selectStatus: function (action, index) {
        this.status = action.name
      },
      onOversize (file) {
        console.log(file)
        this.$toast('文件大小不能超过 500kb')
      },
      // 保存编辑
      saveInfo: function () {
        console.log('保存', this.sex, this.birth, this.status, this.stature, this.weight, this.declaration, this.job)
        console.log(this.fileList)
        this.axios.post(this.apiUrl + 'index/friend/editInfo', {
          sex: this.sex,
          love_state: this.status,
          stature: this.stature,
          weight: this.weight,
          job: this.job,
          birth: this.birth,
          declaration: this.declaration,
          img: this.fileList
        }).then((response) => {
          if (response.data.status === 1) {
            this.$toast({
              message: '编辑成功',
              onClose: () => {
                this.$router.go(0)
              }
            })
          } else {
            this.$toast(response.data.msg)
          }
        })
      },
      editInit: function () {
        this.axios.post(this.apiUrl + 'index/friend/editInit', {}).then((response) => {
          const res = response.data
          const data = res.data
          if (res.status === 1) {
            this.sex = data.sex
            this.status = data.love_state
            this.stature = data.stature
            this.weight = data.weight
            this.job = data.job
            this.birth = data.birth
            this.declaration = data.declaration
            this.fileList = data.img.map((item) => {
              return { url: item }
            })
          } else {
            this.$toast({
              message: res.msg,
              onClose: () => {
                this.$router.push('user')
              }
            })
          }
        })
      },
      // 返回上一个
      returnPage: function () {
        // this.$router.go(-1)
        this.$router.push('/friend')
      },
      // 上传前置处理
      asyncBeforeRead (file) {
        this.$toast.loading({
          message: '加载中...'
        })
        return new Promise((resolve) => {
          imageConversion.compressAccurately(file, {
            size: 300 // 图片大小压缩到1024kb
          }).then(res => {
            this.$toast.clear()
            resolve(res)
          })
        })
      }
    },
    mounted () {
      this.editInit()
    }
  }
</script>

<style scoped>
    .page {
        background-color: #ffffff;
        padding: 1rem 0;
    }

    .title {
        font-size: 1rem;
        color: #666666;
        margin: 0 1rem;
        border-left: 0.3rem solid #fa1f1f;
        padding-left: 0.5rem;
    }

    .button-container {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: space-between;
        border-top: 0.01rem solid #F64C4C;
        position: fixed;
        width: 100%;
        bottom: 0;
        left: 0;
    }

    .button-container > div {
        width: 50%;
        box-sizing: border-box;
        text-align: center;
        padding: 1rem 0;
        font-size: 1.1rem;
    }

    .button-container > div:first-child {
        color: #F64C4C;
        background-color: #ffffff;
    }

    .button-container > div:nth-child(2) {
        color: #FFFFff;
        background-color: #F64C4C;
    }

    .img-container {
        width: 93%;
        margin: 0 auto;
    }
</style>
